import { DateTime } from 'luxon'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Day from '../Day/Day'
import styles from './Month.module.css'
import classNames from 'classnames/bind'
import { useInView } from 'react-intersection-observer'
import CalendarContext from '../../../../context/CalendarContext'

const cx = classNames.bind(styles)

type Props = {
  month: DateTime
  onDayClick: (date: string) => void
  activeDate: string | null
  isToday: (date: string) => boolean
  sundayIsFirst: boolean
  forwardRef: React.LegacyRef<HTMLDivElement> | null
  isScrolling: boolean
  isExpanded: boolean
  onIntersectionStateChange: (
    inView: boolean,
    entry: IntersectionObserverEntry | undefined
  ) => void
  showMonthName: boolean
}

const Month = ({
  month,
  onDayClick,
  activeDate,
  isToday,
  sundayIsFirst,
  forwardRef,
  isExpanded,
  onIntersectionStateChange,
  isScrolling,
  showMonthName,
}: Props) => {
  const [ref, inView, entry] = useInView({
    onChange: onIntersectionStateChange,
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
  })

  // get previos month dates based on activeMonthYear
  const previousMonthDates = useMemo(
    () => getPreviousMonthDates(month, sundayIsFirst),
    [month, sundayIsFirst]
  )
  const currentMonthDates = useMemo(() => getCurrentMonthDates(month), [month])

  const totalDays = previousMonthDates.length + currentMonthDates.length

  const {
    isLoading: calendarIsLoading,
    calendar: {
      selected: { date: selectedMonth },
    },
  } = useContext(CalendarContext)

  return (
    <div
      ref={forwardRef}
      style={{
        position: 'relative',
      }}
    >
      <div
        className={cx(
          'month',
          totalDays > 28 && 'five-weeks',
          showMonthName && 'show-month-name'
        )}
        ref={ref}
      >
        <div className={cx('month-name')}>
          <span>{month.toFormat('LLL')}</span>&nbsp;
          {month.toFormat('yyyy')}
        </div>
        <div className={cx('days-container')}>
          {previousMonthDates.map((day) => (
            <Day
              key={day}
              date={day}
              month={month}
              onClick={onDayClick}
              isActive={activeDate === day}
              number={
                day.substring(8, 10) // day is in YYYY-MM-DD format originally
              }
              isToday={isToday(day)}
              isCurrentMonth={
                month.minus({ months: 1 }).toFormat('MM yyyy') ===
                selectedMonth.toFormat('MM yyyy')
              }
              sundayIsFirst={sundayIsFirst}
              isLoading={calendarIsLoading}
              isScrolling={isScrolling}
            />
          ))}
          {currentMonthDates.map((day, index) => (
            <Day
              key={day}
              month={month}
              date={day}
              onClick={onDayClick}
              isActive={activeDate === day}
              number={
                index === 0
                  ? DateTime.fromISO(day).toFormat('LLL d')
                  : (index + 1).toString()
              }
              isToday={isToday(day)}
              isCurrentMonth={
                month.toFormat('MM yyyy') === selectedMonth.toFormat('MM yyyy')
              }
              sundayIsFirst={sundayIsFirst}
              isLoading={calendarIsLoading}
              isScrolling={isScrolling}
              // allowSnap={!isExpanded && !isScrolling}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Month

const getPreviousMonthDates = (month: DateTime, sundayIsFirst: boolean) => {
  const firstDayOfActiveMonth = month.startOf('month')
  const firstDayOfActiveMonthWeekday =
    firstDayOfActiveMonth.weekday - (sundayIsFirst ? 0 : 1)
  const previousMonth = month.minus({ month: 1 })
  const daysInPreviousMonth = previousMonth.daysInMonth
  const previousMonthDates = []
  for (
    let i = daysInPreviousMonth;
    i > daysInPreviousMonth - firstDayOfActiveMonthWeekday;
    i--
  ) {
    previousMonthDates.push(previousMonth.set({ day: i }).toISODate())
  }
  return previousMonthDates.reverse()
}

const getCurrentMonthDates = (month: DateTime) => {
  const startOfTheMonth = month.startOf('month')
  const endOfTheMonth = month.endOf('month')

  const adjustedEndOfMonth =
    endOfTheMonth.weekday === 7
      ? endOfTheMonth
      : endOfTheMonth.endOf('week').minus({ days: 7 })
  const activeMonthDates = []

  for (let i = 1; i <= adjustedEndOfMonth.endOf('week').day; i++) {
    activeMonthDates.push(startOfTheMonth.set({ day: i }).toISODate())
  }

  return activeMonthDates
}
