import React, { useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { auth } from '../../../firebase'
import './_Auth.css'
import Button from '../../../components/buttons/Button/Button'
import { UserContext } from '../../../context/UserContext'
import { AuthMode } from '../../../hooks/useAuth'
import { useURLParams } from '../../../hooks/useURLParams'

type Props = {
  onModeSwitch: (mode: AuthMode) => void
  isLoading: boolean
  error: string | null
}

function VerifyModule({ onModeSwitch, isLoading, error }: Props) {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)

  return isLoading ? (
    <div className="module auth_container">
      <h3 style={{ textAlign: 'center' }}>Verifying E-mail...</h3>
    </div>
  ) : error ? (
    <div className="module auth_container">
      <h2>Couldn't verify your email</h2>
      <div style={{ textAlign: 'left', color: 'white' }}>
        Kindly try clicking the verification link again. If the problem
        persists, please contact us at{' '}
        <a
          href={`mailto:support@rewod.io?subject=Verification link not working [${user?.uid}]`}
        >
          support@rewod.io
        </a>
      </div>
    </div>
  ) : user && !user.emailVerified ? (
    <div className="module auth_container">
      <h2>
        Please Verify Your
        <br />
        E-mail
      </h2>
      <div style={{ textAlign: 'left', color: 'white' }}>
        A verification link has been sent to{' '}
        <h3 style={{ margin: '24px 0' }}>{user && user.email}</h3>
        Click the link in your e-mail to continue
        <br />
        <br />
        <a onClick={() => onModeSwitch('register')}>Incorrect e-mail?</a>
      </div>

      <br />
    </div>
  ) : user && user.emailVerified ? (
    <div className="module auth_container">
      <h2>E-mail Verified Successfuly</h2>
      <div style={{ textAlign: 'left', color: 'white', marginBottom: '24px' }}>
        You may now continue
      </div>
      <Button
        onClick={() => {
          navigate('/')
          onModeSwitch('complete')
        }}
      >
        Continue
      </Button>
      <br />
    </div>
  ) : (
    <></>
  )
}
export default VerifyModule
