import { useContext, useEffect, useState } from 'react'
import { ArrowRight } from 'react-feather'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../../components/buttons/Button/Button'
import Pricing from '../../components/sections/pricing/Pricing'
import Logo from '../../components/atoms/Logo/Logo'
import ProgressBar from '../../components/atoms/ProgressBar/ProgressBar'
import { AppContext } from '../../context/AppContext'
import { UserContext } from '../../context/UserContext'
import { auth, logout } from '../../firebase'
import AuthModule from './auth/AuthModule'
import GymNameModule from './GymNameModule'
import css from './Onboarding.module.css'
import { Analytics } from '../../services/analytics'
import { useURLParams } from '../../hooks/useURLParams'

type Props = {}
type Section = 'hello' | 'auth' | 'gym-name' | 'pricing'

const Onboarding = (props: Props) => {
  const sections: Section[] = ['auth', 'gym-name', 'pricing']
  const [authFlow, setAuthFlow] = useState<string>('')
  const navigate = useNavigate()
  //const [user] = useAuthState(auth)

  const { user, userID } = useContext(UserContext)
  const { setSearchParams } = useURLParams()

  const [gymJoinMode, setGymJoinMode] = useState(
    user.introMode === 'join-gym' ? true : false
  ) // I need to keep this here to be able to hide progress bar
  const { currentSpace, spaces } = useContext(AppContext)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const modeViaParams = params.get('mode')

  const getNextSection = () => {
    if (!auth.currentUser) return 'auth'

    if (auth.currentUser && !auth.currentUser.emailVerified) return 'auth'

    if (
      auth.currentUser &&
      auth.currentUser.emailVerified &&
      modeViaParams === 'verifyEmail'
    )
      return 'gym-name'

    if (
      activeSection === 'gym-name' ||
      (currentSpace && currentSpace.ownerID === userID)
    )
      return 'pricing'
    else return 'auth'
  }

  const hasOwnedSpaces = spaces.filter((s) => s.ownerID === userID).length

  const startSection: Section =
    modeViaParams === 'resetPassword'
      ? 'auth'
      : auth.currentUser
      ? auth.currentUser.emailVerified && !modeViaParams // user clicked on email verification link, hence mode is present
        ? hasOwnedSpaces
          ? 'pricing'
          : 'gym-name'
        : 'auth'
      : 'hello'

  const [activeSection, setActiveSection] = useState<Section>(startSection)

  const activeSectionIndex = sections.indexOf(activeSection)

  const goForward = () => {
    setActiveSection(getNextSection())
  }

  useEffect(() => {
    if (activeSection === 'hello') {
      Analytics._logEvent({
        name: 'welcome_screen_viewed',
        params: {},
      })
    }
  }, [])

  return (
    <>
      <div
        className={`${css['wrapper']} ${
          activeSection === 'hello' && css['hello']
        }`}
      >
        <div className={`${css['app-store-promo']}`}>
          <section>
            <h2>Athlete?</h2>
            <p>
              Join your gym with the Rewod app, start tracking your workouts,
              and become a part of the community
            </p>
            <div className={css['store-buttons']}>
              <a
                href="https://apps.apple.com/us/app/rewod-workouts-community/id6444032971"
                target="_blank"
              >
                <div className={css['app-store-button']}>
                  <img src="/img/dl-app-store.svg" />
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=io.rewod.app"
                target="_blank"
              >
                <div className={css['app-store-button']}>
                  <img src="/img/google-play-badge.svg" />
                </div>
              </a>
            </div>
          </section>
        </div>
        <div className={css['container']}>
          <div className={css['start-flow-intro']}>
            <section>
              <div>
                {process.env.REACT_APP_ENV === 'dev' ? (
                  <span
                    style={{
                      fontWeight: 'normal',
                      color: 'var(--secondary-text-white)',
                    }}
                  >
                    Dev
                  </span>
                ) : (
                  <></>
                )}
                <Logo
                  className={css['logo-hello']}
                  type="full"
                  style={{ width: '120px', marginBottom: '40px' }}
                />
                <h2>Gym Owner?</h2>
                <p>
                  Add you gym to Rewod, create the&nbsp;workout program and
                  start building your community
                </p>
                <Button
                  style={{ width: '200px', margin: '16px 0 0' }}
                  iconRight={
                    <ArrowRight size={16} style={{ marginLeft: '8px' }} />
                  }
                  onClick={() => {
                    setGymJoinMode(false)

                    Analytics._logEvent({
                      name: 'auth_join_as_gym_owner',
                      params: {},
                    })

                    setAuthFlow('auth_join_as_gym_owner')
                    setActiveSection(getNextSection())
                  }}
                >
                  Get Started
                </Button>
              </div>
              <div className={css['coach-login']}>
                <h3 style={{ lineHeight: '28px' }}>
                  Coach or
                  <br />
                  Manager?
                </h3>
                <Button
                  style={{ width: '220px', height: '48px', margin: '16px 0 0' }}
                  type={'secondary-dark'}
                  onClick={() => {
                    setGymJoinMode(true)
                    Analytics._logEvent({
                      name: 'auth_join_as_coach',
                      params: {},
                    })

                    setAuthFlow('auth_join_as_coach')
                    setActiveSection(getNextSection())
                  }}
                  iconRight={
                    <ArrowRight size={16} style={{ marginLeft: '8px' }} />
                  }
                >
                  Access Your Gym
                </Button>
              </div>
            </section>
          </div>
          <div className={css['onboarding-flow']}>
            <div className={css['header']}>
              <div
                onClick={() => {
                  setActiveSection('hello')
                  setAuthFlow('')
                  navigate('/')
                }}
              >
                <Logo className={css['logo']} type="full" />
              </div>
              {!gymJoinMode && modeViaParams !== 'resetPassword' && (
                <div className={css['progress']}>
                  <ProgressBar activeSection={activeSection} />
                </div>
              )}
              <div className={css['links']}>
                {!gymJoinMode && modeViaParams !== 'resetPassword' && (
                  <div
                    className={css['link']}
                    onClick={() => {
                      setActiveSection('gym-name')
                      setGymJoinMode(true)
                    }}
                  >
                    Join a Gym
                  </div>
                )}
                <div
                  className={css['link']}
                  onClick={() => {
                    if (user) {
                      setActiveSection('hello')
                      setAuthFlow('')
                      navigate('/')
                      logout()
                    }
                  }}
                >
                  {userID && 'Logout'}
                </div>
              </div>
            </div>
            <div
              className={css['modules']}
              style={{ marginLeft: `-${activeSectionIndex * 100}%` }}
            >
              <div className={css['module']}>
                <AuthModule
                  onComplete={goForward}
                  isVisible={activeSection === 'auth'}
                  introMode={!gymJoinMode ? 'create-gym' : 'join-gym'}
                  authFlow={authFlow}
                />
              </div>
              <div className={css['module']}>
                <GymNameModule
                  onComplete={goForward}
                  onModeSwitch={(joinGym) => setGymJoinMode(joinGym)}
                  joinMode={gymJoinMode}
                  isVisible={activeSection === 'gym-name'}
                />
              </div>
              <div className={css['module']}>
                <Pricing
                  onComplete={goForward}
                  isVisible={activeSection === 'pricing'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${css['hello']} ${css['full']}`}>
        <div className={`${css['app-store-promo']}`}>
          <Logo className={css['logo']} type="full" />
          <section>
            <h2>Athlete?</h2>
            <p>
              Join your gym with the Rewod app, start tracking your workouts,
              and become a part of the community
            </p>
            <div className={css['store-buttons']}>
              <a
                href="https://apps.apple.com/us/app/rewod-workouts-community/id6444032971"
                target="_blank"
              >
                <div className={css['app-store-button']}>
                  <img src="/img/dl-app-store.svg" />
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=io.rewod.app"
                target="_blank"
              >
                <div className={css['app-store-button']}>
                  <img src="/img/google-play-badge.svg" />
                </div>
              </a>
            </div>
          </section>
          <section>
            <h3>Gym Owner, Coach or Manager?</h3>
            <p
              style={{
                borderColor: 'transparent',
                fontSize: '14px',
              }}
            >
              Open <strong>app.rewod.io</strong> on your laptop or desktop
              computer to access your gym
            </p>
          </section>
        </div>
      </div>
    </>
  )
}

export default Onboarding
