import { useContext, useState } from 'react'
import { UserContext } from '../context/UserContext'
import { firestore } from '../firebase'
import { snakeCase } from 'snake-case'
import { v4 as uuid } from 'uuid'
import {
  collection,
  doc,
  getDocs,
  where,
  query,
  updateDoc,
  FirestoreError,
} from 'firebase/firestore'
import {
  FieldErrors,
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormRegisterReturn,
  UseFormSetFocus,
} from 'react-hook-form'
import { GymData, GymSubscriptionStatus } from '../types/types'
import { AppContext } from '../context/AppContext'
import { randNumberWithSoManyDigits } from '../helpers'
import { createGym } from '../services/createGym'
import { joinGymByID } from '../services/joinGymByID'
import API from '../services'
import { Analytics } from '../services/analytics'

type Props = {
  onComplete: () => void
  joinMode: boolean
}

type EditableGymInformation =
  | 'title'
  | 'accessCode'
  | 'newAccessCode'
  | 'workoutVisibility'

const useGym = ({ onComplete, joinMode }: Props) => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm()
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  const [gymFound, setGymFound] = useState<GymData | null>(null)
  const [gymAlreadyExists, setGymAlreadyExists] = useState<boolean>(false)

  const { user, userID, userIsLoading } = useContext(UserContext)
  const { currentSpace } = useContext(AppContext)

  const gymNameField = {
    ...register('gymName'),
  }
  const gymIDField = {
    ...register('gymID', { maxLength: 6, onChange: () => setError(null) }),
  }
  const searchGymById = async (gymAccessCode: string, justChecking = false) => {
    setLoading(true)

    const spaceRef = query(
      collection(firestore, 'spaces'),
      where('accessCode', '==', gymAccessCode.toLowerCase())
    )

    let spaceFound = null
    let spaceData: GymData[] = []
    try {
      spaceFound = await getDocs(spaceRef)

      if (justChecking) {
        console.log('checking')

        setLoading(false)
        if (spaceFound.empty) return false
        else return true
      }

      spaceFound.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        spaceData.push(doc.data() as GymData)
      })
      setLoading(false)
    } catch (e) {
      console.error('Error retrieving document: ', e)
    }

    if (spaceData.length) {
      if (
        !userIsLoading &&
        user.spaceAccess &&
        user.spaceAccess.includes(spaceData[0].id)
      ) {
        setError('You have already joined ' + spaceData[0].title)
        API.patchUserAttribute({ defaultSpace: spaceData[0].id })
        Analytics._logEvent({
          name: 'gym_found_already_exists',
          params: { gymID: spaceData[0].id },
        })
      } else {
        setGymFound(spaceData[0])

        Analytics._logEvent({
          name: 'gym_found',
          params: { gymID: spaceData[0].id },
        })
      }
    } else {
      setError('Incorrect Gym ID')
      Analytics._logEvent({
        name: 'gym_not_found',
        params: { details: 'gym ID tried: ' + gymAccessCode },
      })
    }
  }

  const joinGym = async (gymID: string) => {
    setLoading(true)
    await joinGymByID(gymID)
    Analytics._logEvent({ name: 'gym_add', params: { gymID } })
    setLoading(false)
  }

  const leaveGym = async (gymID: string) => {
    setLoading(true)

    if (!userID || !user) return

    const body = {
      gymID: gymID,
      uid: userID,
    }
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    }
    fetch(API.url + 'leaveGym', options)
      .then(
        (res) => res.json(),
        (err) => console.log(err)
      )
      .then((res) => {
        onComplete()
        setLoading(false)
      })
  }

  const onSubmit = async (data: any) => {
    console.log('data', data)
    const error = checkForErrors(data, joinMode)
    setError(error)
    if (error) {
      console.log('error', error)
      return
    } else {
      setLoading(true)

      if (!joinMode) await createGym(data.gymName)
      else await searchGymById(data.gymID)

      setLoading(false)
    }
  }

  const updateGymInfo = async (key: EditableGymInformation, value: string) => {
    if (!currentSpace) return
    const ref = doc(firestore, 'spaces', currentSpace?.id)

    // Error handling
    switch (key) {
      case 'title': {
        if (value === currentSpace.title) return

        // powered by ChatGPT
        const pattern = /^[a-zA-Z0-9'".\-!#$%&()\[\]\s]*$/
        if (!pattern.test(value)) {
          setError('The title contains incorrect character')
          return
        }
        break
      }

      case 'workoutVisibility': {
        if (value === currentSpace.workoutVisibility) return

        break
      }
      case 'accessCode': {
        if (value === currentSpace.accessCode) return

        const pattern = /^[A-Za-z0-9]+$/i
        if (!pattern.test(value)) {
          setError('The code can only have letters or numbers')
          return
        }

        setLoading(true)
        const isUnique = await codeIsUnique(value)
        if (!isUnique) {
          setError('Sorry, this code is already taken')
          return false
        }

        break
      }
    }

    const oldName = currentSpace.title
    const oldVisibility = currentSpace.workoutVisibility
    const oldCode = currentSpace.accessCode || 'no-code'

    try {
      setLoading(true)
      await updateDoc(ref, { [key]: value })
      setLoading(false)
      switch (key) {
        case 'accessCode': {
          if (!currentSpace.accessCode)
            Analytics._logEvent({
              name: 'gym_invite_code_changed',
              params: { details: `old name: ${oldCode}, new name: ${value}` },
            })
          break
        }
        case 'title': {
          Analytics._logEvent({
            name: 'gym_name_changed',
            params: { details: `old name: ${oldName}, new name: ${value}` },
          })
          break
        }
        case 'workoutVisibility': {
          Analytics._logEvent({
            name: 'gym_workout_visibility_changed',
            params: { details: `old: ${oldVisibility}, new name: ${value}` },
          })
        }
      }
      onComplete()
    } catch (err) {
      setError(JSON.stringify(err))
      setLoading(false)
    }
  }

  const createNewAccessCode = async (gymName: string) => {
    if (!currentSpace) return ''
    let originalCode

    setLoading(true)

    originalCode = gymName
      .toLowerCase()
      .replaceAll('crossfit', '')
      .replace(/[^a-zA-Z0-9]/g, '')
      .replaceAll(' ', '')
      .substring(0, 6)

    let unique = await codeIsUnique(originalCode)
    let retries = 0
    let newCode = originalCode

    while (!unique && retries < 10) {
      retries++
      let numDigitsToReplace = Math.min(Math.floor(Math.log10(retries)) + 1, 3)
      newCode = originalCode.substring(0, 6 - numDigitsToReplace) + retries
      unique = await codeIsUnique(newCode)
    }
    setLoading(false)
    if (!unique) {
      // failed to create a code during 10 retries
      setError('code-support')
      return 'error'
    } else return newCode
  }

  const codeIsUnique = async (code: string) => {
    const gymExists = await searchGymById(code, true)
    return !gymExists
  }

  return {
    gymNameField,
    gymIDField,
    error,
    isLoading,
    handleSubmit: handleSubmit(onSubmit),
    setFocus,
    setError,
    gymFound,
    joinGym,
    leaveGym,
    setGymFound,
    createNewAccessCode,
    codeIsUnique,
    updateGymInfo,
  }
}

export default useGym

const checkForErrors = (data: any, joinMode: boolean) => {
  if (!joinMode) {
    if (data.gymName.length < 3)
      return 'Gym name should be at least 3 characters long'
    else if (data.gymName.length > 40)
      return 'Gym name can should be under 40 characters'
    else return null
  } else if (joinMode) {
    if (!data.gymID) return 'Please enter Gym ID'
  }
  return null
}
