import { CSSProperties } from 'react'
import css from './ProgressBar.module.css'

type Section = { name: string; id: string }

type Props = {
  sections?: Section[]
  activeSection: string
  style?: CSSProperties
}
//TBD: make this reusable
const ProgressBar = ({ sections, activeSection, style }: Props) => {
  return (
    <div
      style={style}
      className={`${css['progress-bar-container']} ${
        activeSection === 'gym-name'
          ? css['step2']
          : activeSection === 'pricing' && css['step3']
      }`}
    >
      <div className={css['progress-bar']}>
        <div className={`${css['item']} ${css['active']}`}>
          Sign Up
          <div />
        </div>
        <div
          className={`${css['item']} ${
            (activeSection === 'gym-name' || activeSection === 'pricing') &&
            css['active']
          }`}
        >
          Add Your Gym
          <div />
        </div>
        <div
          className={`${css['item']} ${
            activeSection === 'pricing' && css['active']
          }`}
        >
          Select Plan
          <div />
        </div>
      </div>
      <div className={css['line']}>
        <div className={css['dot']} />
        <div className={css['fill']} />
      </div>
    </div>
  )
}

export default ProgressBar
