import axios from 'axios'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { auth } from '../firebase'
import API from '.'

export const goToCheckout = async (product: string, gymID: string) => {
  const authToken = await auth.currentUser?.getIdToken()
  const email = auth.currentUser?.email

  console.log('checking out product: ', product)

  return axios
    .post(API.url + `checkout`, {
      email,
      product,
      gymID,
      authToken,
    })
    .then((res) => {
      const url = res.data.url
      if (typeof url === 'string') window.location.href = url
    })
    .catch((err) => {
      console.error(err.response.data)
    })
}
