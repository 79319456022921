import React, { useContext, useState } from 'react'
import '../App.css'
import SecondaryNav from '../components/navigation/SecondaryNavigation/SecondaryNav'
import { AppContext } from '../context/AppContext'
import ButtonToolbar from '../components/buttons/ButtonToolbar/ButtonToolbar'
import { DateTime } from 'luxon'
import GymName from '../components/modals/GymName'
import EditAccessCode from '../components/modals/EditAccessCode'
import { manageSubscription } from '../services/manageSubscription'
import { addResult } from '../services/addResult'
import SubscriptionStatus from '../components/atoms/SubscriptionStatus/SubscriptionStatus'
import Footer from '../components/sections/Footer/Footer'
import SubscribeModal from '../components/modals/SubscribeModal'
import WorkoutVisibility from '../components/modals/WorkoutVisibility'
import { translateGymData } from '../helpers_ts'
import { HelpCircle } from 'react-feather'
import { Tooltip } from 'react-tooltip'
import Page from './technical/Page'

type Props = {
  limitedAccess?: boolean
}

const GymSettings = ({ limitedAccess }: Props) => {
  const { currentSpace } = useContext(AppContext)

  //test

  const sub = currentSpace?.subscription

  const periodStart = sub?.current_period_end
    ? DateTime.fromSeconds(sub?.current_period_start._seconds).toFormat(
        'LLL d, yyyy'
      )
    : ''
  const periodEnd = sub?.current_period_end
    ? DateTime.fromSeconds(sub?.current_period_end._seconds).toFormat(
        'LLL d, yyyy'
      )
    : ''

  const [visibleModal, setVisibleModal] = useState<
    'gymName' | 'accessCode' | 'sub-again' | 'workoutVisibility' | null
  >(null)

  const overQuota = () => {
    if (!sub || !sub.user_limit) return

    let overQuota = 0
    const quota = sub.user_limit
    Object.values(sub.active_by_month).forEach((val) => {
      if (val > quota) overQuota += val - quota
    })

    return overQuota
  }

  const [subModalVisible, setSubModalVisible] = useState(false)

  const subscribeAgain = () => {
    setVisibleModal('sub-again')
  }

  return (
    <>
      <GymName
        onCancel={() => setVisibleModal(null)}
        onConfirm={() => setVisibleModal(null)}
        isVisible={visibleModal === 'gymName'}
      />

      <WorkoutVisibility
        onCancel={() => setVisibleModal(null)}
        onConfirm={() => setVisibleModal(null)}
        isVisible={visibleModal === 'workoutVisibility'}
      />

      <EditAccessCode
        onCancel={() => setVisibleModal(null)}
        onConfirm={() => setVisibleModal(null)}
        isVisible={visibleModal === 'accessCode'}
      />

      <SubscribeModal
        isVisible={visibleModal === 'sub-again'}
        onCancel={() => setVisibleModal(null)}
      />
      <Tooltip
        id="t-setting-explainer"
        place="right"
        delayShow={400}
        className="tooltip"
        noArrow={true}
        offset={8}
        style={{ margin: '-2px 0 0 0' }}
      />

      <Page>
        <div id="page-content" className="col-8">
          {!limitedAccess && (
            <div>
              <h3>Information</h3>
              <section>
                <div>
                  <label style={{ margin: 0 }}>Gym Name</label>
                  <span>{currentSpace?.title}</span>{' '}
                  <span
                    onClick={() => setVisibleModal('gymName')}
                    className="link s"
                    style={{ marginLeft: '8px' }}
                  >
                    Edit
                  </span>
                </div>
                <div>
                  <label>
                    <span
                      data-tooltip-id="t-setting-explainer"
                      data-tooltip-content="When your published workouts become visible to your
                      athletes"
                    >
                      Workout Visibility{' '}
                      <HelpCircle
                        size={14}
                        color={'var(--icon)'}
                        style={{
                          position: 'relative',
                          top: '2px',
                          left: '2px',
                        }}
                      />
                    </span>
                  </label>
                  <span>
                    {translateGymData(currentSpace?.workoutVisibility || '')}
                  </span>{' '}
                  <span
                    onClick={() => setVisibleModal('workoutVisibility')}
                    className="link s"
                    style={{ marginLeft: '8px' }}
                  >
                    Edit
                  </span>
                </div>
                <div>
                  <label>Access Code</label>
                  <div
                    style={{
                      textAlign: 'center',
                      width: '240px',
                      border: '1px solid var(--divider)',
                      font: 'var(--h3)',
                      margin: '8px 0',
                      letterSpacing: '8px',
                      borderRadius: '4px',
                      padding: '24px 0',
                    }}
                  >
                    {currentSpace?.accessCode?.toUpperCase()}
                  </div>
                  <span
                    className="link s"
                    onClick={() => setVisibleModal('accessCode')}
                  >
                    Edit
                  </span>{' '}
                  <span
                    className="link s"
                    style={{ marginLeft: '8px' }}
                    onClick={() =>
                      window.open(
                        '/printCode/' + currentSpace?.accessCode,
                        '_blank'
                      )
                    }
                  >
                    Print
                  </span>
                </div>
              </section>
            </div>
          )}
          <div>
            <h3>Subscription</h3>
            <section style={{ display: 'flex', gap: '16px' }}>
              {sub ? (
                <>
                  <section className="col-6">
                    <label style={{ marginTop: 0 }}>Status</label>
                    <SubscriptionStatus
                      status={sub.status}
                      date={
                        sub && sub.cancel_at_period_end && sub.cancel_at
                          ? sub.cancel_at._seconds
                          : sub.status === 'trialing'
                          ? sub.trial_end && sub.trial_end._seconds
                          : sub.status === 'canceled' && sub.canceled_at
                          ? sub.canceled_at._seconds
                          : sub.current_period_end?._seconds
                      }
                      cancelInTheEnd={sub.cancel_at_period_end}
                      sub={currentSpace.subscription}
                    />

                    <label>Your Plan</label>
                    <span>
                      <span></span>
                      <strong style={{ textTransform: 'capitalize' }}>
                        {sub.interval + 'ly, '}Up to {sub.user_limit}{' '}
                        {sub.interval === 'year' ? 'monthly' : ''} Active
                        Members
                      </strong>
                      <div style={{ font: 'var(--small-text)' }}>
                        ${(sub.flat_price / 100).toFixed(2)} per {sub.interval}
                        <br />${(sub.metered_price / 100).toFixed(2)} per extra
                        member (if any)
                      </div>
                    </span>

                    <label>
                      Current Period
                      {sub.status === 'trialing' ? ' (14-Day Free Trial)' : ''}
                    </label>
                    <span>
                      {periodStart} — {periodEnd}
                    </span>

                    {sub.status !== 'trialing' &&
                      (sub.interval === 'month' ? (
                        <>
                          <label>Active This Period</label>
                          <span>
                            {sub.active_this_period}/{sub.user_limit}{' '}
                            {sub.user_limit < sub.active_this_period &&
                              `(${
                                sub.active_this_period - sub.user_limit
                              } over quota)`}
                          </span>
                        </>
                      ) : (
                        <>
                          <label>Activity by Month</label>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '4px',
                            }}
                          >
                            {sub &&
                              Object.keys(sub.active_by_month).map((key) => {
                                const isCurr = isCurrentMonth(key)
                                const [month, year] = key.split('-').map(Number)
                                const date = new Date(year, month - 1)

                                const shortMonth = date.toLocaleString('en', {
                                  month: 'short',
                                })
                                return (
                                  <div key={key}>
                                    <div
                                      style={{
                                        textAlign: 'center',
                                        color: isCurr
                                          ? 'var(--text)'
                                          : 'var(--secondary-text)',
                                        padding: '8px 8px 4px',
                                        borderTop: '1px solid var(--divider)',
                                        borderRight:
                                          shortMonth === 'Dec'
                                            ? '1px dotted var(--divider)'
                                            : 0,
                                        font: 'var(--small-text)',
                                        fontWeight: isCurr ? '500' : 'inherit',
                                        backgroundColor: isCurr
                                          ? 'var(--bg-gray)'
                                          : 'inherit',
                                      }}
                                    >
                                      {shortMonth}
                                    </div>
                                    <div
                                      style={{
                                        textAlign: 'center',
                                        paddingBottom: '8px',
                                        borderBottom:
                                          '1px solid var(--divider)',
                                        fontWeight:
                                          sub.active_by_month[key] &&
                                          sub.active_by_month[key] >
                                            sub.user_limit
                                            ? '600'
                                            : 'auto',
                                        borderRight:
                                          shortMonth === 'Dec'
                                            ? '1px dotted var(--divider)'
                                            : 0,
                                        backgroundColor: isCurr
                                          ? 'var(--bg-gray)'
                                          : 'inherit',
                                      }}
                                    >
                                      {sub.active_by_month[key] ? (
                                        sub.active_by_month[key]
                                      ) : (
                                        <span
                                          style={{
                                            color: 'var(--divider)',
                                          }}
                                        >
                                          –
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                          {overQuota() ? (
                            <div
                              style={{
                                font: 'var(--small-text)',
                                marginTop: '4px',
                              }}
                            >{`Over quota: ${overQuota()}`}</div>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    <br />

                    {['canceled', 'incomplete_expired'].includes(
                      currentSpace?.subscription.status
                    ) && (
                      <ButtonToolbar
                        style={{ width: '240px', margin: '16px 0 8px' }}
                        type="light"
                        onClick={() => subscribeAgain()}
                      >
                        Subscribe Again
                      </ButtonToolbar>
                    )}

                    <ButtonToolbar
                      style={{ width: '240px', margin: '16px 0 8px' }}
                      type="light"
                      onClick={() =>
                        manageSubscription(currentSpace?.subscription)
                      }
                    >
                      Manage Subsctiption
                    </ButtonToolbar>
                  </section>
                  <section className="col-3">
                    <label
                      style={{
                        color: 'var(--secondary-text)',
                        marginTop: '0',
                      }}
                    >
                      Who is an <i>active</i> member?
                    </label>
                    <div
                      style={{
                        marginTop: '8px',
                        font: 'var(--small-text)',
                        color: 'var(--secondary-text)',
                      }}
                    >
                      An active member is someone who tracked a workout during{' '}
                      {sub.interval === 'year'
                        ? 'a calendar month'
                        : 'the current period'}
                      .
                    </div>{' '}
                    {sub.interval === 'year' && (
                      <div
                        style={{
                          marginTop: '8px',
                          font: 'var(--small-text)',
                          color: 'var(--secondary-text)',
                        }}
                      >
                        With your yearly subscription, unique active users are
                        calculated each calendar month. Then, at the end of the
                        subscription period, extra user charges (if any) are
                        calculated using the sum of values over the quota.
                        <br />
                        So, for example, if you had 2 months with{' '}
                        {sub.user_limit + 3}&nbsp;active members, an extra
                        charge of 2&times;3&times;$
                        {(sub.metered_price / 100).toFixed(2)} will be applied.
                      </div>
                    )}
                  </section>{' '}
                </>
              ) : (
                <></>
              )}
            </section>
          </div>
        </div>
      </Page>
    </>
  )
}

export default GymSettings

const isCurrentMonth = (monthYear: string): boolean => {
  const [month, year] = monthYear.split('-').map(Number)
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()

  return month === currentMonth && year === currentYear
}
