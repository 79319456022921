import React, { CSSProperties, useState } from 'react'
import css from './RoundsInput.module.css'
import { Plus as IconPlus, Minus as IconMinus } from 'react-feather'
import NumberInput from '../NumberInput/NumberInput'
import { useFormContext } from 'react-hook-form'

type Props = {
  className?: string
  name: string
  label?: string
}

const TimeCapInput = ({ className, name, label }: Props) => {
  return (
    <NumberInput
      name={name}
      label={label || 'Time Cap'}
      placeholder="minutes"
      className={className}
      hideButtons={true}
      min={0}
      max={999}
    />
  )
}

export default TimeCapInput
