import { SubmitHandler, useForm } from 'react-hook-form'
import useWorkoutBuilder from '../../../hooks/useWorkoutBuilder'
import { RewodLegacyEvent } from '../../../types/legacyEvents'
import { Workout } from '../../../types/workouts'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import css from './AdditionalNoteInput.module.css'
import useCalendar from '../../../hooks/calendar/useCalendar'
import { Analytics } from '../../../services/analytics'
import { CalendarEvent } from '../../../types/calendarEvents'

type Props = {
  onDismiss: () => void
  workoutData: Workout.Item
  eventData: CalendarEvent.Workout | undefined
}

export const AdditionalNoteInput = ({
  workoutData,
  onDismiss,
  eventData,
}: Props) => {
  const {} = useCalendar()

  const { formMethods, updateWorkoutWithData } = useWorkoutBuilder({
    onDismiss,
    index: eventData?.index || 0,
    workoutToEdit: workoutData,
    workoutEventToEdit: eventData || null,
  })

  const handleSave = () => {
    const additionalNote = formMethods.watch('additionalNote')
    Analytics._logEvent({
      name: 'workout_additional_note_added',
      params: {
        workoutID: workoutData.id,
        additionalNote,
      },
    })
    if (additionalNote && eventData)
      updateWorkoutWithData({
        newEventData: eventData,
        newWorkoutData: { ...workoutData, additionalNote },
        publishStatus: eventData.isPublished,
      })
    else onDismiss()
  }

  const handleDelete = () => {
    Analytics._logEvent({
      name: 'workout_additional_note_removed',
      params: {
        workoutID: workoutData.id,
        additionalNote: workoutData.additionalNote,
      },
    })
    const additionalNote = formMethods.watch('additionalNote')
    if (additionalNote && eventData)
      updateWorkoutWithData({
        newEventData: eventData,
        newWorkoutData: { ...workoutData, additionalNote: '' },
        publishStatus: eventData.isPublished,
      })
    else onDismiss()
  }

  return (
    <div className={css['additional-note']}>
      <div>
        <label>Additional Note for Athletes</label>
        <textarea
          placeholder="Note..."
          autoFocus={true}
          {...formMethods.register('additionalNote')}
        />
      </div>
      <div className={css['buttons']}>
        <div className={css['section']} style={{ flex: '.6' }}>
          <ButtonToolbar
            type="light"
            onClick={handleSave}
            style={{ flex: '1' }}
          >
            Save
          </ButtonToolbar>
          <ButtonToolbar type="light" onClick={onDismiss} style={{ flex: '1' }}>
            Cancel
          </ButtonToolbar>
        </div>
        <div className={css['section']}>
          <ButtonToolbar type="light" onClick={handleDelete}>
            Remove
          </ButtonToolbar>
        </div>
      </div>
    </div>
  )
}
