import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { GymMemberData } from '../../types/types'
import Button from '../buttons/Button/Button'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'
import css from './Modal.module.css'
import useGym from '../../hooks/useGym'
import { AppContext } from '../../context/AppContext'
import { IconBarbell } from '../atoms/Icons/Icons'
import {
  Field,
  FieldErrors,
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormRegisterReturn,
  UseFormSetFocus,
} from 'react-hook-form'
import { Analytics } from '../../services/analytics'

type Props = {
  isVisible: boolean
  onDismiss: () => void
}

const AccessCode = ({ isVisible, onDismiss }: Props) => {
  //const [isLoading, setLoading] = useState(false)

  const [autoGymCode, setAutoGymCode] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [localError, setLocalError] = useState('')
  const { currentSpace } = useContext(AppContext)

  const {
    createNewAccessCode,
    codeIsUnique,
    updateGymInfo,
    error,
    setError,
    isLoading,
  } = useGym({
    onComplete: () => {
      reset()
    },
    joinMode: false,
  })

  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const getAccessCode = async () => {
      if (!currentSpace) return
      const code = await createNewAccessCode(currentSpace.title)

      setAutoGymCode(code)
      setValue('accessCode', code)
    }

    if (!autoGymCode) getAccessCode()
  }, [])

  const checkCode = async (data: FieldValues) => {
    if (data.accessCode === autoGymCode) {
      return true
    } else {
      const isUnique = await codeIsUnique(data.accessCode.trim().toLowerCase())
      if (!isUnique) {
        setLocalError('Sorry, this code is already taken')
        setFocus('accessCode')
        return false
      } else return true
    }
  }

  const onPrint: SubmitHandler<FieldValues> = async (data) => {
    const codeIsOK =
      data.accessCode !== autoGymCode ? await checkCode(data) : true
    if (!codeIsOK) return

    const code = data.accessCode.trim().toLowerCase()

    Analytics._logEvent({
      name: 'gym_invite_code_print_requested',
      params: { details: `gymID: ${currentSpace?.id}, code: ${code}` },
    })
    window.open('/printCode/' + code, '_blank')
  }

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const code = data.accessCode.trim().toLowerCase()
    Analytics._logEvent({
      name: 'gym_invite_code_first_created',
      params: { details: `code: ${code}` },
    })
    updateGymInfo('accessCode', code)
  }

  const actions = {
    secondary: {
      onClick: handleSubmit(onPrint),
      isLoading: isLoading,
      disabled: localError || error ? true : false,
      label: 'Print code',
    },
    primary: {
      label: "Let's go!",
      onClick: handleSubmit(onSubmit),
      disabled: localError || error ? true : false,
      isLoading: isLoading,
      isSubmitButton: true,
    },
  }

  const toggleEditMode = () => {
    if (editMode) {
      setValue('accessCode', autoGymCode)
      setLocalError('')
      setError('')
    }
    setEditMode(!editMode)
  }

  useEffect(() => {
    if (editMode) setFocus('accessCode')
  }, [editMode, error])

  return (
    <Modal
      isVisible={isVisible}
      onCancel={onDismiss}
      size="small"
      buttons={actions}
      canDismiss={false}
      blurBackdrop={true}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {' '}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ textAlign: 'center' }}>
            <IconBarbell
              color="var(--accent)"
              style={{ marginBottom: '16px' }}
            />
            <h3>Welcome to your gym</h3>
            <h1 style={{ marginTop: '8px' }}>
              {currentSpace && currentSpace.title}
            </h1>
          </div>

          <p
            style={{ margin: '32px auto 0', width: '80%', textAlign: 'center' }}
          >
            Invite your staff and members
            <br />
            with this code:
          </p>

          <div
            style={{
              margin: '12px 0',
              position: 'relative',
            }}
          >
            <input
              maxLength={6}
              {...register('accessCode', {
                onChange: () => {
                  setLocalError('')
                  setError(null)
                },
              })}
              style={{
                borderColor: error ? 'var(--accent-red)' : '',
              }}
              className="accessCode"
            />

            {!editMode ? (
              <a
                style={{
                  position: 'absolute',
                  right: '16px',
                  top: '28px',
                }}
                onClick={toggleEditMode}
              >
                Edit
              </a>
            ) : (
              <a
                style={{
                  position: 'absolute',
                  right: '16px',
                  top: '28px',
                }}
                onClick={toggleEditMode}
              >
                Reset
              </a>
            )}
          </div>
          {error || localError ? (
            <p
              style={{
                margin: '0 auto 32px',
                width: '80%',
                textAlign: 'center',
                color: 'var(--accent-red)',
              }}
            >
              {error || localError}
            </p>
          ) : !editMode ? (
            <p
              style={{
                margin: '0 auto 32px',
                width: '80%',
                textAlign: 'center',
              }}
            >
              Your can change it later in Gym Settings
            </p>
          ) : (
            <p
              style={{
                margin: '0 auto 32px',
                width: '80%',
                textAlign: 'center',
              }}
            >
              Up to 6 letters and numbers
            </p>
          )}
        </form>
      </div>
    </Modal>
  )
}

export default AccessCode
