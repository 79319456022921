import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  QueryClient as FirebaseQueryClient,
  QueryClientProvider as FirebaseQueryClientProvider,
} from 'react-query'

import ReactPixel from 'react-facebook-pixel'

import { PostHogProvider } from 'posthog-js/react'

const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient()
const firebaseQueryClient = new FirebaseQueryClient() //react-query-firebase doesn't work with tanstack/react-query

React.useLayoutEffect = React.useEffect

if (
  window.location.host.indexOf('localhost') < 0 &&
  window.location.host.indexOf('192.168.4.50') < 0 &&
  (window.location.protocol.toLowerCase() !== 'https:' ||
    window.location.host.indexOf('www') > -1)
) {
  const url = `https://${window.location.host.replace('www.', '')}`
  window.location.replace(url)
}

// Disabling console logs in prod
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.debug = () => {}
}

const postHogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

root.render(
  //<React.StrictMode>
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={postHogOptions}
  >
    <FirebaseQueryClientProvider client={firebaseQueryClient}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <App />
      </QueryClientProvider>
    </FirebaseQueryClientProvider>
  </PostHogProvider>
  //</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
