import axios from 'axios'
import { auth } from '../firebase'
import API from '.'

export const joinGymByID = async (gymID: string) => {
  const authToken = await auth.currentUser?.getIdToken()

  try {
    await axios.post(API.url + `joinGym`, {
      gymID,
      authToken,
    })
  } catch (err: any) {
    console.error(err.response.data)
  }
}
