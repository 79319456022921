import {
  collection,
  CollectionReference,
  query,
  where,
} from 'firebase/firestore'
import { Result } from '../../types/results'
import { useContext, useEffect, useMemo } from 'react'
import { useFirestoreQueryData } from '@react-query-firebase/firestore'
import { firestore } from '../../firebase'

type Props = {
  eventID: string
}

const useResultsForEvent = ({ eventID }: Props) => {
  const queryKey = useMemo(() => ['results', eventID], [eventID])

  const collectionRef = query(
    collection(firestore, 'results') as CollectionReference<Result.Full>,
    where('eventItemID', '==', eventID),
    where('isDeleted', '==', false)
  )
  const {
    data: results,
    isLoading,
    error,
    refetch,
  } = useFirestoreQueryData<Result.Full>(
    queryKey,
    collectionRef,
    {},
    {
      enabled: eventID !== 'no-id',
    }
  )

  return {
    results: results || [],
    error,
    retryErrorQuery: refetch,
    isLoading,
  }
}

export default useResultsForEvent
