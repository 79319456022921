import React, { useEffect, useState } from 'react'
import RegisterModule from './Register'
import css from './AuthModule.module.css'
import VerifyModule from './Verify'
import LoginModule from './Login'
import ResetModule from './Reset'
import useAuth, { AuthMode } from '../../../hooks/useAuth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { useURLParams } from '../../../hooks/useURLParams'
import { toast, Toaster } from 'react-hot-toast'

type Props = {
  onComplete: () => void
  forceMode?: AuthMode
  isVisible: boolean
  introMode: 'create-gym' | 'join-gym'
  authFlow: string
}

const AuthModule = ({
  onComplete,
  isVisible,
  forceMode,
  introMode,
  authFlow,
}: Props) => {
  const animationDelay = 200
  const [user] = useAuthState(auth)
  const [isHidden, setHidden] = useState<boolean>(false)

  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  const modeViaParams = params.get('mode')
  const actionCode = params.get('oobCode')
  const { setSearchParams } = useURLParams()

  let defaultMode: AuthMode | null = null

  const {
    authForm,
    mode,
    setMode,
    setFocus,
    handleVerifyEmail,
    clearErrors,
    clearFields,
    isLoading,
    isSuccess,
    error,
  } = useAuth({
    defaultMode:
      modeViaParams === 'resetPassword' ? 'resetEnterNewPassword' : null,
    actionCode: actionCode,
    introMode: introMode,
  })

  useEffect(() => {
    if (mode === 'register' && authFlow) {
      setSearchParams({ page: authFlow })

      return
    }

    if (mode === 'login') {
      setSearchParams({ page: 'auth_sign_in' })

      return
    }

    if (mode === 'reset') {
      setSearchParams({ page: 'auth_reset_password' })

      return
    }

    if (mode === 'verify' && !user?.emailVerified) {
      console.log(mode)
      setSearchParams({
        page: 'auth_verify_email',
      })

      return
    }
  }, [mode, authFlow, user])

  useEffect(() => {
    if (!user && modeViaParams === 'resetPassword')
      setMode('resetEnterNewPassword')
    else if (!user && mode !== 'register') setMode('register')
    else if (user && !user.emailVerified) setMode('verify')
  }, [user])

  useEffect(() => {
    if (isVisible && !isLoading) {
      if (
        modeViaParams !== 'verifyEmail' &&
        modeViaParams !== 'resetPassword' &&
        !user
      ) {
        return
      } else
        switch (modeViaParams) {
          case 'verifyEmail': {
            // Display email verification handler and UI.
            if (user && !user.emailVerified) handleVerifyEmail()
            break
          }
          default:
            break
          // Error: invalid mode.
        }
    }
  }, [isVisible, search])

  useEffect(() => {
    if (isVisible)
      setTimeout(() => {
        setFocus('name')
      }, 400)
  }, [isVisible])

  const onModeSwitch = (newMode: AuthMode) => {
    if (newMode === mode) return
    else if (newMode === 'complete') {
      onComplete()
      setTimeout(() => {
        //navigate(`/${search}`)
      }, 400)
    } else {
      setHidden(true)
      setTimeout(() => {
        //navigate(`/${search}`)
        clearErrors()
        setMode(newMode)
        setHidden(false)
        setTimeout(() => {
          // setting focus without delay
          // messes up the animatin
          switch (newMode) {
            case 'register': {
              setFocus('name')
              break
            }
            case 'login':
            case 'reset': {
              setFocus('email')
              break
            }
            case 'resetEnterNewPassword': {
              setFocus('password')
              break
            }
          }
        }, animationDelay)
      }, 400)
    }
  }

  useEffect(() => {
    if (isSuccess && isVisible) {
      /* Login and register success cases are treated from the parent element.
       * When AuthState updates, it displays a corresponding auth block
       */
      console.log('success', mode)
      switch (mode) {
        case 'resetEnterNewPassword': {
          navigate(`/${search}`)
          clearFields()
          toast.success('Password updated successfuly!')
          onModeSwitch('login')
          break
        }
      }
    }
  }, [isSuccess])

  const authModules = {
    login: <LoginModule {...authForm} onModeSwitch={onModeSwitch} />,
    register: <RegisterModule {...authForm} onModeSwitch={onModeSwitch} />,
    reset: <ResetModule {...authForm} onModeSwitch={onModeSwitch} />,
    resetEnterNewPassword: (
      <ResetModule
        {...authForm}
        onModeSwitch={onModeSwitch}
        setNewPassword={true}
      />
    ) /*reset link pressed*/,
    resetSent: (
      <ResetModule {...authForm} onModeSwitch={onModeSwitch} isSent={true} />
    ),
    verify: (
      <VerifyModule
        onModeSwitch={onModeSwitch}
        isLoading={isLoading}
        error={error}
      />
    ),
    complete: (
      <VerifyModule
        onModeSwitch={onModeSwitch}
        isLoading={isLoading}
        error={error}
      />
    ),
  }

  return (
    <div className={`${css['auth-wrapper']} ${isHidden && css['hidden']}`}>
      <Toaster
        toastOptions={{
          success: {
            iconTheme: {
              primary: 'var(--accent-green)',
              secondary: 'white',
            },
            style: {
              borderRadius: '8px',
            },
          },
        }}
      />
      {authModules[mode as AuthMode]}
    </div>
  )
}

export default AuthModule
