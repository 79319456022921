import { Query } from '@tanstack/react-query'
import { DateTime } from 'luxon'

const getMonthEventsDateRange = (
  date: DateTime
): { fromDate: string; toDate: string } => {
  const fromDate = date.startOf('month')
  const toDate = date.endOf('month')

  return {
    fromDate: fromDate.toISODate(),
    toDate: toDate.toISODate(),
  }
}

export default getMonthEventsDateRange
