import React, { HTMLAttributes } from 'react'
import css from './ButtonToolbar.module.css'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  style?: React.CSSProperties
  onClick: React.MouseEventHandler<HTMLDivElement>
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
  icon?: JSX.Element
  type?: 'light' | 'dark'
  size?: 'large'
  className?: string
}

const ButtonToolbar = ({
  style,
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
  icon,
  type,
  className,
  title,
  size,
}: Props) => {
  return (
    <div
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${css['button-toolbar']} ${type && css[type]} ${className} ${
        !children && css['no-text']
      } ${size === 'large' && css['large']}`}
      title={title}
    >
      {icon && icon}
      {children}
    </div>
  )
}

export default ButtonToolbar
