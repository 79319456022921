import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { GymMemberData } from '../../types/types'
import Button from '../buttons/Button/Button'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'
import css from './Modal.module.css'
import useGym from '../../hooks/useGym'

type Props = {
  isVisible: boolean
  onDismiss: () => void
}

const ActiveMember = ({ isVisible, onDismiss }: Props) => {
  //const [isLoading, setLoading] = useState(false)

  const actions = {
    secondary: {
      onClick: onDismiss,
    },
    primary: {
      label: 'Got it!',
      onClick: onDismiss,
    },
  }

  return (
    <Modal
      isVisible={isVisible}
      onCancel={onDismiss}
      size="small"
      buttons={actions}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          color: 'black',
          gap: '16px',
        }}
      >
        <h2>Active Members</h2>

        <p>
          Any athlete that tracked a workout at your gym is considered an active
          member.
        </p>
        <h3 style={{ fontSize: '20px', marginTop: '8px' }}>
          What if I reach my plan's quota?
        </h3>
        <p>
          We look at the number of unique active users during a subscription
          period or, for yearly subscriptions, during a calendar month. Then, at
          the end of the subscription period, extra member charges (if any) are
          calculated using the amount over the active member quota.
        </p>
        <p style={{ marginBottom: '8px' }}>
          For example, let's assume you have the "Up to 5 active members" yearly
          plan. And during that year, there were 2 months with 5+3 active
          members. Therefore, an extra charge of 2×3×$2.50 will be added to the
          total subscription cost for that period.
        </p>
      </div>
    </Modal>
  )
}

export default ActiveMember
