import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import './_Auth.css'
import Button from '../../../components/buttons/Button/Button'
import Logo from '../../../components/atoms/Logo/Logo'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { translateAuthErrors } from '../../../helpers'
import { AuthForm } from '../../../hooks/useAuth'

function LoginModule({
  name,
  email,
  password,
  isLoading,
  error,
  handleSubmit,
  onModeSwitch,
}: AuthForm) {
  return (
    <div className="auth_container module">
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit}>
        <input
          className="login__textBox"
          {...email}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="password__textBox"
          {...password}
          placeholder="Password"
          autoComplete="current-password"
          id="current-password"
        />
        {error && <div className={`under-input-message error`}>{error}</div>}
        <Button
          className="login__btn"
          isSubmitButton={true}
          style={{ marginTop: '28px' }}
          isLoading={isLoading}
        >
          Sign In
        </Button>
      </form>
      {/* <Button
          className="login__google"
          onClick={signInWithGoogle}
          style={{
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          Sign In with Google
        </Button> */}
      <div>
        <br />
        Don't have an account?
        <br />
        <a onClick={() => onModeSwitch('register')}>Create Account</a> now
      </div>
      <br />
      <div>
        <a onClick={() => onModeSwitch('reset')}>Forgot Password?</a>
      </div>
    </div>
  )
}
export default LoginModule
