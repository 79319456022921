import React, { CSSProperties, useState } from 'react'

import css from '../TextInput/TextInput.module.css'
import { Plus as IconPlus, Minus as IconMinus } from 'react-feather'
import NumberInput from '../NumberInput/NumberInput'
import { useFormContext } from 'react-hook-form'
import TextInput from '../TextInput/TextInput'
import { RewodLegacyEvent } from '../../../types/legacyEvents'
import { translateRoundsRestTime } from '../../../helpers_ts'

type Props = {
  className?: string
  name: string
  label?: string
  style?: CSSProperties
}

const DoubleTimeInput = ({ className, name, label, style }: Props) => {
  const { register, setValue, getValues, watch } = useFormContext()

  const currentValue = watch(name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEntryValue = transformValue(e.target.value)

    if (newEntryValue !== undefined) {
      const valueBreakdown = newEntryValue?.split('/')
      const workParse = valueBreakdown?.[0] || ''
      const workMin = parseInt(workParse?.split(':')[0]) || 0
      const workSec = parseInt(workParse?.split(':')[1]) || 0

      const restParse = valueBreakdown?.[1] || workParse || ''

      const restSec = parseInt(restParse?.split(':')[1])
        ? parseInt(restParse?.split(':')[1])
        : restParse
        ? 0
        : workSec
      const restMin = parseInt(restParse?.split(':')[0])
        ? parseInt(restParse?.split(':')[0])
        : restSec
        ? 0
        : workMin

      const newValue: RewodLegacyEvent.Item['roundRestTime'] = {
        entryValue: newEntryValue,
        work: {
          min: workMin,
          sec: workSec,
        },
        rest: {
          min: restMin,
          sec: restSec,
        },
      }

      setValue(name, newValue)
    } else setValue(name, currentValue)
  }

  return (
    <>
      <TextInput
        style={style}
        label={'Work/Rest Time'}
        centered={true}
        hintText={'I.e: 2, :30 or 2:30/1:30'}
        subText={translateRoundsRestTime(currentValue) || ''}
        formMethods={{
          ...register(`${name}.entryValue`, {
            setValueAs: (value) =>
              transformValue(value) !== undefined
                ? transformValue(value)
                : currentValue.entryValue,
            onChange: handleChange,
          }),
        }}
      />
    </>
  )
}

export default DoubleTimeInput

const transformValue = (value: string) => {
  const regex =
    /^(?:(\d{1,2}):)?(?::(\d{1,2})(?:\/|$)|\d{1,2}(?:\/|$)|(?::|\/))?(?:(\d{1,2})\/?)?(?::(\d{1,2})|:)?$/

  if (regex.test(value) && value !== '/')
    return value.replaceAll(':/', '/').replace(/:(\d)\/$/, ':$10/')
  else console.log('invalid', value)
}
