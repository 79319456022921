import React, { useState, useEffect, useRef } from 'react'
import css from './NoteInput.module.css'
import TextareaAutosize from 'react-textarea-autosize'
import { X as IconMinusCircle, AlignJustify as IconDrag } from 'react-feather'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { RewodLegacyEvent } from '../../../types/legacyEvents'
import { useFormContext } from 'react-hook-form'
import { Tooltip } from 'react-tooltip'

type Props = {
  id: string
  index: number
  onRemove: () => void
  isDragOverlay?: boolean
  sortingInProgress?: boolean
  packPosition: 'first' | 'middle' | 'last' | 'lonely'
  canSort: boolean
  parentFieldName: string
}

const Note = ({
  id,
  index,
  onRemove,
  isDragOverlay,
  sortingInProgress,
  packPosition,
  canSort,
  parentFieldName,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id, attributes: { tabIndex: -1 } })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const { register } = useFormContext()

  return (
    <div
      className={`
        ${css['everything']} 
        ${isDragOverlay ? css['drag-overlay'] : ''} 
        ${isDragging ? css['hide'] : ''}
        ${sortingInProgress ? css['sorting'] : ''}
      `}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {['first', 'lonely'].includes(packPosition) && (
        <div className={css['labels']}>
          <label style={{ float: 'left' }}>Note</label>
        </div>
      )}
      <div
        className={`${css['inputs-and-icon-container']} ${css[packPosition]}`}
      >
        {canSort && (
          <div
            data-tooltip-id="t-drag-hint"
            data-tooltip-content="Drag to reorder"
            className={css['drag-icon']}
            {...listeners}
          >
            <IconDrag size={20} color={'#ccc'} />
          </div>
        )}
        <div className={`${css['inputs-container']} `}>
          <TextareaAutosize
            minRows={1}
            className={`${css['note-input']}`}
            placeholder="Note..."
            {...register(`${parentFieldName}.${index}.note`)}
          />
        </div>
        <div onClick={onRemove} className={css['icon-container']}>
          <IconMinusCircle size={16} className={css['icon']} />
        </div>
      </div>

      <div className={css['overlay']} />
    </div>
  )
}

export default Note
