import React, { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'

type Props = {}

const Footer = (props: Props) => {
  const year = new Date().getFullYear()
  const { user, userID } = useContext(UserContext)

  const supportRefCode =
    Math.floor(1000 + Math.random() * 9000) +
    userID.substring(0, 8) +
    Math.floor(10 + Math.random() * 90)

  //docs.google.com/forms/d/e/1FAIpQLScV-cOcm43D8YgtuAN9qJx8q4KPJPCG5BXsbg-d547TeIpahQ/viewform?usp=pp_url&entry.140728056=Test

  https: return (
    <footer>
      &copy; {year} Rewod{' '}
      <ul>
        <li>
          <a href="/policies/terms-of-use.html" target="_blank">
            Terms of Use
          </a>
        </li>
        <li>
          <a href="/policies/privacy-policy.html" target="_blank">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="https://forms.gle/dkxqQtEL4mg4Wyje8" target="_blank">
            Send Feedback
          </a>
        </li>
        <li>
          <a
            href={`https://docs.google.com/forms/d/e/1FAIpQLScV-cOcm43D8YgtuAN9qJx8q4KPJPCG5BXsbg-d547TeIpahQ/viewform?usp=pp_url&entry.16509963=${supportRefCode}`}
            target="_blank"
          >
            Contact Support
          </a>
        </li>{' '}
      </ul>
    </footer>
  )
}

export default Footer
