import { BarLoader } from 'react-spinners'
import ButtonIcon from '../../../buttons/ButtonIcon/ButtonIcon'
import ButtonToolbar from '../../../buttons/ButtonToolbar/ButtonToolbar'
import useCalendar from '../../../../hooks/calendar/useCalendar'
import {
  IconArrowUTurn,
  IconArrowLeft,
  IconArrowRight,
  IconTV,
  IconCycle,
} from '../../../atoms/Icons/Icons'
import css from '../SecondaryNav.module.css'
import { useContext } from 'react'
import CalendarContext from '../../../../context/CalendarContext'
import { Send, Share, Tv } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useWorkoutPublisher } from '../../../../hooks/useWorkoutPublisher'
import { Analytics } from '../../../../services/analytics'
import { CalendarViewToggle } from '../../../atoms/CalendarViewToggle'
import getMonthEventsDateRange from '../../../../utilities/queries/getMonthEventsDateRange'
import { CalendarEvent } from '../../../../types/calendarEvents'
import useCachedCalendarEvents from '../../../../hooks/calendar/useCachedCalendarEvents'

const CalendarNav = () => {
  const {
    dispatchAction,
    showPrevMonth,
    showNextMonth,
    goToToday,
    isScrolling,
    calendarRef,
    calendar: { selected, today },
  } = useContext(CalendarContext)

  const { fromDate, toDate } = getMonthEventsDateRange(selected.date)

  const { allCachedEvents } = useCachedCalendarEvents()

  const unpublishedEvents =
    allCachedEvents?.filter(
      (event: CalendarEvent.Workout) =>
        !event.isPublished &&
        event.eventDateISO >= fromDate &&
        event.eventDateISO <= toDate
    ) || null

  const { publishDrafts } = useWorkoutPublisher()

  const handlePublishAllDrafts = () => {
    calendarRef?.classList.remove('highlight-drafts')
    if (unpublishedEvents) publishDrafts(unpublishedEvents)
  }

  const publishButtonMouseEnter = () =>
    unpublishedEvents && calendarRef?.classList.add('highlight-drafts')

  const publishButtonMouseLeave = () =>
    unpublishedEvents && calendarRef?.classList.remove('highlight-drafts')

  const goFullscreen = () => {
    const element = document.documentElement as any // TS throws errors otherwise
    try {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        // IE/Edge
        element.msRequestFullscreen()
      }
    } catch (err) {
      console.log('Error entering fullscreen', err)
    }
  }

  const navigate = useNavigate()
  const onGymTVClick = () => {
    Analytics._logEvent({ name: 'gym_tv_click', params: {} })
    goFullscreen()
    navigate('/tv')
  }

  const onCycleClick = () => {
    Analytics._logEvent({ name: 'cycle_click', params: {} })
    dispatchAction('show-cycles')
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div className={css['arrows']}>
          <ButtonIcon
            onClick={showPrevMonth}
            icon={<IconArrowLeft color={'var(--accent)'} />}
          />
          <ButtonIcon
            onClick={showNextMonth}
            icon={<IconArrowRight color={'var(--accent)'} />}
          />
        </div>
        <div className={css['month-and-year']}>
          <h2 className={css['month-title']}>
            {selected.monthName}
            <span className="light">&nbsp;{selected.year}</span>
          </h2>
        </div>
        {(selected.monthNumber !== today.monthNumber ||
          selected.year !== today.year) && (
          <ButtonToolbar
            style={{
              marginLeft: '24px',
              color: 'var(--accent)',
              opacity: isScrolling ? 0 : 1,
              transition: 'opacity 0.4s',
            }}
            onClick={goToToday}
            icon={<IconArrowUTurn color="var(--accent)" />}
          >
            Today
          </ButtonToolbar>
        )}
      </div>
      <div className={css['button-group']}>
        <ButtonToolbar
          onClick={onCycleClick}
          icon={<IconCycle color="var(--accent)" size={16} />}
          title="Somehow, heartbreak feels good in a place like this"
        >
          Cycles
        </ButtonToolbar>
        <ButtonToolbar
          onClick={onGymTVClick}
          icon={<IconTV color="var(--accent)" size={16} />}
          title="Somehow, heartbreak feels good in a place like this"
        >
          Gym TV
        </ButtonToolbar>
        {!!unpublishedEvents.length && (
          <ButtonToolbar
            onClick={handlePublishAllDrafts}
            onMouseEnter={publishButtonMouseEnter}
            onMouseLeave={publishButtonMouseLeave}
            icon={<Send size={16} color="var(--accent)" />}
          >
            Publish Drafts
          </ButtonToolbar>
        )}
        <CalendarViewToggle />
      </div>
    </>
  )
}

export default CalendarNav
