import { Timestamp } from 'firebase/firestore'
import { RewodLegacyEvent } from '../../types/legacyEvents'
import { CalendarEvent } from '../../types/calendarEvents'
import { v4 as uuid } from 'uuid'
import getNewWorkout from './getNewWorkout'

export const getNewWorkoutEvent = ({
  uid,
  eventDateISO,
  index,
  workoutType,
}: {
  uid: string
  eventDateISO: string
  index: number
  workoutType?: 'single' | 'superset'
}) => {
  const newEvent: CalendarEvent.Workout = {
    id: 'ev_' + uuid().substring(0, 8),
    index: index,
    createdBy: uid,
    createdDate: Timestamp.now(),
    updatedBy: uid,
    updatedDate: Timestamp.now(),
    eventDateISO,
    type: 'workout',
    details: getNewWorkout(uid, workoutType),
    isDeleted: false,
    isPublished: false,
  }

  return newEvent
}

/**
 *
 * @deprecated getNewLegacyEvent
 */
export const getNewLegacyEvent = (uid: string, activeDay: string) => {
  const newEvent: RewodLegacyEvent.Data = {
    id: 'ev_' + activeDay,
    createdBy: uid,
    createdDate: Timestamp.now(),
    updatedBy: uid,
    updatedDate: Timestamp.now(),
    eventDate: activeDay,
    eventItems: [],
    results: null,
  }

  return newEvent
}
