export const getRandomCycleName = () => {
  const randomNames = [
    'Power Tower',
    'Core Score',
    'Cardio Shmardio',
    'Muscle Hustle',
    'Speed Exceed',
    'Endurance Assurance',
    'Power Shower',
    'Gymnastic Fantastic',
    'Swift Lift',
    'Crunch Punch',
    'Snatch Hatch',
    'Clean Machine',
  ]

  return randomNames[Math.floor(Math.random() * randomNames.length)]
}
