import {
  URLSearchParamsInit,
  useSearchParams,
  useLocation,
} from 'react-router-dom'

export const useURLParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const prevParams = new URLSearchParams(location.search)

  const setParams = (params: URLSearchParamsInit) => {
    const newParams = typeof params === 'object' ? params : { params }

    setSearchParams({ ...Object.fromEntries(prevParams), ...newParams })
  }

  return { setSearchParams: setParams, searchParams }
}
