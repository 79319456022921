interface IConfig {
  access: {
    [key: string]: string[]
  }
}

export const config: IConfig = {
  access: {
    playbook_add_new_workout: [
      'xp0Uw1tY6KVebrz1juX117UAfUr1', // prod
      'XP1lHcYvRROAH9J9EUQ5XCEp4wM2', // prod
      'v8Sbq7prEFZa0AEf02RZirjLPgl1', // dev
    ],
  },
}
