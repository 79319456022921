import { signOut } from '@firebase/auth'
import React, { useContext, useEffect, useState } from 'react'
import { Clock, Frown, Lock, Send, XOctagon } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import useAuth from '../../../hooks/useAuth'
import EditEmail from '../../../components/modals/EditEmail'
import JoinGym from '../../../components/modals/JoinGym'
import GymSelector from '../../../components/atoms/GymSelector/GymSelector'
import { IconBarbell } from '../../../components/atoms/Icons/Icons'
import { AppContext } from '../../../context/AppContext'
import { auth } from '../../../firebase'
import css from './SystemMessage.module.css'
import { Analytics } from '../../../services/analytics'

type Props = {
  type:
    | 'access-pending'
    | 'access-denied'
    | 'subscription-inactive'
    | 'reverify-email'
    | 'recover-email'
    | 'failed-to-fetch-subscription'
}

const SystemMessage = ({ type }: Props) => {
  const { spaces, currentSpace } = useContext(AppContext)
  const [joinGymModalIsVisible, setJoinGymModalIsVisible] = useState(false)

  const [visibleModal, setVisibleModal] = useState<
    'name' | 'email' | 'joinGym' | 'email' | null
  >(null)

  const { search } = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(search)

  const modeViaParams = params.get('mode')
  const actionCode = params.get('oobCode')

  const { handleVerifyEmail, handleRecoverEmail, isLoading } = useAuth({
    actionCode,
  })

  switch (modeViaParams) {
    case 'verifyEmail': {
      if (auth.currentUser && !auth.currentUser.emailVerified && !isLoading) {
        handleVerifyEmail()
        window.location.replace('/')
      }
      break
    }
    case 'recoverEmail': {
      if (auth.currentUser && !isLoading) {
        handleRecoverEmail()
      }
    }
  }

  const getMessage = () => {
    switch (type) {
      case 'access-pending':
        return (
          <div className={css['block']}>
            <IconBarbell
              size={32}
              color="var(--accent)"
              style={{ margin: '8px 0 16px' }}
            />
            <h1>
              <span>Welcome to</span>
              <br />
              {currentSpace?.title}
            </h1>
            <h3>Coach or Manager?</h3>
            <p>
              You will be able to access the gym from the web once you get an
              appropriate role or permissions.
            </p>
            <h3>Athlete?</h3>
            <p>Get Rewod app and start tracking your workouts</p>
            <div className={css['store-buttons']}>
              <a
                href="https://apps.apple.com/us/app/rewod-workouts-community/id6444032971"
                target="_blank"
                onClick={() => {
                  Analytics._logEvent({
                    name: 'click__appstore_link',
                    params: {},
                  })
                }}
              >
                <div className={css['app-store-button']}>
                  <img src="/img/dl-app-store-dark.svg" />
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=io.rewod.app"
                target="_blank"
              >
                <div className={css['app-store-button']}>
                  <img src="/img/google-play-badge.svg" />
                </div>
              </a>
            </div>
          </div>
        )
      case 'access-denied':
        return (
          <div className={css['block']}>
            <XOctagon
              size={32}
              color="var(--accent)"
              style={{ margin: '8px 0 16px' }}
            />
            <h1 style={{ marginBottom: '8px' }}>Access Revoked</h1>
            <p>
              Your access {currentSpace && `to ${currentSpace.title} `}has been
              revoked by gym staff. Please contact a gym representetive for
              details.
            </p>
          </div>
        )
      case 'subscription-inactive':
        return (
          <div className={css['block']}>
            <Frown
              size={32}
              color="var(--accent)"
              style={{ margin: '8px 0 16px' }}
            />
            <h1 style={{ marginBottom: '8px' }}>Subscription Expired</h1>
            <p>
              {currentSpace && `${currentSpace.title} `} subscription expired.
            </p>
          </div>
        )

      case 'failed-to-fetch-subscription':
        return (
          <div className={css['block']}>
            <Frown size={64} strokeWidth={1.5} color="var(--accent)" />
            <h1 style={{ marginBottom: '8px' }}>
              Couldn't Load
              <br />
              Gym Data
            </h1>
            <p>
              Please check your internet connection. If the issue persists,
              please contact Rewod&nbsp;at{' '}
              <a href="mailto:support@rewod.io">support@rewod.io</a>
            </p>
          </div>
        )

      case 'recover-email':
        return (
          <div className={css['block']}>
            <ClipLoader color="var(--accent)" speedMultiplier={0.8} />
            <h2 style={{ margin: '16px 0 0' }}>Recovering E-mail</h2>
          </div>
        )

      case 'reverify-email':
        return isLoading ? (
          <>
            <div className={css['block']}>
              <ClipLoader color="var(--accent)" speedMultiplier={0.8} />
              <h2 style={{ margin: '16px 0 0' }}>Verifying E-mail</h2>
            </div>
          </>
        ) : (
          <>
            <EditEmail
              onDismiss={() => setVisibleModal(null)}
              isVisible={visibleModal === 'email'}
            />
            <div className={css['block']}>
              <Send
                size={32}
                color="var(--accent)"
                style={{ margin: '8px 0 16px' }}
              />
              <h1 style={{ marginBottom: '20px' }}>
                Please verify your&nbsp;e-mail
              </h1>
              <p>
                Please click a link sent to{' '}
                <strong>{auth.currentUser?.email}</strong>
                <br />
                to verify your e-mail.
              </p>
              <br />
              <span className="link" onClick={() => setVisibleModal('email')}>
                Incorrect E-Mail?
              </span>
            </div>
          </>
        )
    }
  }

  return (
    <div
      className={`${css['bg']} ${
        type === 'failed-to-fetch-subscription' ? css['solid'] : ''
      }`}
    >
      <JoinGym
        isVisible={joinGymModalIsVisible}
        onDismiss={() => setJoinGymModalIsVisible(false)}
      />
      {spaces.length > 1 && (
        <GymSelector style={{ position: 'absolute', top: '-2px' }} />
      )}
      <div className={css['links']}>
        {type !== 'reverify-email' && (
          <span onClick={() => setJoinGymModalIsVisible(true)}>
            Join a different gym
          </span>
        )}
        <span onClick={() => signOut(auth)}>Logout</span>
      </div>
      <div className={css['container']}>
        {!joinGymModalIsVisible && getMessage()}
      </div>
    </div>
  )
}

export default SystemMessage
