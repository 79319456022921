import React, { CSSProperties, ReactNode, useState } from 'react'
import css from './ButtonArea.module.css'
import { IconNewWorkout } from '../../atoms/Icons/Icons'

type Props = {
  // type: 'single' | 'superset'
  onClick: () => void
  className?: string
  style?: CSSProperties
  icon: ReactNode
  iconOnHover?: ReactNode
  children: ReactNode
}

const ButtonArea = ({ onClick, children, icon, iconOnHover }: Props) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className={`${css['container']}`}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={css['icon-container']}>
        {hovered && iconOnHover ? iconOnHover : icon}
      </div>
      <div className={css['label']}>{children}</div>
    </div>
  )
}

export default ButtonArea
