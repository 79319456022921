import { useFirestoreQueryData } from '@react-query-firebase/firestore'
import { collection, query, where } from 'firebase/firestore'
import { firestore } from '../firebase'
import { Workout } from '../types/workouts'
import { useEffect, useMemo, useState } from 'react'
import { set } from 'lodash'

export const useWorkoutSet = (workoutData: Workout.Item, queryKey: string) => {
  const setID = workoutData.set?.id
  const playbookReferenceID = workoutData.playbookReferenceID

  const setRef = query(
    collection(firestore, 'workouts'),
    where('set.id', '==', setID || 'no-id'),
    where('id', '!=', playbookReferenceID || 'no-id') // Filtering out the current workout
  )

  const [setItems, setSetItems] = useState<Workout.Item[]>(
    workoutData ? [workoutData] : []
  )

  const defaultScalingOption: Workout.ScalingOption = {
    ageGroup: 'masters',
    effort: 'rx',
    genderGroup: 'women',
  }
  const [activeScalingOption, setActiveScalingOption] =
    useState<Workout.ScalingOption>(defaultScalingOption)

  const [activeWorkout, setActiveWorkout] = useState<Workout.Item>(workoutData)

  useEffect(() => {
    setActiveWorkout(workoutData)
    //setSetItems([workoutData])
  }, [workoutData])

  const setItemsRaw = useFirestoreQueryData(
    ['workout-set', { queryKey, setID, playbookReferenceID }],
    setRef,
    {
      subscribe: false,
    },
    {
      enabled: !!setID,
      onSuccess(snapshot: any) {},
      onError(error: any) {
        console.error('Failed to get workouts by setID')
      },
    }
  )

  // useEffect(() => {
  //   if (setItemsRaw.data && setItemsRaw.data.length) {
  //     const setItemsSnapshot = setItemsRaw.data.map((doc: any) => doc)
  //     if (setItemsSnapshot && setItemsSnapshot.length) {
  //       // Filtering the main item
  //       const filteredSetItems = setItemsSnapshot.filter(
  //         (item: Workout.Item) => setItems.indexOf(item) === -1
  //       )
  //       //const currentSetItems = [...setItems, ...filteredSetItems]
  //       const currentSetItems = [workoutData, ...filteredSetItems]
  //       setSetItems(currentSetItems)
  //     }
  //   } else setSetItems([workoutData])
  // }, [workoutData, setItemsRaw.data])

  const currentSetItems = useMemo(
    () =>
      setItems.length > 1
        ? setItems
        : setItemsRaw.data
        ? [workoutData, ...(setItemsRaw.data as Workout.Item[])]
        : [workoutData],
    [setItems, setItemsRaw.data]
  )

  const handleScalingChange = (item: Workout.Item | undefined) => {
    if (item) setActiveWorkout(item)
  }

  return {
    setItems: currentSetItems,
    setItemsAreLoading: setItemsRaw.isLoading,
    activeWorkout,
    activeScalingOption,
    defaultScalingOption,
    temporarilyUpdateSetItems: (data: any) => {
      console.log('data', data)
      setSetItems(data)
    },
    onScalingChange: handleScalingChange,
  }
}
