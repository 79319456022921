import { Tooltip } from 'react-tooltip'
import { ReactElement, useContext, useId } from 'react'

import SegmentedInput from '../../inputs/SegmentedInput/SegmentedInput'
import { IconCollapse, IconExpand } from '../Icons/Icons'
import { AppContext, CalendarViewState } from '../../../context/AppContext'

import css from './CalendarViewToggle.module.css'
import { Analytics } from '../../../services/analytics'

export const CalendarViewToggle = () => {
  const { calendarViewState, setCalendarViewState } = useContext(AppContext)
  const collapseTooltipId = useId()
  const expandTooltipId = useId()

  return (
    <>
      <Tooltip
        id={collapseTooltipId}
        place="bottom"
        delayShow={400}
        className="tooltip"
        noArrow={true}
        style={{
          marginTop: '4px',
        }}
        children={<span>Compact View</span>}
      />
      <Tooltip
        id={expandTooltipId}
        place="bottom"
        delayShow={400}
        className="tooltip"
        noArrow={true}
        style={{
          marginTop: '4px',
        }}
        children={<span>Full View</span>}
      />
      <SegmentedInput
        segments={[
          {
            label: (
              <span className={css.item} data-tooltip-id={collapseTooltipId}>
                <IconCollapse
                  color={
                    calendarViewState === CalendarViewState.Collapse
                      ? 'white'
                      : 'var(--icon-white)'
                  }
                />
              </span>
            ) as ReactElement,
            value: CalendarViewState.Collapse,
          },
          {
            label: (
              <span className={css.item} data-tooltip-id={expandTooltipId}>
                <IconExpand
                  color={
                    calendarViewState === CalendarViewState.Expand
                      ? 'white'
                      : 'var(--icon-white)'
                  }
                />
              </span>
            ) as ReactElement,
            value: CalendarViewState.Expand,
          },
        ]}
        value={calendarViewState}
        dark
        style={{
          height: '30px',
        }}
        onChange={(value) => {
          Analytics._logEvent({
            name: 'calendar_view_toggle',
            params: {
              $set: { calendarViewState: value },
            },
          })
          setCalendarViewState(value as CalendarViewState)
        }}
      />
    </>
  )
}
