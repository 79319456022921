import React from 'react'
import { Workout } from '../../../types/workouts'
import { Dropdown as MuiDropdown } from '@mui/base/Dropdown'
import { Menu } from '@mui/base/Menu'
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton'
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem'
import { styled } from '@mui/system'
import { capitalize } from 'lodash'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import { useMenuButton } from '@mui/base/useMenuButton'
import {
  ArrowLeft,
  ArrowRight,
  ChevronDown,
  ChevronUp,
  ChevronsDown,
  Layers,
} from 'react-feather'
import { useDropdown } from '@mui/base'

type Props = {
  items: {
    label: string
    type: 'value' | 'divider'
    id: string
  }[]
  innerTitle?: string
  activeItem: {
    label: string
    id: string
    type: 'value'
  }
  onChange: (id: string) => void
  style?: React.CSSProperties
}

export const Dropdown = ({
  items,
  innerTitle,
  activeItem,
  onChange,
  style,
}: Props) => {
  const showNext = () => {
    const currentIndex = items.findIndex((item) => item.id === activeItem.id)
    const nextIndex = items.findIndex(
      (item, index) => item.type !== 'divider' && index > currentIndex
    ) // should not be a divider
    const nextItem = items[nextIndex]
    if (nextItem) {
      onChange(nextItem.id)
    } else {
      onChange(items[0].id)
    }
  }

  const showPrevious = () => {
    const currentIndex = items.findIndex((item) => item.id === activeItem.id)
    const previousIndex =
      items[currentIndex - 1]?.type === 'divider'
        ? currentIndex - 2
        : currentIndex - 1
    const previousItem = items[previousIndex]
    if (previousItem) {
      onChange(previousItem.id)
    } else {
      onChange(items[items.length - 1].id)
    }
  }

  return (
    <div style={{ display: 'flex', ...style }}>
      <ButtonToolbar
        onClick={showPrevious}
        type="light"
        icon={<ArrowLeft size={16} />}
        style={{
          borderRadius: '4px 0 0 4px',
          padding: '0 8px',
        }}
      />
      <MuiDropdown>
        <MenuButton>
          <span>{activeItem.label}</span>
        </MenuButton>

        <Menu slots={{ listbox: Listbox }} style={{ zIndex: 99999 }}>
          <MenuSection label={innerTitle || ''}>
            {items.map((item) =>
              item.type === 'value' ? (
                <MenuItem
                  onClick={() => onChange(item.id)}
                  key={item.id}
                  style={{
                    fontWeight: item.id === activeItem.id ? 'bold' : 'normal',
                  }}
                >
                  {item.label}
                </MenuItem>
              ) : (
                <Divider key={item.id} />
              )
            )}
          </MenuSection>
        </Menu>
      </MuiDropdown>
      <ButtonToolbar
        onClick={showNext}
        type="light"
        icon={<ArrowRight size={16} />}
        style={{
          borderRadius: '0 4px 4px 0',
          padding: '0 8px',
        }}
      />
    </div>
  )
}

const translateLabel = (label: string) => {
  switch (label) {
    case 'masters':
      return 'Masters'
    case 'masters-55-plus':
      return 'Masters 55+'
    case 'teens':
      return 'Teens'
    default:
      return capitalize(label)
  }
}

function MenuSection({ children, label }: MenuSectionProps) {
  return (
    <MenuSectionRoot role="group">
      <MenuSectionLabel>{label}</MenuSectionLabel>
      <ul>{children}</ul>
    </MenuSectionRoot>
  )
}

const Listbox = styled('ul')(
  ({ theme }) => `
    background:red;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 8px;
    overflow: auto;
    outline: 0px;
    background:  #fff;
    border: 1px solid var(--divider-solid);
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0px 4px 6px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
    };
    `
)

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
}

const Divider = styled('div')(
  ({ theme }) => `
        height: 1px;
        margin: 4px 8px;
        background-color: var(--divider);
    `
)

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
    list-style: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: default;
    user-select: none;
    font:var(--small-text);
    font-size:14px;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid var(--accent);
      background-color:var(--accent-highlight);
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: var(--accent-highlight);
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
    `
)

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
    font: var(--h6);
    box-sizing: border-box;
    display: flex;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border: 1px solid var(--divider-solid);
    flex:1;
    text-align:center;
    border-left:none;
    border-right:none;
    background-color:white;
    cursor: pointer;
    height: 32px;
    transition: all 0.1s;
    font-weight: 500;
    color: black;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    & span {
        flex:1;
        text-overflow:ellipsis;
        white-space:nowrap;
    }

    & svg {
        margin: 0 8px 0 -2px;
        color: var(--accent);
      }
      
      
    &:hover svg {
    color: white;
    stroke: white !important;
    }
      
  
  
  &:hover {
    background-color: var(--accent-hover);
    box-shadow: 0 1px 1px 0px rgba(36, 139, 83, 0.8);
    border-color: transparent;
    color: white !important;
  }
  
  &:active {
    background-color: var(--accent-clicked);
    box-shadow: none;
    border-color: transparent;
    color: white !important;
  }
  `
)

interface MenuSectionProps {
  children: React.ReactNode
  label: string
}

const MenuSectionRoot = styled('li')`
  list-style: none;

  & > ul {
    padding-left: 0;
  }
`

const MenuSectionLabel = styled('span')`
  display: block;
  padding: 8px 0 5px 8px;
  font: var(--h6);
  color: var(--secondary-text);
  margin-bottom: 4px;
`
