import React, {
  CSSProperties,
  ReactElement,
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import css from './SegmentedInput.module.css'

type SegmentElement = {
  label?: string | ReactElement
  value: string
  icon?: JSX.Element
}

type Props = {
  largeText?: boolean
  segments: SegmentElement[]
  style?: CSSProperties
  value: string
  onChange: (newValue: string) => void
  dark?: boolean
}

/** Value and value change event has to be handeled by the parent */
const SegmentedInput = ({
  segments,
  style,
  value,
  largeText,
  onChange,
  dark,
}: Props) => {
  const [platePosition, setPlatePosition] = useState(0)
  const [animated, setAnimated] = useState(false)
  // const [value, setValue] = useState(segments[0].value)
  const segmentsRef = useRef<HTMLDivElement | null>(null)

  const handleSegmentClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    newValue: string
  ) => {
    onChange(newValue)
    setPlatePosition(e.currentTarget.offsetLeft)
  }

  // const [elementRefs, setElementRefs] = useState<RefObject<HTMLDivElement>[]>(
  //   []
  // )

  // useEffect(() => {
  //   // Create an array of refs and set it as state
  //   console.log('segments', segments)
  //   setElementRefs(segments.map(() => React.createRef<HTMLDivElement>()))
  // }, [segments])

  // useEffect(() => {
  //   // Create an array of refs and set it as state
  //   console.log('rendered', segments)
  // }, [])

  const elementRefs = segments.map(() => createRef<HTMLDivElement>())

  useEffect(() => {
    if (value !== '') {
      const targetIndex = segments.findIndex((seg) => seg.value === value)
      if (targetIndex !== -1) {
        const targetRef = elementRefs[targetIndex].current
        if (targetRef) {
          setPlatePosition(targetRef.offsetLeft)
        }
      }
    }
    if (!animated)
      setTimeout(() => {
        setAnimated(true)
      }, 100)
  }, [segments, value, elementRefs])

  // const elementRefs = segments.map(() => useRef<HTMLDivElement | null>(null))

  return (
    <div
      className={`${css['segmented-input']} ${animated && css['animated']} ${
        dark && css['dark']
      }`}
      style={style}
    >
      <div className={css['segments']} ref={segmentsRef}>
        {segments.length &&
          segments.map((opt, index) => {
            const currentRef = elementRefs[index]

            return (
              <div
                ref={currentRef}
                key={opt.value}
                onClick={(e) => handleSegmentClick(e, opt.value)}
                className={`${value === opt.value ? css['active'] : ''} ${
                  largeText && css['large-text']
                }
                ${opt.label ? css['label'] : css['no-label']}`}
              >
                {opt.icon}
                {opt.label}
              </div>
            )
          })}
      </div>
      {value && (
        <div
          className={css['plate']}
          style={{
            left: platePosition,
            width: `calc(100% / ${segments.length})`,
          }}
        />
      )}
    </div>
  )
}

export default SegmentedInput
