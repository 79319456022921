import { DateTime } from 'luxon'
import css from './DateSubheader.module.css'
import { EyeOff } from 'react-feather'
import { Tooltip } from 'react-tooltip'
import useCycles from '../../../hooks/cycles/useCycles'
import { IconCycle, IconSquare } from '../Icons/Icons'

export const DateSubheader = ({
  fullDate,
  activeDate,
  hideMessage,
}: {
  fullDate: string
  activeDate: string
  hideMessage?: JSX.Element | null
}) => {
  const { checkDateForCycle } = useCycles()

  const currentCycle = checkDateForCycle(activeDate)

  return (
    <div style={{ display: 'flex', gap: '4px', alignItems: 'baseline' }}>
      {fullDate}
      {DateTime.now().toFormat('yyyy-LL-dd') === activeDate && (
        <span className={css.today}> It's today</span>
      )}
      {hideMessage && (
        <div data-tooltip-id="t-hidden-day">
          <EyeOff
            size={16}
            color={'var(--icon)'}
            style={{ position: 'relative', top: '2px', marginLeft: '4px' }}
          />

          <Tooltip
            id="t-hidden-day"
            place="bottom"
            delayShow={100}
            className="tooltip"
            noArrow={true}
            offset={4}
            style={{ margin: '-4px 0 0 0' }}
            children={
              <div style={{ lineHeight: '20px', padding: '4px 0' }}>
                This day is hidden from athletes.
                <br />
                It will be revealed {hideMessage}
              </div>
            }
          />
        </div>
      )}
      {currentCycle && (
        <div
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            background: `var(--color-cycle-${currentCycle.color})`,
            padding: '0 8px 0 6px',
            borderRadius: '4px',
            margin: '0px 0 0 8px',
          }}
        >
          <IconCycle size={12} color="rgba(0,0,0,0.65)" />
          <span
            style={{
              fontSize: '12px',
              color: 'rgba(0,0,0,0.85)',
            }}
          >
            {currentCycle.title}
          </span>
        </div>
      )}
    </div>
  )
}
