import React, { useContext } from 'react'
import Avatar from '../Avatar/Avatar'
import css from './UserListItem.module.css'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import { UserContext } from '../../../context/UserContext'
import ButtonIcon from '../../buttons/ButtonIcon/ButtonIcon'
import { RotateCcw, UserX } from 'react-feather'
import { GymMemberData } from '../../../types/types'
import { AppContext } from '../../../context/AppContext'
import { Time } from '../TimeAgo/TimeAgo'
import Badge from '../Badge/Badge'

type Props = {
  data: GymMemberData
  onUserAction?: (action: string, data: GymMemberData) => void
  index?: number
}

const UserListItem = ({ data, onUserAction, index }: Props) => {
  const { userID } = useContext(UserContext)
  const { currentSpace } = useContext(AppContext)
  const isMe = userID === data.uid
  const isOwner = currentSpace?.ownerID === data.uid

  const canEditPermissions =
    currentSpace?.ownerID === userID || currentSpace?.permissions.canEditMembers

  const canEditMembers =
    currentSpace?.ownerID === userID ||
    currentSpace?.permissions.canEditWorkouts

  return (
    <li className={css['li']}>
      <div className={css['left']}>
        {index && <div className={css['index']}>{index}</div>}
        <Avatar
          name={data.name}
          photoURL={data.photoURL}
          size={56}
          className={css['avatar']}
        />
        <div className={css['text-info']}>
          <div className={css['name']}>
            <div className={css['text']}>{data.name}</div>{' '}
            {data.role !== 'athlete' && (
              <Badge style={{ float: 'left' }}>{data.role}</Badge>
            )}
            {isMe && (
              <Badge style={{ marginRight: '-2px', float: 'left' }}>you</Badge>
            )}{' '}
          </div>
          <div className={css['secondary']}>
            {data.lastActivityRecord ? (
              <>
                Last active{' '}
                <Time seconds={data.lastActivityRecord.createdDate.seconds} />
              </>
            ) : (
              'No activity yet'
            )}
          </div>
        </div>
      </div>
      {!isMe &&
        !isOwner &&
        (data.hasAccess ? (
          <div style={{ display: 'flex', gap: '8px' }}>
            {canEditPermissions && (
              <ButtonToolbar
                type="light"
                className={css['button']}
                onClick={() => onUserAction && onUserAction('edit', data)}
              >
                Role & Permissions
              </ButtonToolbar>
            )}
            {canEditMembers && (
              <ButtonIcon
                type="light"
                className={css['button']}
                border={true}
                icon={<UserX size={16} />}
                destructive={true}
                onClick={() => onUserAction && onUserAction('remove', data)}
              />
            )}
          </div>
        ) : (
          canEditMembers && (
            <ButtonToolbar
              type="light"
              className={css['button']}
              icon={<RotateCcw size={16} />}
              onClick={() => onUserAction && onUserAction('restore', data)}
            >
              Restore Member
            </ButtonToolbar>
          )
        ))}
    </li>
  )
}

export default UserListItem
