import axios from 'axios'
import API from '.'
import { auth } from '../firebase'
import { Analytics } from './analytics'

export const createGym = async (name: string) => {
  const authToken = await auth.currentUser?.getIdToken()

  try {
    await axios.post(API.url + `createGym`, {
      name,
      authToken,
    })
    Analytics._logEvent({ name: 'gym_created', params: {} })
  } catch (err: any) {
    console.error(err.response.data)
  }
}
