import { useContext, useEffect, useRef, useState } from 'react'
import { Cycle } from '../../types/cycles'
import { useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import { UserContext } from '../../context/UserContext'
import { AppContext } from '../../context/AppContext'
import { v4 as uuid } from 'uuid'
import useCyclePublisher from './useCyclePublisher'

interface Props {
  cycleToEdit: Cycle | null
  onSuccess: () => void
}

const useCycleBuilder = ({ cycleToEdit, onSuccess }: Props) => {
  const timestamp = {
    seconds: DateTime.now().toUnixInteger(),
  }

  const { userID } = useContext(UserContext)
  const { currentSpace } = useContext(AppContext)
  const { publishCycle } = useCyclePublisher()

  const { current: defaultValues } = useRef(
    cycleToEdit
      ? { ...cycleToEdit, updatedBy: userID, updatedDate: timestamp }
      : {
          ...getNewCycle(),
          createdBy: userID,
          updatedBy: userID,
        }
  )

  const formMethods = useForm<Cycle>({
    defaultValues: defaultValues,
    mode: 'onChange',
  })

  const isDirty = formMethods.formState.isDirty
  const isValid = formMethods.formState.isValid

  const onSubmit = (data: Cycle) => {
    if (!currentSpace) return

    publishCycle(data)
    onSuccess()
  }

  return {
    publishCycle: formMethods.handleSubmit(onSubmit),
    formMethods,
    isDirty,
    isValid,
  }
}

export default useCycleBuilder

const getNewCycle = (): Cycle => {
  const newCycle: Cycle = {
    id: 'cycle_' + uuid().substring(0, 8),
    calendarColor: 'green',
    title: '',
    dateRange: {
      startDate: {
        seconds: DateTime.now().startOf('day').toUnixInteger(),
        nanoseconds: 0,
      },
      endDate: {
        seconds: DateTime.now()
          .startOf('day')
          .plus({ month: 1 })
          .toUnixInteger(),
        nanoseconds: 0,
      },
    },
    createdDate: {
      seconds: DateTime.now().toUnixInteger(),
      nanoseconds: 0,
    },
    updatedDate: {
      seconds: DateTime.now().toUnixInteger(),
      nanoseconds: 0,
    },
    createdBy: '',
    updatedBy: '',
    description: '',
    isDeleted: false,
  }

  return newCycle
}
