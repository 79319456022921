import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Logo from '../../../components/atoms/Logo/Logo'
import { AppContext } from '../../../context/AppContext'
import css from './PrintCode.module.css'
import QRCode from 'react-qr-code'
import AccessCode from '../../../components/modals/AccessCode'

type Props = {}

const PrintCode = (props: Props) => {
  const { code } = useParams()
  const { currentSpace } = useContext(AppContext)
  const [logoLoaded, setLogoLoaded] = useState(false)

  useEffect(() => {
    if (logoLoaded) window.print()
  }, [logoLoaded])

  return (
    <div className={css['bg']}>
      <div className={css['container']}>
        <h1>Join {currentSpace && currentSpace.title} at</h1>
        <div className={css['logo']}>
          <img
            src={'/img/logo-full.svg'}
            alt="Rewod"
            onLoad={() => setLogoLoaded(true)}
          />
        </div>
        <div className={css['text']}>
          Become a part of our community and start tracking your progress
          with&nbsp;us
        </div>
        <div className={`${css['text']} ${css['ins']}`}>Get the Rewod App</div>
        <QRCode
          value={`https://app.rewod.io/get/${
            currentSpace && currentSpace.accessCode
          }`}
        />{' '}
        <div className={css['link']}>app.rewod.io/get</div>
        <div className={`${css['text']} ${css['ins']}`}>Join with the code</div>
        <div className={css['code']}>{code?.substring(0, 6)}</div>
      </div>
    </div>
  )
}

export default PrintCode
