import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import { UserContext } from '../../context/UserContext'
import Button from '../buttons/Button/Button'
import useGym from '../../hooks/useGym'
import useMe from '../../hooks/useMe'
import SegmentedInput from '../inputs/SegmentedInput/SegmentedInput'
import ToggleInput from '../inputs/ToggleInput/ToggleInput'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'
import { EditableUserAttributes } from '../../services'

type Props = {
  isVisible: boolean
  onDismiss: () => void
}

const EditPassword = ({ isVisible, onDismiss }: Props) => {
  const { register, reset, handleSubmit, setFocus } =
    useForm<EditableUserAttributes>()

  const { error, isLoading, clearErrors, updateUserPassword } = useMe({
    setFocus: setFocus,
    onSuccess: () => {
      reset()
      onDismiss()
    },
  })

  useEffect(() => {
    setFocus('password')
  }, [isVisible])

  const save: SubmitHandler<EditableUserAttributes> = (data) =>
    updateUserPassword(data)

  return (
    <Modal
      isVisible={isVisible}
      onCancel={() => {
        clearErrors()
        reset()
        onDismiss()
      }}
      size="small"
      title="Change Password"
      buttons={{
        secondary: {
          onClick: () => {
            reset()
            clearErrors()
            onDismiss()
          },
        },
        primary: {
          onClick: handleSubmit(save),
          label: 'save',
          isSubmitButton: true,
          disabled: error ? true : false,
          isLoading: isLoading,
        },
      }}
    >
      <form onSubmit={handleSubmit(save)} style={{ paddingBottom: '12px' }}>
        <label>Current Password</label>
        <input
          autoComplete="current-password"
          id="current-password"
          maxLength={24}
          disabled={!isVisible}
          type="password"
          style={{
            borderColor: error ? 'var(--accent-red)' : '',
          }}
          {...register('password', {
            maxLength: 64,
            onChange: () => clearErrors(),
          })}
        />
        <label>New Password</label>
        <input
          autoComplete="new-password"
          id="new-password"
          maxLength={24}
          disabled={!isVisible}
          type="password"
          style={{
            borderColor: error ? 'var(--accent-red)' : '',
          }}
          {...register('newpassword', {
            maxLength: 64,
            onChange: () => clearErrors(),
          })}
        />
        {error && (
          <p style={{ marginTop: '8px', color: 'var(--accent-red)' }}>
            {error}
          </p>
        )}
      </form>
    </Modal>
  )
}

export default EditPassword
