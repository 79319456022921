import { useFirestoreQueryData } from '@react-query-firebase/firestore'
import {
  collection,
  doc,
  limit,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { useContext, useEffect, useRef, useState } from 'react'
import { firestore } from '../firebase'
import { GymMemberData, UserData } from '../types/types'
import { useUsersData } from './useUsersData'
import { Analytics } from '../services/analytics'
import { AppContext } from '../context/AppContext'

type Props = {
  currentSpace: string | null
  onUpdateSuccess: () => void
  onUpdateFailure: () => void
}

const usePeopleManagement = ({
  currentSpace,
  onUpdateSuccess,
  onUpdateFailure,
}: Props) => {
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [showRemovedMembers, setShowRemovedMembers] = useState(false)
  const { currentSpace: currSpace } = useContext(AppContext)

  const ref = currentSpace
    ? query(
        collection(firestore, 'spaces', currentSpace, 'acl'),
        where('role', '!=', 'support')
      )
    : undefined

  const peopleRaw = useFirestoreQueryData(
    ['acl', currentSpace],
    ref,
    {
      //subscribe: true, // or undefined
    },
    {
      enabled: !!currentSpace,

      onSuccess(snapshot: any) {},
      onError(error: any) {
        console.error('failed to load members')
      },
    }
  )

  const userIDs = peopleRaw.data
    ? peopleRaw.data
        .filter((p: GymMemberData) => p.uid)
        .map((p: GymMemberData) => p.uid)
    : []

  // getting user names and avatars
  const { usersData, usersDataIsLoading } = useUsersData({
    userIDs,
  })

  const members =
    peopleRaw.data && usersData
      ? peopleRaw.data
          .map((p: GymMemberData) => {
            return (
              p !== undefined &&
              usersData[p.uid] &&
              usersData[p.uid].name && {
                ...p,
                name: usersData[p.uid].name,
                photoURL: usersData[p.uid].photoURL,
                joinDate: p.joinDate
                  ? typeof p.joinDate === 'number'
                    ? p.joinDate
                    : p.joinDate.seconds * 1000
                  : null,
              }
            )
          })
          .filter((i: UserData) => {
            return i !== undefined && i.name !== undefined
          })
          .sort((a: UserData, b: UserData) => a.name.localeCompare(b.name))
      : []

  const updateMemberData = async (newData: GymMemberData) => {
    if (!currentSpace) return
    const ref = doc(firestore, 'spaces', currentSpace, 'acl', newData.uid)

    setUpdateInProgress(true)

    const isStaff =
      newData.permissions?.canEditWorkouts ||
      newData.permissions?.canEditPermissions ||
      newData.permissions?.canRemoveComments ||
      newData.permissions?.canEditMembers ||
      newData.role === 'coach' ||
      newData.role === 'manager'

    try {
      await updateDoc(ref, {
        'permissions.canEditWorkouts': newData.permissions?.canEditWorkouts,
        'permissions.canEditMembers': newData.permissions?.canEditMembers,
        'permissions.canRemoveComments': newData.permissions?.canRemoveComments,
        'permissions.canEditPermissions':
          newData.permissions?.canEditPermissions,
        role: newData.role,
        isStaff: isStaff,
      })
      setUpdateInProgress(false)
      peopleRaw.refetch()
      Analytics._logEvent({
        name: 'gym_member_permissions_changed',
        params: { details: `member id: ${newData.uid}` },
      })
      onUpdateSuccess()
    } catch (err) {
      console.log('Error updating member data: ', err)
      setUpdateInProgress(false)
      onUpdateFailure()
    }
  }

  const restoreMember = async (newData: GymMemberData) => {
    if (!currentSpace) return
    const ref = doc(firestore, 'spaces', currentSpace, 'acl', newData.uid)

    setUpdateInProgress(true)

    const isStaff =
      newData.permissions?.canEditWorkouts ||
      newData.permissions?.canRemoveComments ||
      newData.permissions?.canEditPermissions ||
      newData.permissions?.canEditMembers ||
      newData.role === 'coach' ||
      newData.role === 'manager' ||
      newData.role === 'owner'

    try {
      await updateDoc(ref, {
        'permissions.canEditWorkouts': newData.permissions?.canEditWorkouts,
        'permissions.canRemoveComments':
          newData.permissions?.canRemoveComments || false,
        'permissions.canEditMembers': newData.permissions?.canEditMembers,
        'permissions.canEditPermissions':
          newData.permissions?.canEditPermissions,
        role: newData.role,
        hasAccess: true,
        isStaff: isStaff,
      })
      setUpdateInProgress(false)
      peopleRaw.refetch()
      Analytics._logEvent({
        name: 'gym_member_restored',
        params: { details: `member id: ${newData.uid}` },
      })
      onUpdateSuccess()
    } catch (err) {
      console.error('Error updating member data: ', err)
      setUpdateInProgress(false)
      onUpdateFailure()
    }
  }

  const changeMemberAccess = async (params: {
    data: GymMemberData
    hasAccess: boolean
  }) => {
    if (!currentSpace) return
    const ref = doc(firestore, 'spaces', currentSpace, 'acl', params.data.uid)
    setUpdateInProgress(true)
    try {
      await updateDoc(ref, {
        'permissions.canEditWorkouts': false,
        'permissions.canEditMembers': false,
        'permissions.canEditPermissions': false,
        'permissions.canRemoveComments': false,
        role: 'athlete',
        hasAccess: false,
        isStaff: false,
      })
      setUpdateInProgress(false)
      peopleRaw.refetch()
      Analytics._logEvent({
        name: 'gym_member_blocked',
        params: { details: `member id: ${params.data.uid}` },
      })
      onUpdateSuccess()
    } catch (err) {
      console.error('Error updating member data: ', err)
      setUpdateInProgress(false)
      onUpdateFailure()
    }
  }

  return {
    peopleAreLoading:
      !members.length && (peopleRaw.isLoading || usersDataIsLoading),
    members: members,
    updateInProgress: updateInProgress,
    showRemovedMembers: showRemovedMembers,
    updateMemberData: updateMemberData,
    changeMemberAccess: changeMemberAccess,
    loadRemovedMembers: () => setShowRemovedMembers(true),
    restoreMember: restoreMember,
  }
}
export default usePeopleManagement

// Hook
function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current
}
