import React, { CSSProperties, useEffect } from 'react'

import { DatePicker as AntdDatePicker, ConfigProvider } from 'antd'
import en_GB from 'antd/lib/locale/en_GB'
import { Controller, UseFormReturn } from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'
import { ArrowRight, Calendar } from 'react-feather'
import updateLocale from 'dayjs/plugin/updateLocale'
type Props = {
  formMethods: UseFormReturn<any>
  label: string
  name: string
  style?: CSSProperties
}

const DateRangeInput = ({ label, name, formMethods, style }: Props) => {
  const { RangePicker } = AntdDatePicker

  const getDayJSvalue = (date: {
    startDate: { seconds: number; nanoseconds: number }
    endDate: { seconds: number; nanoseconds: number }
  }) => {
    const startDayJS = dayjs.unix(date.startDate.seconds) || null
    const endDayJS = dayjs.unix(date.endDate.seconds) || null

    const result: [
      start: Dayjs | null | undefined,
      end: Dayjs | null | undefined
    ] = [startDayJS, endDayJS]

    return result
  }

  useEffect(() => {
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
      weekStart: 1,
    })
  }, [])
  return (
    <div style={style}>
      <label>{label}</label>
      <Controller
        control={formMethods.control}
        name={name}
        render={({ field: { ref, value, onChange, ...fieldProps } }) => (
          <ConfigProvider locale={en_GB}>
            <RangePicker
              format="MMM D, YYYY"
              ref={ref}
              value={getDayJSvalue(value)}
              className="range-picker"
              id={{
                start: 'startInput',
                end: 'endInput',
              }}
              showNow={true}
              allowClear={false}
              showWeek={true}
              separator={<ArrowRight size={16} color={'var(--icon)'} />}
              suffixIcon={<Calendar size={16} color={'var(--icon)'} />}
              onChange={(dates) => {
                console.log('\n\n\n\ndates', dates?.[0]?.unix())

                // This component uses DayJS
                const startDate = dates?.[0]?.unix() || null
                const endDate = dates?.[1]?.unix() || null

                onChange({
                  startDate: {
                    seconds: startDate,
                    nanoseconds: 0,
                  },
                  endDate: {
                    seconds: endDate,
                    nanoseconds: 0,
                  },
                })
              }}
            />
          </ConfigProvider>
        )}
      />
    </div>
  )
}

export default DateRangeInput
