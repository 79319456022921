import { useContext } from 'react'
import { UserContext } from '../context/UserContext'
import { RewodLegacyEvent } from '../types/legacyEvents'
import { Result } from '../types/results'
import pluralize from 'pluralize'
import { translateRoundsRestTime } from '../helpers_ts'

const useHelper = () => {
  const {
    user: { measureUnits },
    movements,
  } = useContext(UserContext)

  /**
   *  Converts a weight in KGs into user's units of measurement.
   *  If null, returns as zero
   */
  const w = (weightInKG: number | null) => {
    if (!weightInKG) return 0
    if (measureUnits === 'kg') return weightInKG
    else return Math.floor(weightInKG * 2.20462)
  }

  /**
   * Used in workout cards. Translates repetition and time cap values
   * from workout data into a standartized instruction. For example:
   * "In 5 minutes, do as many reps as possible of:"
   */
  const translateRepetitionInstruction = ({
    tracking,
    timeCap,
    roundRestTime,
    rounds,
    repetitionPattern,
  }: RewodLegacyEvent.Item): string | React.ReactNode => {
    /**
     * Do 5 times for as many rounds & reps as possible of
     * As many reps as possible of
     * Do 5 rounds for as many reps as possible of
     * In 10 minutes, do 5 rounds for as many reps as possible of
     * In 10 minutes, do as many reps as possible of
     */

    const expertMode = true

    const repetitionPatternText = translateRepetitionPattern(
      repetitionPattern,
      false
    )
    const repetitionPatternTextRoundsMode = translateRepetitionPattern(
      repetitionPattern,
      true
    )

    const value = (() => {
      switch (tracking) {
        case 'time': {
          if (typeof rounds === 'number' && repetitionPatternTextRoundsMode)
            return `${repetitionPatternTextRoundsMode} for time`
          else return `For time`
        }
        case 'weight-used': {
          if (typeof rounds === 'number' && repetitionPatternTextRoundsMode)
            return `${repetitionPatternTextRoundsMode} for load`
          else return `For load`
        }
        case 'reps-weight': {
          return `${repetitionPatternText}`
        }
        case 'rounds': {
          return expertMode
            ? timeCap
              ? `${timeCap} min AMRAP (for rounds)`
              : `AMRAP (for rounds)`
            : timeCap
            ? `In ${timeCap} min, do as many rounds\nas possible`
            : `As many rounds as possible`
        }
        case 'rounds-reps': {
          return expertMode
            ? timeCap
              ? `${timeCap} min AMRAP (for rounds + reps)`
              : `AMRAP (rounds+reps)`
            : timeCap
            ? `In ${timeCap} min, do as many\nrounds & reps as possible`
            : `Do as many rounds & reps as possible of:`
        }

        case 'total-reps': {
          if (expertMode)
            if (rounds && rounds > 1) {
              return translateRoundsRestTime(roundRestTime)
                ? `AMRAP, ${rounds} rounds (${translateRoundsRestTime(
                    roundRestTime
                  )})`
                : `AMRAP, ${rounds} rounds`
            } else {
              return timeCap
                ? `${timeCap} min AMRAP (for reps)`
                : `AMRAP (for reps)`
            }
          else if (rounds && rounds > 1) {
            return translateRoundsRestTime(roundRestTime)
              ? `Do ${rounds} rounds (${translateRoundsRestTime(
                  roundRestTime
                )})\nfor as many reps as possible`
              : `Do ${rounds} rounds for as many\nreps as possible`
          } else {
            return timeCap
              ? `In ${timeCap} min, do as many reps\nas possible`
              : `Do as many reps as possible`
          }
        }
        case 'text':
          return '(custom score via text entry)'
        case 'no-tracking':
          return ''
      }
      return ''
    })()
    return value
  }

  /**
   * Used to translate repetition pattern into human-readable text
   * @param repetitionPattern value used in workout instructions
   * @param roundsMode used when displaying certain workout types, i.e. "21-15-9 reps for time"
   * @returns human-readable text
   */
  const translateRepetitionPattern = (
    repetitionPattern: RewodLegacyEvent.Item['repetitionPattern'],
    roundsMode: boolean
  ): string => {
    if (!repetitionPattern) return ''
    const { reps, dashReps } = repetitionPattern

    const setLabel = roundsMode ? 'round' : 'set'

    if (!reps.length) return ''

    if (roundsMode && reps.length === 1)
      // if only one round, don't show it
      return parseInt(reps[0]) === 1 ? '' : `${reps[0]} ${setLabel}s`

    if (!dashReps)
      return `${reps.length} ${pluralize(setLabel, reps.length)}, ${
        reps[0]
      } ${pluralize('rep', parseInt(reps[0]))}`
    else {
      return roundsMode
        ? `${reps.join('–')} reps`
        : `${reps.length} ${pluralize(setLabel, reps.length)}, ${reps.join(
            '–'
          )} reps`
    }
  }

  return { w, translateRepetitionInstruction, translateRepetitionPattern }
}

export default useHelper
