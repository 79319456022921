import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import Modal from './Modal'
import { DatePicker, DateValidationError } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { WorkoutShortcut } from '../../types/workouts'
import toast from 'react-hot-toast'
import { useWorkoutPublisher } from '../../hooks/useWorkoutPublisher'
import { Analytics } from '../../services/analytics'

type Props = {
  isVisible: boolean
  isLoading?: boolean
  onCancel: () => void
  onConfirm: () => void
  workoutShortcut: WorkoutShortcut | null
}

const AddWorkoutToCalendar = ({
  isVisible,
  onCancel,
  onConfirm,
  workoutShortcut,
}: Props) => {
  const { currentSpace } = useContext(AppContext)

  const {
    register,
    reset,
    handleSubmit,
    setFocus,
    watch,
    getFieldState,
    control,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: { date: dayjs(DateTime.now().toISO()) }, // MUI datepicker requires a dayjs object
    shouldUnregister: true,
  })

  const [data, setData] = useState(workoutShortcut)
  const [activeDate, setActiveDate] = useState(
    DateTime.now().toFormat('YYYY-MM-DD')
  )

  useEffect(() => {
    if (workoutShortcut) setData(workoutShortcut)
  }, [workoutShortcut])

  const [submitAfterDateChange, setSubmitAfterDateChange] = useState(false)

  const save = async (data: any) => {
    const selectedDate = dayjs(data.date)?.format('YYYY-MM-DD')
    setActiveDate(selectedDate)
    setSubmitAfterDateChange(true)
    onConfirm()
  }

  const submit = async () => {
    setSubmitAfterDateChange(false)
    Analytics._logEvent({
      name: 'add_benchmark_workout',
      params: {
        via: 'playbook',
      },
    })
    await addPredefinedWorkout({
      workoutShortcut: data,
      eventDateISO: activeDate,
      index: 0,
    })
    //await addWorkoutByShortcut(data)
    toast.success('Workout added to calendar')
  }

  useEffect(() => {
    if (submitAfterDateChange) {
      submit()
    }
  }, [activeDate])

  const { addPredefinedWorkout } = useWorkoutPublisher()

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setFocus('date')
  }, [isVisible])

  const [pickerOpen, setPickerOpen] = useState(false)

  const handleDatePickerError = (reason: DateValidationError) => {
    switch (reason) {
      case 'invalidDate':
        setError('Invalid date')
        break
      case 'minDate':
        setError('Date is too far in the past')
        break
      case 'maxDate':
        setError('Date is too far in the future')
        break

      case null:
        setError(null)
    }
  }

  return (
    <Modal
      isVisible={isVisible}
      onCancel={() => {
        reset()
        onCancel()
      }}
      size="small"
      secondaryTitle={data?.title ? 'Add Workout to Calendar' : ''}
      title={data?.title ? data.title : 'Add Workout to Calendar'}
      buttons={{
        secondary: {
          onClick: () => {
            setError(null)
            reset()
            onCancel()
          },
        },
        primary: {
          onClick: handleSubmit(save),
          label: 'Add',
          isSubmitButton: true,
          disabled: error ? true : false,
        },
      }}
    >
      <label>Select Date</label>

      <Controller
        name="date"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <DatePicker
            className={error ? 'error' : ''}
            open={pickerOpen}
            onClose={() => setPickerOpen(false)}
            minDate={dayjs(DateTime.now().minus({ month: 6 }).toISODate())}
            maxDate={dayjs(DateTime.now().plus({ year: 1 }).toISODate())}
            onError={handleDatePickerError}
            slotProps={{
              actionBar: {
                actions: ['today'],
              },
              textField: {
                className: 'date-picker',
                autoComplete: 'off',
                type: 'text',
                onClick: () => setPickerOpen(true),
              },
            }}
            sx={{ width: '100%', marginBottom: '8px' }}
            {...fieldProps}
          />
        )}
      />

      {error && (
        <p
          style={{
            color: 'var(--accent-red)',
            marginTop: '-6px',
            font: 'var(--small-text)',
          }}
        >
          {error}
        </p>
      )}
    </Modal>
  )
}

export default AddWorkoutToCalendar
