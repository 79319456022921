import { DateTime } from 'luxon'

export const capitalize = (string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join('-')
    .split('-To-')
    .join('-to-')
    .split('-In-')
    .join('-in-')
    .split(' In ')
    .join(' in ')
    .split(' To ')
    .join(' to ')
}

export const abbreviateMovementName = (string) => {
  return string
    .replace('Kettlebell', 'KB')
    .replace('Dumbbell', 'DB')
    .replace('Toes-to-Bar', 'T2B')
    .replace('Toes-through-Ring', 'TTR')
    .replace('Handstand Push-Up', 'HSPU')
    .replace('Chest-to-Bar', 'C2B')
    .replace('Overhead Squat', 'OHS')
    .replace('Single-Leg Romanian Deadlift', 'SLRDL')
    .replace('Romanian Deadlift', 'RDL')
}

export const deAbbreviateMovementName = (string) => {
  return string
    .toLowerCase()
    .replace('bb ', 'Barbell')
    .replace('kb ', 'Kettlebell')
    .replace('db ', 'Dumbbell ') //db needs a space after cause it may be "Dball"
    .replace('t2b', 'Toes-to-Bar')
    .replace('ttb', 'Toes-to-Bar')
    .replace('ttr', 'Toes through Ring')
    .replace('t2r', 'Toes through Ring')
    .replace('hspu', 'Handstand Push-Up')
    .replace('c2b', 'Chest-to-Bar')
    .replace('ctb', 'Chest-to-Bar')
    .replace('ohs', 'Overhead Squat')
    .replace('slrdl', 'Single Leg Romanian Deadlift')
    .replace('rdl', 'Romanian Deadlift')
}

export const standartizeMovementEntry = (string) => {
  return capitalize(deAbbreviateMovementName(string))
    .replace('Pullups', 'Pull-Up')
    .replace('Pull Ups', 'Pull-Up')
    .replace('Pushups', 'Push-Up')
    .replace('Push Ups', 'Push-Up')
    .replace('Dball', 'D-Ball')
}

export const isToday = (someDate) => {
  const today = DateTime.now().toFormat('yyyy-LL-dd')

  return someDate === today
}

export const translateAuthErrors = (err, from) => {
  if (from === 'reset' && err.includes('user-not-found')) return 'Donno, man...'

  if (err.includes('user-not-found') || err.includes('wrong-password'))
    return 'Incorrect e-mail or password'

  if (err.includes('invalid-email')) return 'Please enter a valid e-mail'
  if (err.includes('email-already-in-use'))
    return 'This e-mail is already in use'

  if (err.includes('no-email-and-password-first'))
    return 'Please enter your e-mail and create a password'

  if (err.includes('no-email-and-password'))
    return 'Please enter your e-mail and the password'

  if (err.includes('no-email')) return 'Please enter your e-mail'

  if (err.includes('no-password-first')) return 'Please create a password'

  if (err.includes('no-password')) return 'Please enter the password'

  if (err.includes('no-name')) return 'Please enter your name'
  if (err.includes('network-request-failed'))
    return "There's a problem with the network connection"

  if (err.includes('popup-closed-by-user')) return '' // google sign-in closed

  // If all else fails
  return err.replace('Firebase: ', '')
}

export const formatReps = (reps, tvMode) => {
  return tvMode
    ? reps
    : !isNaN(reps)
    ? parseInt(reps) === 1
      ? parseInt(reps) + ' rep'
      : parseInt(reps) + ' reps'
    : reps
}

/** Filters out old and deleted records */
export const getLatestRecordsForAnEvent = (results) => {
  // Leaving only results with the latest createdTime for each of userIDs+eventItemIDs
  // Powered by ChatGPT :)

  return results
    ? results.filter((obj1) => {
        return (
          !results.some(
            (obj2) =>
              obj2.eventItemID === obj1.eventItemID &&
              obj2.userID === obj1.userID &&
              obj2.createdTime > obj1.createdTime
          ) && !obj1.isDeleted
        )
      })
    : null
}

export const randNumberWithSoManyDigits = (digits) => {
  const min = Math.pow(10, digits - 1)
  const max = Math.pow(10, digits) - 1
  return Math.floor(Math.random() * (max - min + 1)) + min
}
