import React, { Children, useEffect, useMemo } from 'react'
import css from './Day.module.css'
import { IconCycle } from '../../../atoms/Icons/Icons'
import { v4 as uuid } from 'uuid'
import { PlusCircle as IconPlus } from 'react-feather'
import useCycles from '../../../../hooks/cycles/useCycles'

import styles from './Day.module.css'
import classNames from 'classnames/bind'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import WorkoutItem from '../WorkoutItem/WorkoutItem'
import { DateTime } from 'luxon'
import useCalendarEvents from '../../../../hooks/calendar/useCalendarEvents'

const cx = classNames.bind(styles)

interface Props {
  month: DateTime
  /** Day title to display in the header ("Jan 1" or "2") */
  number: string
  date: string
  isLoading: boolean
  isActive: boolean
  isCurrentMonth: boolean
  isToday: boolean
  sundayIsFirst: boolean
  onClick: (date: string) => void
  isScrolling?: boolean
}

const Day = React.memo(
  ({
    month,
    number,
    date,
    isActive,
    isToday,
    sundayIsFirst,
    isCurrentMonth,
    isScrolling,
    onClick,
  }: Props) => {
    const {
      data: events,
      isLoading,
      isFetched,
    } = useCalendarEvents({
      dateISO: date,
      enabled: !isScrolling,
    })

    const { checkDateForCycle } = useCycles()

    const cycleData = checkDateForCycle(date)

    const { setNodeRef, over } = useDroppable({
      id: date,
    })

    const isOver =
      over &&
      (over.id === date ||
        events.map((item) => item.id).includes(over.id as string))

    return (
      <SortableContext
        items={events?.map((item) => item.id) ?? []}
        strategy={verticalListSortingStrategy}
      >
        <div
          className={cx(
            'day',
            isActive && 'active',
            sundayIsFirst && 's',
            (isLoading || (isScrolling && !isFetched)) && 'loading',
            cycleData && 'cycle',
            cycleData && cycleData.isStart && 'cycle-start',
            cycleData && cycleData.isEnd && 'cycle-end',
            cycleData && cycleData.color && 'cycle-' + cycleData.color,
            isOver && 'drag-hover'
          )}
          style={{
            overflow: 'hidden',
          }}
          onClick={() => {
            onClick(date)
          }}
        >
          <div className={css['num-container']}>
            {cycleData?.isStart ? (
              <div className={css['cycle-title']}>
                <IconCycle color="var(--icon)" size={11} /> {cycleData.title}
              </div>
            ) : (
              <div></div>
            )}
            <div
              className={`${css['daynum']} ${isToday && css['today']} ${
                !isCurrentMonth && css['non-current']
              }`}
            >
              {number}
            </div>
          </div>

          <div className={cx('content')} ref={setNodeRef}>
            {events.length ? (
              events.map((event, index) => (
                <div key={event.id}>
                  <WorkoutItem item={event} />
                </div>
              ))
            ) : (
              <div className={css['icon-add']}>
                <IconPlus size={24} />
              </div>
            )}
          </div>

          <div className={css['wrap-gradient']} />
        </div>
      </SortableContext>
    )
  }
)

export default Day
