import React, { useState, ReactNode } from 'react'
import css from './Sidebar.module.css'
import { X as IconClose } from 'react-feather'

import ButtonIcon from '../../buttons/ButtonIcon/ButtonIcon'

type Props = {
  isActive: boolean
  onDismiss: () => void
  builderMode?: boolean
  header?: string
  subHeader?: string
  children: ReactNode
  /** @blankTemplate children will override header and content area */
  blankTemplate?: boolean
}

const Sidebar = ({
  isActive,
  onDismiss,
  header,
  subHeader,
  children,
  blankTemplate = false,
}: Props) => {
  const [scrollShadowIsVisible, setScrollShadowIsVisible] = useState(false)

  const handleBuilderScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = (e.target as HTMLElement).scrollTop
    if (!scrollShadowIsVisible && scrollTop > 10) setScrollShadowIsVisible(true)
    else if (scrollShadowIsVisible && scrollTop <= 10)
      setScrollShadowIsVisible(false)
  }

  return (
    <div id={css['sidebar']} className={`${isActive && css['active']}`}>
      {!blankTemplate ? (
        <>
          <header>
            <div className={`${css['subheader']} ${'light'}`}>
              {subHeader || ''}
            </div>

            {header && <h2>{header}</h2>}
            <ButtonIcon
              icon={<IconClose />}
              className={css['close-icon']}
              onClick={onDismiss}
              type={'light'}
              style={{ margin: '-4px 0 0 0' }}
            />
            <div
              className={`${css['scroll-shadow']} ${
                scrollShadowIsVisible && css['visible']
              }`}
            />
          </header>

          <div id={css['content']} onScroll={handleBuilderScroll}>
            {children}
          </div>
        </>
      ) : (
        children
      )}
    </div>
  )
}

export default Sidebar
