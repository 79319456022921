import axios from 'axios'
import { auth } from '../firebase'
import {
  GymData,
  GymSubscription,
  GymSubscriptionInstance,
} from '../types/types'
import API from '.'

export const getSubscription = async (gym: GymData | null) => {
  if (!gym) return

  const authToken = await auth.currentUser?.getIdToken()

  return axios
    .post<GymSubscriptionInstance>(API.url + `getSubscription`, {
      gymID: gym.id,
      authToken,
    })
    .then((res) => res.data)
}
