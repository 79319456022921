import ReactTimeAgo from 'react-time-ago'

import TimeAgo, { Style } from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'

type Props = {
  seconds: number
  displayStyle?: 'short' | 'long'
}

export const Time = (props: Props) => {
  TimeAgo.addLocale(en)

  const day = 1000 * 60 * 60 * 24

  const todayMilliseconds = Math.floor(Date.now())
  const inputMilliseconds = props.seconds * 1000

  const threshold = day * 14 // 14 days

  const timeStyle =
    todayMilliseconds - inputMilliseconds >= threshold
      ? 'twitter-minute-now'
      : 'round-minute-now'

  // If a user was active less than 14 days ago, we'll show "Active 2 weeks ago"
  // If more, we'll show "Oct 25"

  return (
    <>
      <ReactTimeAgo date={inputMilliseconds} timeStyle={timeStyle} />
    </>
  )
}
