import React, { useContext, useEffect, useState } from 'react'
import Modal from '../Modal'
import { AppContext } from '../../../context/AppContext'
import { IconBarbell } from '../../atoms/Icons/Icons'
import { Analytics } from '../../../services/analytics'
import QRCode from 'react-qr-code'
import css from './InviteMembers.module.css'

type Props = {
  isVisible: boolean
  onDismiss: () => void
}

const InviteMembers = ({ isVisible, onDismiss }: Props) => {
  //const [isLoading, setLoading] = useState(false)

  const { currentSpace } = useContext(AppContext)

  const code = currentSpace?.accessCode

  const onPrint = () => {
    Analytics._logEvent({
      name: 'gym_invite_code_print_requested',
      params: {
        details: `gymID: ${currentSpace?.id}, code: ${currentSpace?.accessCode}`,
      },
    })
    window.open('/printCode/' + currentSpace?.accessCode, '_blank')
  }

  const actions = {
    secondary: {
      onClick: onPrint,
      isLoading: false,
      disabled: false,
      label: 'Print Code',
    },
    primary: {
      label: 'Done',
      onClick: onDismiss,
      disabled: false,
      isLoading: false,
      isSubmitButton: true,
    },
  }

  return (
    <Modal
      isVisible={isVisible}
      onCancel={onDismiss}
      size="small"
      buttons={actions}
      blurBackdrop={true}
    >
      <div style={{ display: 'flex', flexDirection: 'column', color: 'black' }}>
        <div style={{ textAlign: 'center' }}>
          <IconBarbell color="var(--accent)" style={{ marginBottom: '16px' }} />

          <h1 style={{ marginTop: '8px' }}>
            Join {currentSpace && currentSpace.title}
          </h1>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div className={`${css['text']} ${css['ins']}`}>
            Get the Rewod App
          </div>
          <div className={css['qr']}>
            <QRCode
              value={`https://app.rewod.io/get/${
                currentSpace && currentSpace.accessCode
              }`}
            />
          </div>
          <div className={css['link']}>app.rewod.io/get</div>

          <div className={`${css['text']} ${css['ins']}`}>
            Join with the code
          </div>
          <div className={css['code']}>{code?.substring(0, 6)}</div>
        </div>
      </div>
    </Modal>
  )
}

export default InviteMembers
