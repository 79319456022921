import React, { ReactNode, useState } from 'react'
import ButtonIcon from '../buttons/ButtonIcon/ButtonIcon'
import css from './Modal.module.css'
import { X as IconX } from 'react-feather'
import Button, { ButtonType } from '../buttons/Button/Button'

export type ModalBasicProps = {
  children: ReactNode
  size: 'small'
  isVisible: boolean
  onCancel: () => void
  onConfirm?: () => void
  canDismiss?: boolean
  blurBackdrop?: boolean
  title?: string
  secondaryTitle?: string
  buttons?: {
    secondary: {
      label?: string
      onClick: () => void
      disabled?: boolean
      isLoading?: boolean
    }
    primary: {
      label: string
      type?: ButtonType
      isLoading?: boolean
      disabled?: boolean
      onClick: () => void
      isSubmitButton?: boolean
    }
  }
}

const Modal = ({
  children,
  size,
  isVisible,
  buttons,
  title,
  secondaryTitle,
  onCancel,
  canDismiss = true,
  blurBackdrop,
}: ModalBasicProps) => {
  return (
    <div
      className={`${css['backdrop']} ${isVisible && css['visible']} ${
        blurBackdrop && css['blurred']
      }`}
      onClick={(e) => {
        // backdrop click closes the modal
        if (e.target === e.currentTarget && canDismiss) onCancel()
      }}
    >
      <div className={`${css['modal-content']} ${css[size]}`}>
        {canDismiss && (
          <ButtonIcon
            className={css['close-button']}
            type="light"
            onClick={onCancel}
            icon={<IconX />}
          />
        )}
        {title && (
          <div className={css['header']}>
            {secondaryTitle && (
              <h5 style={{ marginBottom: '12px', textTransform: 'none' }}>
                {secondaryTitle}
              </h5>
            )}
            <h3>{title}</h3>
          </div>
        )}
        {children}
        {buttons && (
          <div className={css['buttons']}>
            <Button
              disabled={buttons.secondary.disabled}
              isLoading={buttons.secondary.isLoading}
              onClick={buttons.secondary.onClick}
              type={'secondary'}
            >
              {buttons.secondary.label ? buttons.secondary.label : 'Dismiss'}
            </Button>
            <Button
              onClick={buttons.primary.onClick}
              isLoading={buttons.primary.isLoading}
              disabled={buttons.primary.disabled}
              type={buttons.primary.type && buttons.primary.type}
              isSubmitButton={buttons.primary.isSubmitButton}
            >
              {buttons.primary.label && buttons.primary.label}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
export default Modal
