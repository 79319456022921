import React from 'react'
import { Workout } from '../../../types/workouts'
import css from './WorkoutCard.module.css'
import { translateWorkoutType } from '../../../helpers_ts'

type Props = {
  type: Workout.Item['workoutType']
}

export const WorkoutType = ({ type }: Props) => {
  return (
    <div className={css['workout-type']}>
      <h5>{translateWorkoutType(type)}</h5>
    </div>
  )
}
