import React, { ReactNode } from 'react'
import css from './ButtonIcon.module.css'

type Props = {
  icon: ReactNode
  style?: React.CSSProperties
  onClick: React.MouseEventHandler<HTMLDivElement>
  className?: string
  type?: 'light' | 'dark'
  border?: boolean
  destructive?: boolean
}

const ButtonIcon = ({
  style,
  onClick,
  icon,
  border,
  className,
  destructive,
  type,
}: Props) => {
  return (
    <div
      style={style}
      onClick={onClick}
      className={`${css['button-icon']} ${className} ${type && css[type]} ${
        border && css['border']
      } ${destructive && css['destructive']}`}
    >
      {icon}
    </div>
  )
}

export default ButtonIcon
