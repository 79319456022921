import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { GymMemberData } from '../../types/types'
import Button from '../buttons/Button/Button'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'

type Props = {
  isVisible: boolean
  isLoading?: boolean
  onCancel: () => void
  onConfirm: (data: GymMemberData) => void
  data: GymMemberData
}

const RemoveMember = ({
  isVisible,
  isLoading,
  onCancel,
  onConfirm,
  data,
}: Props) => {
  const [currentData, setCurrentData] = useState<GymMemberData>(data)

  const css = {
    header: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      width: '80%',
    } as React.CSSProperties,
  }

  const submitData = () => {
    onConfirm(currentData)
  }

  return (
    <Modal
      isVisible={isVisible}
      onCancel={onCancel}
      size="small"
      buttons={{
        secondary: { onClick: onCancel },
        primary: {
          onClick: submitData,
          isLoading: isLoading,
          label: 'Remove',
          type: 'destructive',
        },
      }}
    >
      {data && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '24px',
          }}
        >
          <div style={css.header}>
            <h3>Block Member?</h3>
          </div>
          <div
            style={{
              marginTop: '24px',
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Avatar
              photoURL={data.photoURL}
              name={data.name}
              size={56}
              style={{ flexShrink: '0' }}
            />
            <div>
              <strong>{data.name}</strong>
              <div
                style={{
                  color: 'var(--secondary-text)',
                  font: 'var(--small-text)',
                  margin: '0 0',
                }}
              >
                {/* {data.joinDate &&
                  'Joined ' +
                    DateTime.fromMillis(data.joinDate).toFormat('MMM d, yyyy')} */}
              </div>
            </div>
          </div>

          <p style={{ marginTop: '24px' }}>
            Blocking this member will remove their access to your gym in Rewod
            and hide their comments. You can restore members anytime.
          </p>
        </div>
      )}
    </Modal>
  )
}

export default RemoveMember
