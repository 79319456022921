import { DateTime } from 'luxon'
import React from 'react'
import { AlertOctagon, Check, X } from 'react-feather'
import {
  GymSubscriptionInstance,
  GymSubscriptionStatus,
} from '../../../types/types'
import { manageSubscription } from '../../../services/manageSubscription'

type Props = {
  status: GymSubscriptionStatus
  /** if needed for additional context, i.e. when billing starts after a trial */
  date?: number | null
  cancelInTheEnd?: boolean
  sub: GymSubscriptionInstance
}

const SubscriptionStatus = ({ status, date, cancelInTheEnd, sub }: Props) => {
  const formattedDate =
    date && DateTime.fromSeconds(date).toFormat('LLL d, yyyy')

  const getSubIcon = () => {
    switch (status) {
      case 'active':
      case 'trialing':
        return (
          <Check
            size={16}
            strokeWidth={4}
            color="var(--accent)"
            style={{
              position: 'relative',
              top: '2px',
              marginRight: '4px',
            }}
          />
        )

      case 'canceled':
      case 'past_due':
      case 'incomplete_expired':
      case 'unpaid':
      case 'error':
        return (
          <X
            size={16}
            strokeWidth={4}
            color="var(--accent-red)"
            style={{
              position: 'relative',
              top: '2px',
              marginRight: '4px',
            }}
          />
        )
      case 'incomplete':
        return (
          <AlertOctagon
            size={16}
            strokeWidth={3}
            color="var(--accent-yellow)"
            style={{
              position: 'relative',
              top: '2px',
              marginRight: '4px',
            }}
          />
        )
    }
  }

  switch (status) {
    case 'active':
      return (
        <div>
          <span>
            {getSubIcon()}
            <strong>Active</strong>
          </span>
          <div style={{ font: 'var(--small-text)', marginTop: '-2px' }}>
            {cancelInTheEnd ? (
              <span style={{ color: 'var(--accent-red)' }}>
                Subscription ends on {formattedDate}
              </span>
            ) : (
              `Renews on ${formattedDate}`
            )}
          </div>
        </div>
      )
    case 'trialing':
      return (
        <div>
          <span>
            {getSubIcon()}
            <strong>Trialing</strong>
          </span>
          <div style={{ font: 'var(--small-text)', marginTop: '-2px' }}>
            {cancelInTheEnd ? (
              <span style={{ color: 'var(--accent-red)' }}>
                Subscription ends on {formattedDate}
              </span>
            ) : (
              `Billing starts on ${formattedDate}`
            )}
          </div>
        </div>
      )
    case 'canceled':
      return (
        <div>
          <span>
            {getSubIcon()}
            <strong>Canceled</strong>
          </span>
          <div style={{ font: 'var(--small-text)', marginTop: '-2px' }}>
            On {formattedDate}
          </div>
        </div>
      )

    case 'does-not-exist':
      return (
        <div>
          <span>
            <strong>No Subscription</strong>
          </span>
        </div>
      )
    case 'paused':
      return (
        <div>
          <span>
            <strong>Paused</strong>
          </span>
        </div>
      )
    case 'incomplete':
    case 'past_due':
      return (
        <div>
          <span>
            {getSubIcon()}
            <strong>Pending Payment</strong>
          </span>
          <div style={{ font: 'var(--small-text)', marginTop: '-2px' }}>
            We couldn't process your payment.
            <br />
            Please{' '}
            <span className="link" onClick={() => manageSubscription(sub)}>
              check your payment details
            </span>{' '}
            to retain access.
          </div>
        </div>
      )
    case 'unpaid':
    case 'incomplete_expired':
      return (
        <div>
          <span>
            {getSubIcon()}
            <strong>On Hold</strong>
          </span>
          <div style={{ font: 'var(--small-text)', marginTop: '-2px' }}>
            Sorry, your subscription was put on hold after we couldn't receive
            your payment.
            <br />
            Please{' '}
            <span className="link" onClick={() => manageSubscription(sub)}>
              check your payment details
            </span>{' '}
            to restore access.
          </div>
        </div>
      )

    case 'error':
      return (
        <div>
          <span>
            {getSubIcon()}
            <strong>Error</strong>
            <div style={{ font: 'var(--small-text)', marginTop: '-2px' }}>
              Please <a href="mailto:support@rewod.io">contact support</a>
            </div>
          </span>
        </div>
      )
  }
}

export default SubscriptionStatus
