import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { GymMemberData } from '../../types/types'
import Button from '../buttons/Button/Button'
import ButtonToolbar from '../buttons/ButtonToolbar/ButtonToolbar'
import SegmentedInput from '../inputs/SegmentedInput/SegmentedInput'
import ToggleInput from '../inputs/ToggleInput/ToggleInput'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'

type Props = {
  isVisible: boolean
  isLoading?: boolean
  onCancel: () => void
  onConfirm: (data: GymMemberData) => void
  data: GymMemberData
}

type RoleChange = {
  what: 'role'
  role: GymMemberData['role']
}

type PermissionChange = {
  what:
    | 'permission-edit-workouts'
    | 'permission-edit-permissions'
    | 'permission-edit-members'
    | 'permission-remove-comments'
  allowed: boolean
}

const MemberPermissions = ({
  isVisible,
  isLoading,
  onCancel,
  onConfirm,
  data,
}: Props) => {
  const [currentData, setCurrentData] = useState<GymMemberData>(data)

  const css = {
    header: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      width: '80%',
    } as React.CSSProperties,
  }

  const handleChange = (changes: RoleChange | PermissionChange) => {
    let newData = { ...currentData }

    switch (changes.what) {
      case 'role': {
        newData['role'] = changes.role as GymMemberData['role']

        switch (changes.role) {
          case 'athlete': {
            newData.permissions = {
              canEditWorkouts: false,
              canRemoveComments: false,
              canEditMembers: false,
              canEditPermissions: false,
            }
            break
          }
          case 'coach': {
            newData.permissions = {
              canEditWorkouts: true,
              canRemoveComments: true,
              canEditMembers: false,
              canEditPermissions: false,
            }
            break
          }
          case 'manager': {
            newData.permissions = {
              canEditWorkouts: true,
              canRemoveComments: true,
              canEditPermissions: true,
              canEditMembers: true,
            }
            break
          }
        }

        setCurrentData(newData)

        break
      }
      case 'permission-edit-workouts':
        newData.permissions.canEditWorkouts = changes.allowed
        break
      case 'permission-edit-permissions':
        newData.permissions.canEditPermissions = changes.allowed
        if (!changes.allowed)
          newData.permissions.canEditMembers = changes.allowed
        break
      case 'permission-edit-members':
        newData.permissions.canEditMembers = changes.allowed
        if (changes.allowed)
          newData.permissions.canEditPermissions = changes.allowed
        break
      case 'permission-remove-comments':
        newData.permissions.canRemoveComments = changes.allowed
        if (changes.allowed)
          newData.permissions.canRemoveComments = changes.allowed
        break
      default:
        break
    }
    setCurrentData(newData)
  }

  const submitData = () => {
    onConfirm(currentData)
  }

  return (
    <Modal
      isVisible={isVisible}
      onCancel={onCancel}
      size="small"
      buttons={{
        secondary: { onClick: onCancel },
        primary: { onClick: submitData, isLoading: isLoading, label: 'Save' },
      }}
    >
      {data && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={css.header}>
            <Avatar
              photoURL={data.photoURL}
              name={data.name}
              size={84}
              style={{ flexShrink: '0' }}
            />
            <div>
              <h3>{data.name}</h3>
              <div
                style={{
                  color: 'var(--secondary-text)',
                  font: 'var(--small-text)',
                  margin: '4px 0 -8px',
                }}
              >
                {/* {data.joinDate &&
                  'Joined ' +
                    DateTime.fromSeconds(data.joinDate.).toFormat('MMM d, yyyy')} */}
              </div>
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <label style={{ marginBottom: '8px' }}>Role</label>
            <SegmentedInput
              largeText={true}
              segments={[
                { label: 'Athlete', value: 'athlete' },
                { label: 'Coach', value: 'coach' },
                { label: 'Manager', value: 'manager' },
              ]}
              value={currentData && currentData.role}
              onChange={(value) =>
                handleChange({
                  what: 'role',
                  role: value as GymMemberData['role'],
                })
              }
            />
            <div
              style={{
                font: 'var(--small-text)',
                marginTop: '8px',
                width: '85%',
              }}
            >
              Coaches and managers have access to the full gym calendar, and the
              list of members in the web version.
            </div>
            <div>
              <label style={{ marginBottom: '16px' }}>
                Advanced Permissions
              </label>
              <ToggleInput
                isActive={currentData?.permissions?.canEditWorkouts}
                label={'Add, edit or delete workouts'}
                style={{ marginBottom: '16px' }}
                onToggle={() =>
                  handleChange({
                    what: 'permission-edit-workouts',
                    allowed: currentData.permissions
                      ? currentData.permissions.canEditWorkouts
                        ? false
                        : true
                      : true,
                  })
                }
              />
              <ToggleInput
                isActive={currentData?.permissions?.canRemoveComments}
                label={"Remove other member's comments"}
                style={{ marginBottom: '16px' }}
                onToggle={() =>
                  handleChange({
                    what: 'permission-remove-comments',
                    allowed: currentData.permissions
                      ? currentData.permissions.canRemoveComments
                        ? false
                        : true
                      : true,
                  })
                }
              />
              <ToggleInput
                isActive={currentData?.permissions?.canEditPermissions}
                label={"Edit members' roles & permissions"}
                style={{ marginBottom: '16px' }}
                onToggle={() =>
                  handleChange({
                    what: 'permission-edit-permissions',
                    allowed: currentData.permissions
                      ? currentData.permissions.canEditPermissions
                        ? false
                        : true
                      : true,
                  })
                }
              />
              <ToggleInput
                isActive={currentData?.permissions?.canEditMembers}
                label={'Remove or restore members'}
                style={{ marginBottom: '16px' }}
                onToggle={() =>
                  handleChange({
                    what: 'permission-edit-members',
                    allowed: currentData.permissions
                      ? currentData.permissions.canEditMembers
                        ? false
                        : true
                      : true,
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default MemberPermissions
