import { getUserData } from '../../../firebase'
import css from './Avatar.module.css'
import { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { firestore } from '../../../firebase'

interface AvatarParams {
  userID?: string | null
  size: number | null
  photoURL: string | null
  name: string | null
  className?: string | null
  style?: React.CSSProperties | null
}

const Avatar = ({
  userID,
  size,
  photoURL,
  name,
  className,
  style,
}: AvatarParams) => {
  const [localName, setName] = useState<string | null>(name)

  // console.log('avatar received:', localPhotoURL)

  // const getName = async () => {
  //   const userQuery = doc(firestore, 'users', userID)
  //   let newName
  //   try {
  //     const data = await getDoc(userQuery)
  //     setName(data.data().name)
  //     setPhotoURL(data.data().photoURL)
  //   } catch (e) {}
  // }

  useEffect(() => {
    if (userID) setName('NONAME')
  }, [])

  const initials = localName
    ? localName.trim()[0].toUpperCase() !== 'Z'
      ? localName.trim()[0]
      : '/'
    : ''

  const relativeFontSize = size && size * 0.6 < 60 ? size * 0.6 : 60

  return photoURL ? (
    <div
      className={`${css['avatar']} ${className}`}
      style={{
        width: size + 'px',
        backgroundImage: `url(${photoURL}`,
        backgroundSize: 'cover',
        ...style,
      }}
    />
  ) : (
    <div
      className={`${css['avatar']} ${className}`}
      style={{
        width: size + 'px',
        fontSize: relativeFontSize + 'px',
        ...style,
      }}
    >
      {initials}
    </div>
  )
}

export default Avatar
