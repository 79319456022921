import css from './WorkoutCard.module.css'
import { IconWorkoutType } from '../../atoms/Icons/Icons'
import { formatReps } from '../../../helpers'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import {
  AlignLeft,
  EyeOff,
  Edit2 as IconEdit,
  Plus,
  Repeat,
  Send,
  Trash,
  Trash2,
} from 'react-feather'
import { AlignJustify as IconDrag } from 'react-feather'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { CSSProperties, useContext, useState } from 'react'
import { Scoreboard } from '../../../types/scoreboard'
import { Result } from '../../../types/results'
import { Tracking } from '../../../types/types'
import useHelper from '../../../hooks/useHelper'
import Button from '../../buttons/Button/Button'
import useWorkoutBuilder from '../../../hooks/useWorkoutBuilder'
import pluralize from 'pluralize'
import { AppContext } from '../../../context/AppContext'
import { UserContext } from '../../../context/UserContext'
import { Workout } from '../../../types/workouts'
import { WorkoutType } from './WorkoutType'
import { AdditionalNoteInput } from '../../inputs/AdditionalNoteInput/AdditionalNoteInput'
import AddWorkoutToCalendar from '../../modals/AddWorkoutToCalendar'
import { ScalingSelector } from './ScalingSelector'
import { rewodPluralize } from '../../../utilities/rewodPluralize'
import { useWorkoutSet } from '../../../hooks/useWorkoutSet'
import { Analytics } from '../../../services/analytics'
import { config } from '../../../config'
import { RewodLegacyEvent } from '../../../types/legacyEvents'
import { CalendarEvent } from '../../../types/calendarEvents'
import useResultsForEvent from '../../../hooks/results/useResultsForEvent'

type Props = {
  workoutData: Workout.Item
  eventData?: CalendarEvent.Workout
  canSort: boolean
  isDragOverlay?: boolean
  style?: CSSProperties
  className?: string
  id: string
  tvMode?: {
    enableNotes: boolean
    baseSize: string
  }
  playbookMode?: boolean
  onAction: (
    action: 'edit' | 'delete' | 'publish' | 'add-to-calendar',
    actionItems?: { setItems: Workout.Item[] | null }
  ) => void
  animateOnAppear?: boolean
}

const WorkoutCard = ({
  workoutData,
  canSort,
  isDragOverlay,
  className,
  style,
  tvMode,
  playbookMode,
  eventData,
  onAction,
  animateOnAppear,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: eventData?.id ?? 'no-id' })

  const { translateRepetitionInstruction } = useHelper()
  const { setItems, activeWorkout, onScalingChange } = useWorkoutSet(
    workoutData,
    workoutData.set?.parentWorkoutID || workoutData.id
  )

  const styleAddition = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition,
  }

  let repetitionPosted = 0

  const repetitionText = translateRepetitionInstruction(activeWorkout)
  const notesOnlyMode = !activeWorkout.superset.some(
    (item) => item.itemType === 'exercise'
  )

  const getItem = (
    item: Workout.Superset.Item,
    type: Workout.Item['workoutType'],
    isPublished: boolean,
    index: number
  ) => {
    switch (item.itemType) {
      case 'exercise': {
        repetitionPosted++
        return (
          <React.Fragment key={item.id}>
            {repetitionPosted === 1 &&
              repetitionText &&
              !activeWorkout.hideAutomaticDescription && (
                <div key="rep" className={`${css['repetition']}`}>
                  {repetitionText}
                </div>
              )}
            <div className={`${css['exercise']} ${css['item']}`}>
              <div className={css['icon']}>
                <IconWorkoutType type={type} size={12} outline={!isPublished} />
              </div>
              <div className={css['text']}>
                <div>
                  <span className={css['move-name']}>
                    {activeWorkout.isSuperset
                      ? rewodPluralize(item.movement.name)
                      : item.movement.name}
                  </span>
                  <span className={css['reps']}>
                    {' '}
                    {item.reps && item.effort && activeWorkout.isSuperset ? (
                      <>
                        {formatReps(item.reps, tvMode)}
                        <span className={css['times-symbol']}>
                          &#x2009;&times;&#x2009;
                        </span>
                        {getEffortValue(setItems, item, tvMode, index)}
                      </> // &#x2009 is a thin space. we need it so that the string would wrap when needed
                    ) : item.reps && activeWorkout.isSuperset ? (
                      formatReps(item.reps, tvMode)
                    ) : (
                      getEffortValue(setItems, item, tvMode, index)
                    )}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }

      case 'note': {
        if (notesOnlyMode) repetitionPosted++
        return !tvMode || (tvMode && tvMode.enableNotes) ? (
          <React.Fragment key={item.id}>
            {repetitionPosted === 1 && repetitionText && notesOnlyMode && (
              <div key="rep" className={`${css['repetition']}`}>
                {repetitionText}
              </div>
            )}
            <div key={item.id} className={`${css['note']} ${css['item']}`}>
              {item.note}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment key={item.id}></React.Fragment>
        )
      }
    }
  }

  const { results } = useResultsForEvent({ eventID: eventData?.id || 'no-id' })
  const resultsCount = results?.length || 0

  const baseSizeCSS: React.CSSProperties = {
    '--base-size': tvMode ? tvMode.baseSize : ('' as string), // Cast as string
  } as React.CSSProperties // Cast the whole object

  const { currentSpace } = useContext(AppContext)
  const { userID } = useContext(UserContext)
  const userIsEditor =
    currentSpace?.permissions.canEditWorkouts ||
    currentSpace?.ownerID === userID

  const [additionalNoteMode, setAdditionalNoteMode] = useState(false)

  const isAddNewWorkoutEnabled =
    config.access.playbook_add_new_workout?.includes(userID)

  const ActionButtons = () => {
    if (tvMode) return null

    const RightSideButtons = () => {
      if (!workoutData.isPredefined) {
        // Generic workout
        return (
          <>
            <ButtonToolbar
              icon={<IconEdit size={12} style={{ marginRight: '8px' }} />}
              type="light"
              onClick={() => onAction('edit', { setItems })}
              style={{
                color: 'var(--accent)',
              }}
            >
              Edit Workout
            </ButtonToolbar>

            {!eventData?.isPublished && (
              <ButtonToolbar
                icon={<Send size={12} style={{ marginRight: '8px' }} />}
                type="light"
                onClick={() => onAction('publish')}
                style={{
                  color: 'var(--accent)',
                }}
              >
                Publish
              </ButtonToolbar>
            )}
          </>
        )
      } else {
        // Predefined/benchmark workouts
        if (!playbookMode) {
          return (
            <>
              <ButtonToolbar
                icon={<Repeat size={12} style={{ marginRight: '8px' }} />}
                type="light"
                onClick={() => {
                  Analytics._logEvent({
                    name: 'workout_benchmark_variation_requested',
                    params: {
                      workoutID: workoutData.id,
                    },
                  })
                  onAction('edit')
                }}
                style={{
                  color: 'var(--accent)',
                }}
              >
                Variation
              </ButtonToolbar>
              <ButtonToolbar
                icon={<AlignLeft size={12} style={{ marginRight: '8px' }} />}
                type="light"
                onClick={() => setAdditionalNoteMode(true)}
                style={{
                  color: 'var(--accent)',
                }}
              >
                {workoutData.additionalNote ? 'Edit Note' : 'Note'}
              </ButtonToolbar>{' '}
            </>
          )
        } else {
          // in Playbook view only action is to add to calendar
          return (
            <>
              <ButtonToolbar
                type="light"
                onClick={() => onAction && onAction('add-to-calendar')}
                className={css['da-button']}
                icon={<Plus size={16} color="var(--accent)" />}
              >
                Add to Calendar
              </ButtonToolbar>
              {isAddNewWorkoutEnabled && (
                <ButtonToolbar
                  icon={<IconEdit size={12} style={{ marginRight: '8px' }} />}
                  type="light"
                  onClick={() => onAction('edit', { setItems })}
                  style={{
                    color: 'var(--accent)',
                  }}
                >
                  Edit
                </ButtonToolbar>
              )}
            </>
          )
        }
      }
    }

    const LeftSideButtons = () => {
      return (
        <div className={css['section']}>
          {!playbookMode && (
            <ButtonToolbar
              type="light"
              onClick={() => onAction('delete')}
              className={css['da-button']}
              icon={<Trash2 size={16} color="var(--accent)" />}
            ></ButtonToolbar>
          )}
        </div>
      )
    }

    return !additionalNoteMode ? (
      userIsEditor ? (
        <div className={css['editor-buttons']}>
          <div className={css['group']}>
            <div className={css['section']}>
              <RightSideButtons />
            </div>
            <div className={css['section']}>
              <LeftSideButtons />
            </div>
          </div>
        </div>
      ) : null
    ) : (
      <AdditionalNoteInput
        onDismiss={() => setAdditionalNoteMode(false)}
        workoutData={activeWorkout}
        eventData={eventData}
      />
    )
  }

  return (
    <div
      className={`
        ${css['workout-card']} 
        ${isDragOverlay ? css['drag-overlay'] : ''} 
        ${isDragging ? css['hide'] : ''} 
        ${className ? css[className] : ''} 
        ${notesOnlyMode ? css['notes-only'] : ''}
        ${tvMode ? css['tv'] : ''}
        ${workoutData.isPredefined ? css['predefined'] : ''}
        ${workoutData.workoutType ? css[workoutData.workoutType] : ''}
        ${animateOnAppear ? css['animate-on-appear'] : ''}
      `}
      {...attributes}
      ref={setNodeRef}
      style={{
        ...baseSizeCSS,
        ...styleAddition,
      }}
    >
      <div className={css['content']}>
        {!tvMode && (
          <>
            <div className={css['header']}>
              <WorkoutType type={activeWorkout.workoutType} />

              <div className={css['no-tracking']}>
                <div style={{ float: 'left', margin: '-8px 0 0 0' }}>
                  {!playbookMode ? (
                    eventData && !eventData.isPublished ? (
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <EyeOff
                          size={16}
                          color={'var(--icon)'}
                          style={{ marginTop: '8px' }}
                        />
                        <h5 style={{ marginTop: '8px' }}>Not Published</h5>
                      </div>
                    ) : activeWorkout.tracking === 'no-tracking' ? (
                      <h5 style={{ marginTop: '8px' }}>No tracking</h5>
                    ) : resultsCount ? (
                      <h5
                        style={{
                          marginTop: '8px',
                          color: 'var(--primary-text)',
                        }}
                      >
                        {resultsCount} {pluralize('Result', resultsCount)}
                      </h5>
                    ) : (
                      <h5 style={{ marginTop: '8px' }}>No results yet</h5>
                    )
                  ) : (
                    ''
                  )}
                </div>
                {canSort && userIsEditor && (
                  <div
                    className={css['drag-icon']}
                    data-tooltip-id="t-drag-hint-card"
                    data-tooltip-content="Drag to reorder"
                    {...listeners}
                  >
                    <IconDrag size={20} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {activeWorkout.title && !tvMode && (
          <div className={`${css['title-container']}`}>
            <div className={css['title']}>{activeWorkout.title}</div>
          </div>
        )}
        {setItems.length > 1 && !tvMode && (
          <ScalingSelector
            setItems={setItems}
            workoutData={workoutData}
            onScalingOptionChange={onScalingChange}
            scalingOption={activeWorkout}
            style={{ margin: '16px 24px 16px 24px' }}
          />
        )}
        <div className={css['moves']}>
          {activeWorkout.superset.map((item, index) =>
            getItem(
              item,
              activeWorkout.workoutType,
              eventData?.isPublished ?? true,
              index
            )
          )}
        </div>
        {!!activeWorkout.additionalNote && !additionalNoteMode && (
          <div className={`${css['note']} ${css['additional']}`}>
            {activeWorkout.additionalNote}
          </div>
        )}
        {activeWorkout.timeCap && !tvMode ? (
          ['time', 'reps-weight', 'no-tracking', 'text'].includes(
            activeWorkout.tracking
          ) ? (
            <div className={css['time-cap']}>
              {activeWorkout.timeCap} min cap
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <ActionButtons />
      </div>
    </div>
  )
}

export default WorkoutCard

const getEffortValue = (
  setItems: Workout.Item[],
  supersetItem: Workout.Superset.Exercise,
  tvMode: any,
  supersetItemIndex: number
) => {
  /**
   * If we're in the TV mode and there's a scaled version of workout,
   * i.e. for women and men, we need to combine values we display on TV
   */

  if (tvMode && setItems.length > 1) {
    const womenScalingOption = setItems.find(
      (workoutItem) =>
        workoutItem.set?.scaling.genderGroup === 'women' &&
        workoutItem.set?.scaling.ageGroup === 'masters' &&
        workoutItem.set?.scaling.effort === 'rx'
    )

    console.log(setItems[0].title)
    console.log('setItems', setItems)
    console.log('womenScalingOption', womenScalingOption)

    const menScalingOption = setItems.find(
      (workoutItem) =>
        workoutItem.set?.scaling.genderGroup === 'men' &&
        workoutItem.set?.scaling.ageGroup === 'masters' &&
        workoutItem.set?.scaling.effort === 'rx'
    )

    // Finding matching set items
    const womenEffort = (
      womenScalingOption?.superset.find(
        (item, index, array) =>
          item.itemType === 'exercise' &&
          item.movement.id === supersetItem.movement.id &&
          array.indexOf(item) === supersetItemIndex
      ) as Workout.Superset.Exercise
    )?.effort

    const menEffort = (
      menScalingOption?.superset.find(
        (item, index, array) =>
          item.itemType === 'exercise' &&
          item.movement.id === supersetItem.movement.id &&
          array.indexOf(item) === supersetItemIndex
      ) as Workout.Superset.Exercise
    )?.effort

    if (womenEffort === menEffort) return supersetItem.effort
    else if (womenEffort && menEffort) {
      return combineEffortValues(womenEffort, menEffort)
    }

    return supersetItem.effort
  }
  return supersetItem.effort
}

const combineEffortValues = (strA: string, strB: string) => {
  // Variation 1 Pattern: Matches two parts of the format "X lbs to Y ft"
  const pattern3 = /(\d+)\s*lbs\s*to\s*(\d+)\s*ft/
  const matchA3 = strA.match(pattern3)
  const matchB3 = strB.match(pattern3)

  if (matchA3 && matchB3) {
    return `${matchA3[1]}/${matchB3[1]} lbs to ${matchA3[2]}/${matchB3[2]} ft`
  }

  // Variation 2 and 3 Pattern: Matches numbers followed by a unit (e.g., "100 lbs", "20\"")
  const pattern1 = /(\d+)\s*([a-zA-Z"]+)/
  const matchA1 = strA.match(pattern1)
  const matchB1 = strB.match(pattern1)

  if (matchA1 && matchB1 && matchA1[2] === matchB1[2]) {
    return `${matchA1[1]}/${matchB1[1]} ${matchA1[2]}`
  }

  // Return original strings if no pattern is matched
  return `${strA}/${strB}`
}
