import { BarLoader, ScaleLoader, SquareLoader } from 'react-spinners'

const Loading = () => {
  return (
    <>
      <div
        id="content"
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#222',
        }}
      >
        {/* <PricingPage /> */}
        <div
          style={{
            width: '180px',
            height: '50px',
            ///backgroundColor: '#111',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maskImage: "url('./img/logo-full.svg')",
            maskRepeat: 'no-repeat',
            maskSize: '100% auto',
            WebkitMaskImage: "url('./img/logo-full.svg')",
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskSize: '100%',
            background: 'red',
          }}
        >
          <BarLoader
            color="var(--accent)"
            width="100%"
            height="100%"
            cssOverride={{ backgroundColor: '#1E7C49' }}
          />
        </div>
      </div>
    </>
  )
}

export default Loading
