import {
  SetStateAction,
  createContext,
  useReducer,
  useRef,
  useState,
} from 'react'
import { DateTime, MonthNumbers } from 'luxon'
import e from 'express'
import Confirm from '../components/modals/Confirm'
import { Analytics } from '../services/analytics'

type MonthReducerActions = 'prev' | 'next' | 'today' | 'prev-snap' | 'next-snap'
export type CalendarActions = 'show-cycles' | MonthReducerActions

export interface Calendar {
  showPrevMonth: () => void
  showNextMonth: () => void
  goToToday: () => void
  setLoading: React.Dispatch<SetStateAction<boolean>>
  setScrolling: React.Dispatch<SetStateAction<boolean>>
  setHighlightDrafts: React.Dispatch<SetStateAction<boolean>>
  setActiveDate: (day: string | null) => void
  setSelectedMonth: (month: DateTime) => void
  setMonthsReducer: React.Dispatch<MonthReducerActions | null>
  setPendingActiveDate: (day: string | null) => void
  proceedToPendingDate: () => void
  setCalendarRef: React.Dispatch<SetStateAction<HTMLDivElement | null>>
  setPreventActiveDateChange: React.Dispatch<SetStateAction<boolean>>
  dispatchAction: (action: CalendarActions | null) => void
  dispatchMonthAction: (action: MonthReducerActions) => void
  action: CalendarActions | null
  isScrolling: boolean
  activeDate: string | null
  pendingActiveDate: string | null
  preventActiveDateChange: boolean
  highlightDrafts: boolean
  calendarRef: HTMLDivElement | null
  snapToMonth: DateTime
  monthsReducer: {
    months: DateTime[]
    latestAction: MonthReducerActions | null
  }
  calendar: {
    today: {
      date: DateTime
      monthName: string
      monthNumber: MonthNumbers
      year: number
    }
    selected: {
      date: DateTime
      monthName: string
      monthNumber: MonthNumbers
      year: number
    }
    /** First visible date */
    fromDate: string
    /** Last visible date */
    toDate: string
    previousMonthDates: string[]
    currentMonthDates: string[]
    nextMonthDates: string[]
  }
  isLoading: boolean
}

// defaults
const getDefaultValues = (selectedDate: DateTime): Calendar => {
  const defaultDate = DateTime.now()
  const snapToMonth = defaultDate

  const activeDate = null
  const pendingActiveDate = null
  const preventActiveDateChange = false
  const highlightDrafts = false
  const calendarRef = null

  const sundayShift = 0
  const action = null

  let previousMonth = []
  let currentMonth = []
  let nextMonth = []

  const firstDayOfTheMonth = DateTime.fromObject({
    year: selectedDate.year,
    month: selectedDate.month,
  }).weekday

  const daysTotalInPreviousMonth =
    selectedDate.month > 1
      ? DateTime.fromObject({
          year: selectedDate.year,
          month: selectedDate.month - 1,
        }).endOf('month').day
      : 31 // December

  const daysTotal = DateTime.fromObject({
    year: selectedDate.year,
    month: selectedDate.month,
  }).endOf('month').day

  //Previous month
  for (
    let i = daysTotalInPreviousMonth;
    i > daysTotalInPreviousMonth - (firstDayOfTheMonth - sundayShift) + 1;
    i--
  ) {
    const pushDate = selectedDate
      .set({ day: i, month: selectedDate.month - 1 })
      .toFormat('yyyy-LL-dd')

    previousMonth.unshift(pushDate)
  }

  //Current month
  for (let i = 1; i <= daysTotal; i++) {
    const pushDate = selectedDate.set({ day: i }).toFormat('yyyy-LL-dd')
    currentMonth.push(pushDate)
  }

  const totalDaysToShow =
    (daysTotal === 31 && firstDayOfTheMonth >= 6) ||
    (daysTotal === 30 && firstDayOfTheMonth === 7)
      ? 42
      : 35

  const daysLeft =
    totalDaysToShow - (previousMonth.length + currentMonth.length) //

  // Next month
  for (let i = 1; i <= daysLeft; i++) {
    const pushDate = selectedDate
      .plus({ months: 1 })
      .set({ day: i })
      .toFormat('yyyy-LL-dd')
    nextMonth.push(pushDate)
  }

  const selected = {
    date: selectedDate,
    monthName: selectedDate.toFormat('LLLL'),
    monthNumber: selectedDate.month,
    year: selectedDate.year,
  }

  const today = {
    date: defaultDate,
    monthName: defaultDate.toFormat('LLLL'),
    monthNumber: defaultDate.month,
    year: defaultDate.year,
  }

  const startMonth = DateTime.now().minus({ months: 3 })
  const months = Array.from({ length: 7 }, (_, i) =>
    startMonth.plus({ months: i })
  )

  return {
    showPrevMonth: () => {},
    showNextMonth: () => {},
    goToToday: () => {},
    setLoading: () => {},
    setScrolling: () => {},
    setActiveDate: () => {},
    setSelectedMonth: () => {},
    setMonthsReducer: () => {},
    setPreventActiveDateChange: () => {},
    setPendingActiveDate: () => {},
    setHighlightDrafts: () => {},
    setCalendarRef: () => {},
    proceedToPendingDate: () => {},
    dispatchAction: () => {},
    dispatchMonthAction: () => {},
    snapToMonth,
    monthsReducer: { months, latestAction: null },
    activeDate,
    action,
    pendingActiveDate,
    highlightDrafts,
    preventActiveDateChange,
    calendarRef,
    isScrolling: false,
    calendar: {
      today: today,
      selected: selected,
      /** First visible date */
      fromDate: previousMonth.length ? previousMonth[0] : currentMonth[0],
      /** Last visible date */
      toDate: nextMonth.length
        ? nextMonth[nextMonth.length - 1]
        : currentMonth[currentMonth.length - 1],
      previousMonthDates: previousMonth,
      currentMonthDates: currentMonth,
      nextMonthDates: nextMonth,
    },
    isLoading: false,
  }
}

export const CalendarContext = createContext(getDefaultValues(DateTime.now()))

export const CalendarContextProvider = ({ children }: any) => {
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now())
  const [calendarRef, setCalendarRef] = useState<HTMLDivElement | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [activeDate, setActiveDate] = useState<string | null>(null)
  const [highlightDrafts, setHighlightDrafts] = useState<boolean>(false)
  const [pendingActiveDate, setPendingActiveDate] = useState<string | null>(
    null
  )
  const [preventActiveDateChange, setPreventActiveDateChange] =
    useState<boolean>(false)
  const [action, dispatchAction] = useState<CalendarActions | null>(null)

  const value: Calendar = getDefaultValues(selectedDate || DateTime.now())

  const getDefaultMonths = () => {
    const startMonth = DateTime.now().minus({ months: 3 })
    return Array.from({ length: 7 }, (_, i) => startMonth.plus({ months: i }))
  }

  //const [months, setMonths] = useState<DateTime[]>(getDefaultMonths())

  const reducer = (
    state: { months: DateTime[]; latestAction: MonthReducerActions | null },
    action: MonthReducerActions | null
  ): { months: DateTime[]; latestAction: MonthReducerActions | null } => {
    switch (action) {
      case 'prev':
        return {
          months: state.months.map((month) => month.minus({ month: 1 })),
          latestAction: 'prev',
        }
      case 'next':
        return {
          months: state.months.map((month) => month.plus({ month: 1 })),
          latestAction: 'next',
        }
      case 'today':
        return {
          months: getDefaultMonths(),
          latestAction: 'today',
        }
      case 'prev-snap': {
        const newSelectedDate = selectedDate.minus({ month: 1 }) // selectedDate should be in the middle of the array
        return {
          months: Array.from({ length: 7 }, (_, i) =>
            newSelectedDate.plus({ months: i - 2 })
          ),

          latestAction: 'prev-snap',
        }
      }
      case 'next-snap':
        const newSelectedDate = selectedDate.plus({ month: 1 }) // selectedDate should be in the middle of the array
        return {
          months: Array.from({ length: 7 }, (_, i) =>
            newSelectedDate.plus({ months: i - 4 })
          ),
          latestAction: 'next-snap',
        }
      default:
        return { ...state, latestAction: null }
    }
  }

  type MonthReducer = {
    prevState: { months: DateTime[]; latestAction: MonthReducerActions | null }
    action: MonthReducerActions | null
  }

  const [monthsReducer, setMonthsReducer] = useReducer(reducer, {
    months: getDefaultMonths(),
    latestAction: null,
  })

  const showPrevMonth = () => {
    setSelectedDate(selectedDate.minus({ month: 1 }))
    setMonthsReducer('prev-snap')
    Analytics._logEvent({
      name: 'calendar_month_change',
      params: {
        currentDate: selectedDate.toISO(),
        direction: 'prev',
      },
    })
  }
  const showNextMonth = () => {
    setSelectedDate(selectedDate.plus({ month: 1 }))
    setMonthsReducer('next-snap')
    Analytics._logEvent({
      name: 'calendar_month_change',
      params: {
        currentDate: selectedDate.toISO(),
        direction: 'next',
      },
    })
  }
  const goToToday = () => {
    setSelectedDate(DateTime.now())
    setMonthsReducer('today')
    Analytics._logEvent({
      name: 'calendar_month_change',
      params: {
        currentDate: selectedDate.toISO(),
        direction: 'today',
      },
    })
  }

  const handleSetActiveDate = (day: string | null) => {
    if (!preventActiveDateChange) setActiveDate(day)
    else setPendingActiveDate(day)
  }

  const proceedToPendingDate = () => {
    setActiveDate(pendingActiveDate)
    setPendingActiveDate(null)
  }

  const dispatchMonthAction = (action: MonthReducerActions) => {}

  const [isScrolling, setScrolling] = useState(false)

  return (
    <CalendarContext.Provider
      value={{
        ...value,
        monthsReducer,
        activeDate,
        isLoading,
        calendarRef,
        pendingActiveDate,
        preventActiveDateChange,
        highlightDrafts,
        action,
        isScrolling,
        dispatchAction,
        setScrolling,
        dispatchMonthAction,
        setHighlightDrafts,
        setCalendarRef,
        setMonthsReducer,
        setPreventActiveDateChange,
        setActiveDate: handleSetActiveDate,
        setSelectedMonth: setSelectedDate,
        proceedToPendingDate,
        showPrevMonth,
        showNextMonth,
        goToToday,
        setLoading,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}

export default CalendarContext
