import { DateTime } from 'luxon'

import { useEffect, useState } from 'react'

export interface Calendar {
  showPrevMonth: () => void
  showNextMonth: () => void
  goToToday: () => void
  calendar: {
    today: {
      date: DateTime
      monthName: string
      monthNumber: number
      year: number
    }
    selected: {}
    /** First visible date */
    fromDate: string
    /** Last visible date */
    toDate: string
    previousMonthDates: string[]
    currentMonthDates: string[]
    nextMonthDates: string[]
  }
}

const useCalendar = () => {
  const defaultDate = DateTime.now()
  const [selectedDate, setSelectedDate] = useState(defaultDate)

  const sundayShift = 0

  let previousMonth = []
  let currentMonth = []
  let nextMonth = []

  const firstDayOfTheMonth = DateTime.fromObject({
    year: selectedDate.year,
    month: selectedDate.month,
  }).weekday

  const daysTotalInPreviousMonth =
    selectedDate.month > 1
      ? DateTime.fromObject({
          year: selectedDate.year,
          month: selectedDate.month - 1,
        }).endOf('month').day
      : 31 // December

  const daysTotal = DateTime.fromObject({
    year: selectedDate.year,
    month: selectedDate.month,
  }).endOf('month').day

  //Previous month
  for (
    let i = daysTotalInPreviousMonth;
    i > daysTotalInPreviousMonth - (firstDayOfTheMonth - sundayShift) + 1;
    i--
  ) {
    const pushDate = selectedDate
      .set({ day: i, month: selectedDate.month - 1 })
      .toFormat('yyyy-LL-dd')

    previousMonth.unshift(pushDate)
  }

  //Current month
  for (let i = 1; i <= daysTotal; i++) {
    const pushDate = selectedDate.set({ day: i }).toFormat('yyyy-LL-dd')
    currentMonth.push(pushDate)
  }

  const totalDaysToShow =
    (daysTotal === 31 && firstDayOfTheMonth >= 6) ||
    (daysTotal === 30 && firstDayOfTheMonth === 7)
      ? 42
      : 35

  const daysLeft =
    totalDaysToShow - (previousMonth.length + currentMonth.length) //

  // Next month
  for (let i = 1; i <= daysLeft; i++) {
    const pushDate = selectedDate
      .plus({ months: 1 })
      .set({ day: i })
      .toFormat('yyyy-LL-dd')
    nextMonth.push(pushDate)
  }

  const selected = {
    date: selectedDate,
    monthName: selectedDate.toFormat('LLLL'),
    monthNumber: selectedDate.month,
    year: selectedDate.year,
  }

  const today = {
    date: defaultDate,
    monthName: defaultDate.toFormat('LLLL'),
    monthNumber: defaultDate.month,
    year: defaultDate.year,
  }

  const showPrevMonth = () => {
    setSelectedDate(selectedDate.minus({ month: 1 }))
  }

  const showNextMonth = () => {
    setSelectedDate(selectedDate.plus({ month: 1 }))
  }
  const goToToday = () => {
    setSelectedDate(DateTime.now())
  }

  return {
    showPrevMonth,
    showNextMonth,
    goToToday,
    calendar: {
      today: today,
      selected: selected,
      /** First visible date */
      fromDate: previousMonth.length ? previousMonth[0] : currentMonth[0],
      /** Last visible date */
      toDate: nextMonth.length
        ? nextMonth[nextMonth.length - 1]
        : currentMonth[currentMonth.length - 1],
      previousMonthDates: previousMonth,
      currentMonthDates: currentMonth,
      nextMonthDates: nextMonth,
    },
  } as Calendar
}

export default useCalendar
