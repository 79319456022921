import { useContext, useState } from 'react'

import Calendar from '../components/sections/CalendarElements/Calendar/Calendar'
import Sidebar from '../components/sections/Sidebar/Sidebar'
import '../App.css'

import SecondaryNav from '../components/navigation/SecondaryNavigation/SecondaryNav'
import { Analytics } from '../services/analytics'
import CalendarContext, {
  CalendarContextProvider,
} from '../context/CalendarContext'
import Confirm from '../components/modals/Confirm'
import CalendarSidebar from '../components/sections/Sidebar/CalendarSidebar'
import CyclesSidebar from '../components/sections/Sidebar/CyclesSidebar'

function CalendarPage({}) {
  const {
    action,
    calendar,
    activeDate,
    setActiveDate,
    dispatchAction,
    preventActiveDateChange,
    isLoading,
    setPreventActiveDateChange,
  } = useContext(CalendarContext)

  //const [activeDay, setActiveDay] = useState(false)
  const [sidebarActive, setSidebarActive] = useState(false)

  const handleDayClick = (activatedDate: string | null) => {
    if (activatedDate === activeDate) {
      // clicked on the active day
      if (preventActiveDateChange) {
        return
      } else {
        handleSidebarClose()
      }

      setTimeout(() => {
        setActiveDate(null)
      }, 200)
    } else {
      setSidebarActive(true)
      setActiveDate(activatedDate)
    }
  }

  const handleSidebarClose = () => {
    setSidebarActive(false)
    // Timeout needed for sidebar to hide
    // before we clearout the date
    setTimeout(() => setActiveDate(null), 200)
  }

  return (
    <>
      <SecondaryNav isLoading={isLoading} />
      <div style={{ overflow: 'hidden' }}>
        <Calendar
          isSidebarActive={sidebarActive || !!action}
          onDayClick={handleDayClick}
          activeYearMonth="2024-05"
        />
      </div>
      <CalendarSidebar
        isActive={sidebarActive && !action}
        onDismiss={handleSidebarClose}
      />
      <CyclesSidebar
        isActive={action === 'show-cycles'}
        onDismiss={() => dispatchAction(null)}
      />
    </>
  )
}

export default CalendarPage
