import React, { CSSProperties, useContext } from 'react'
import { AppContext } from '../../../context/AppContext'
import { IconArrowDown } from '../Icons/Icons'
import css from './GymSelector.module.css'
import API from '../../../services'

type Props = {
  style?: CSSProperties
}

const GymSelector = ({ style }: Props) => {
  const { currentSpace, spaces } = useContext(AppContext)

  const handleSpaceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    API.patchUserAttribute({
      defaultSpace: e.target.value,
    })
  }

  return spaces.length <= 1 && currentSpace ? (
    <div className={css['gym-name']} style={style}>
      {currentSpace.title}
      {process.env.REACT_APP_ENV === 'dev' ? (
        <span
          style={{
            marginLeft: '8px',
            fontWeight: 'normal',
            color: 'var(--secondary-text-white)',
          }}
        >
          Dev
        </span>
      ) : (
        <></>
      )}
    </div>
  ) : currentSpace ? (
    <div className={`${css['gym-name']} ${css['multi']}`} style={style}>
      <div>{currentSpace.title}</div>
      {/* {currentSpace.isDemo ? (
        <span
          style={{
            marginLeft: '8px',
            fontWeight: 'normal',
            color: 'var(--secondary-text-white)',
          }}
        >
          Demo
        </span>
      ) : (
        <></>
      )} */}
      <IconArrowDown />
      <select
        className={css['space-selector']}
        onChange={handleSpaceChange}
        defaultValue={currentSpace.id}
      >
        {spaces.map((space) => (
          <option value={space.id} key={space.id}>
            {space.title}
          </option>
        ))}
      </select>

      {/* {process.env.REACT_APP_ENV === 'dev' ? (
        <span
          style={{
            marginLeft: '8px',
            fontWeight: 'normal',
            color: 'var(--secondary-text-white)',
          }}
        >
          Dev
        </span>
      ) : (
        <></>
      )} */}
    </div>
  ) : (
    <></>
  )
}

export default GymSelector
