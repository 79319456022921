import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Analytics } from '../../services/analytics'
import Logo from '../../components/atoms/Logo/Logo'

type Props = {}

const GetApp = (props: Props) => {
  const { code } = useParams()

  useEffect(() => {
    Analytics._logEvent({
      name: 'qr_code_for_app_scanned',
      params: { gymCode: code ? code : 'no-code' },
    })

    const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1
    const isiPhone = navigator.userAgent.toLowerCase().indexOf('iphone') > -1

    const path = isAndroid
      ? 'https://play.google.com/store/apps/details?id=io.rewod.app'
      : isiPhone
      ? 'https://apps.apple.com/us/app/rewod-workou:ts-community/id6444032971'
      : null

    if (path) window.location.replace(path)
  }, [])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        background: 'url(/img/mobile-splash.jpg) no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '32px',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          width: '400px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {' '}
        <Logo style={{ width: '140px' }} type="full" />
        <h2>Get the App</h2>
        <p>
          Join your gym with the Rewod app, start tracking your workouts, and
          become a part of the community
        </p>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px' }}>
          <a
            href="https://apps.apple.com/us/app/rewod-workouts-community/id6444032971"
            target="_blank"
          >
            <div>
              <img src="/img/dl-app-store.svg" height="40" />
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=io.rewod.app"
            target="_blank"
          >
            <div>
              <img src="/img/google-play-badge.svg" height="40" />
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default GetApp
