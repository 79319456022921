import {
  useReducer,
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

import { useLocalStorage } from 'usehooks-ts'

import { analytics, auth, firestore } from '../firebase'
import { collection, doc, query, where } from 'firebase/firestore'
import {
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore'
import { DocumentData } from '@google-cloud/firestore'
import { UserContext } from './UserContext'
import { GymData, GymMemberData, GymSubscriptionInstance } from '../types/types'

import { getSubscription } from '../services/getSubscription'
import { setUserProperties } from 'firebase/analytics'
import { useQuery } from '@tanstack/react-query'
import { Analytics } from '../services/analytics'

//import { Analytics } from '../analytics'

type SpaceDataShort = {
  title: string
  id: string
  subscriptionActive: boolean | undefined
  ownerID: string
}

export enum CalendarViewState {
  Collapse = 'collapse',
  Expand = 'expand',
}

interface AppContext {
  spaces: SpaceDataShort[] | []
  spacesAreLoading: boolean
  currentSpace: GymData | null
  firstLoadDone: boolean
  appIsLoading: boolean
  calendarViewState: CalendarViewState
  setCalendarViewState: Dispatch<SetStateAction<CalendarViewState>>
}

// const defaultSpaceData: GymData = {
//   title: '',
//   id: '',
//   ownerID: '',
//   accessCode: null,
//   permissions: {
//     canEditWorkouts: false,
//     canEditPermissions: false,
//     canEditMembers: false,
//   },
//   hasAccess: false,
//   role: 'athlete',
//   subscription: { status: 'incomplete' },
// }

const def: AppContext = {
  spaces: [],
  spacesAreLoading: true,
  currentSpace: null,
  firstLoadDone: false,
  appIsLoading: true,
  calendarViewState: CalendarViewState.Collapse,
  setCalendarViewState: () => CalendarViewState,
}

export const AppContext = createContext({
  // hasLoaded differentiates placeholder data from real data
  ...def,
})

export const AppContextProvider = ({ children }: any) => {
  const { user, userID, userIsLoading } = useContext(UserContext)
  const [calendarViewState, setCalendarViewState] =
    useLocalStorage<CalendarViewState>(
      'calendar-view-state',
      CalendarViewState.Collapse
    )

  useEffect(() => {
    Analytics._identifyUser(userID, { calendarViewState })
  }, [calendarViewState])

  // Array with space IDs the user can access
  const spaceAccess =
    user.spaceAccess && user.spaceAccess.length ? user.spaceAccess : ['no-gyms']

  const spacesRef = userID
    ? query(
        collection(firestore, 'spaces'),
        where('id', 'in', spaceAccess) // Limit: 10
      )
    : undefined

  const spaces = useFirestoreQueryData(
    ['spaces', user.spaceAccess, userID],
    spacesRef,
    { subscribe: true },
    {
      onSuccess() {},
      onError() {
        console.log('Woops, something went wrong with spaces!')
      },
    }
  )

  const currentSpace =
    userID && spaces.data && spaces.data.length
      ? getSpaceByID(spaces.data, user.defaultSpace ? user.defaultSpace : '')
      : null

  const memberRef =
    currentSpace && currentSpace.id
      ? doc(firestore, 'spaces', currentSpace.id, 'acl', userID)
      : undefined

  const memberData = useFirestoreDocumentData(
    ['spaces', userID, currentSpace],
    memberRef,
    { subscribe: true },
    {
      onSuccess() {},
      onError() {
        console.log('Woops, something went wrong with memberdata!')
      },
    }
  )

  const {
    isLoading: subscriptionDataIsLoading,
    error,
    data: subscriptionData,
  } = useQuery({
    queryKey: ['getSubscription', currentSpace?.id, user.introComplete],
    queryFn: () => getSubscription(currentSpace),
    enabled: currentSpace?.id ? true : false,
    refetchOnWindowFocus: false,
  })

  let appIsLoading = true
  if (
    (!userIsLoading && !userID) ||
    (userID && !spaces.isLoading && !currentSpace) ||
    (currentSpace && !memberData.isLoading && !subscriptionDataIsLoading)
  ) {
    appIsLoading = false
  } else if (
    (spaces.isLoading || memberData.isLoading || subscriptionDataIsLoading) &&
    !appIsLoading
  ) {
    appIsLoading = true
  }

  const isDemo = currentSpace?.id === process.env.REACT_APP_DEMO_GYM_ID

  const permissions = memberData.data
    ? memberData.data.permissions
    : userID === currentSpace?.ownerID
    ? {
        canEditWorkouts: true,
        canEditPermissions: true,
        canRemoveComments: true,
        canEditMembers: true,
      }
    : {
        canEditWorkouts: false,
        canEditPermissions: false,
        canRemoveComments: false,
        canEditMembers: false,
      }

  const value = !appIsLoading
    ? {
        spaces: spaces.data
          ? spaces.data.map((space) => {
              return {
                title: space.title,
                id: space.id,
                subscriptionActive: space.subscriptionActive,
                ownerID: space.ownerID,
              }
            })
          : [],
        spacesAreLoading:
          currentSpace && subscriptionDataIsLoading ? true : false,
        firstLoadDone: true,
        appIsLoading: false,
        currentSpace: currentSpace
          ? {
              ...currentSpace,
              permissions: permissions,
              hasAccess: memberData.data && memberData.data.hasAccess,
              role: memberData.data && memberData.data.role,
              subscription: subscriptionData as GymSubscriptionInstance,
              isDemo,
            }
          : null,
        calendarViewState,
        setCalendarViewState,
      }
    : def

  useEffect(() => {
    if (currentSpace) setUserProperties(analytics, { gymID: currentSpace.id })
  }, [currentSpace])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

const getSpaceByID = (spaces: any[], id: string) => {
  if (!spaces) return null

  const currSpaceData =
    spaces.filter((space) => space.id === id)[0] || spaces[0]

  return currSpaceData
    ? ({
        title: currSpaceData.title,
        id: currSpaceData.id,
        ownerID: currSpaceData.ownerID,
        accessCode: currSpaceData.accessCode,
        workoutVisibility: currSpaceData.workoutVisibility,
      } as GymData)
    : null //({ title: '', id: '' } as GymData)
}

export default AppContext
