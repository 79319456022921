import { doc } from 'firebase/firestore'
import { db } from '../firebase'
import { Workout, WorkoutShortcut } from '../types/workouts'
import { useFirestoreDocumentData } from '@react-query-firebase/firestore'

export const useWorkoutShortcuts = () => {
  const shortcutsRef = doc(db, 'workout_shortcuts', 'shortcuts')

  const shortcutsRaw = useFirestoreDocumentData(
    ['workout_shortcuts'],
    shortcutsRef,
    {
      subscribe: false,
    },
    {
      onSuccess(snapshot: any) {},
      onError(error: any) {
        console.error('Woops, something went wrong with Workout Shortcuts!')
      },
    }
  )

  const shortcuts: WorkoutShortcut[] = shortcutsRaw.data?.shortcuts || []

  return { shortcuts, shortcutsAreLoading: shortcutsRaw.isLoading }
}
