import React, { useRef, useEffect, useState, useContext } from 'react'
import css from './MoveInput.module.css'
import { matchSorter } from 'match-sorter'
import { standartizeMovementEntry } from '../../../helpers'
import movements from '../../../data/exercises.json'

import Downshift, { StateChangeOptions, StateChangeTypes } from 'downshift'
import { Controller, useFormContext } from 'react-hook-form'
import { RewodLegacyEvent } from '../../../types/legacyEvents'
import { snakeCase } from 'snake-case'
import { useHotkeys } from 'react-hotkeys-hook'
import { Workout } from '../../../types/workouts'
import { Highlight } from '../../atoms/Highlight/Highlight'
import useMovements from '../../../hooks/useMovements'
import { IconKey } from '../../atoms/Icons/Icons'

type Props = {
  className?: string
  placeholder?: string
  packPosition: 'first' | 'middle' | 'last' | 'lonely'
  onKeyUp: (type: 'movement', order: number, key: string) => void
  order: number
}

const MoveInput = ({
  className,
  packPosition,
  placeholder,
  onKeyUp,
  order,
}: Props) => {
  //const allItems = movements // DB import

  const { movements: allItems } = useMovements()

  const { setValue, watch, register, control } = useFormContext()

  const fieldName = `superset.${order}.movement`
  const value = watch(fieldName) ? watch(fieldName).name : ''

  const matches =
    allItems && value && value.trim().length > 1
      ? matchSorter(allItems, value.trim().replaceAll('-', ' '), {
          keys: [(item) => item.name.replaceAll('-', ' ')],
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        })
      : []

  const matchedNames = matches.map((m) => m.name)
  const exactMatchIsPresent =
    value && value.trim().length > 1 && matchedNames.includes(value.trim())

  const [justClosed, setJustClosed] = useState(true)

  const handleStateChange = (changes: StateChangeOptions<any>) => {
    if (changes.selectedItem) {
      // Movement selected from the list that exists in Database

      const selectedItem = changes.selectedItem

      let selectedValue: Workout.Superset.Exercise['movement']

      if (typeof selectedItem === 'string') {
        // Movement is not in DB - recording as a custom one
        const name = standartizeMovementEntry(selectedItem)
        const id = 'custom__' + snakeCase(name)

        // We're only storing name and ID as metadata
        selectedValue = {
          name: name,
          id: id,
          status: 'unpublished',
        }
      } else
        selectedValue = {
          name: changes.selectedItem.name,
          id: changes.selectedItem.id,
        }

      setValue(fieldName, selectedValue)

      //console.log('set value:', selectedValue)
    } else if (
      changes.hasOwnProperty('inputValue') &&
      value &&
      value.length > 1
    ) {
      // User clicked esc/outside of input
      const customName = standartizeMovementEntry(value)
      const customID = 'custom__' + snakeCase(customName)

      // Making sure that there are no matched entries
      const matchedEntry = movements.filter(
        (item) => item.name === changes.inputValue
      )
        ? movements.find(
            (item) =>
              changes.inputValue &&
              item.name.toLowerCase().replaceAll('-', ' ') ===
                changes.inputValue.toLowerCase().replaceAll('-', ' ')
          )
        : null

      const enteredMove: Workout.Superset.Exercise['movement'] = {
        name: matchedEntry ? matchedEntry.name : customName,
        id: matchedEntry ? matchedEntry.id : customID,
        status: matchedEntry ? undefined : 'unpublished',
      }
      setValue(fieldName, enteredMove)
    }
  }

  // When it's the last input, enter press
  // Enter key will add another movement
  // By default we use Ctrl/Alt+Enter
  // We use hotkeys hook to not interfere
  // with Ctrl/Alt+Enter combo

  const [enableEnterHotkey, setEnableEnterHotkey] = useState(true)
  const ref = useHotkeys<HTMLDivElement>(
    `Enter`,
    () => {
      onKeyUp('movement', order, 'Enter')
    },
    {
      enableOnFormTags: true,
      enabled: enableEnterHotkey,
    }
  )

  return (
    <Controller
      control={control}
      name={fieldName + '.name'}
      defaultValue={value}
      render={(controllerMethods) => (
        <Downshift
          inputValue={controllerMethods.field.value}
          onInputValueChange={controllerMethods.field.onChange}
          onStateChange={handleStateChange}
          defaultHighlightedIndex={0}
          itemToString={(
            item // sometimes item is a string
          ) => (item ? (typeof item === 'object' ? item.name : item) : value)}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            getRootProps,
          }) => {
            return (
              <div
                ref={ref}
                className={`${css['move']} ${className && css[className]} ${
                  css[packPosition]
                } ${
                  inputValue &&
                  matches.length &&
                  !exactMatchIsPresent &&
                  css['with-key']
                } ${matches.length < 7 && css['short']}`}
              >
                <div {...getRootProps(undefined, { suppressRefError: true })}>
                  <input
                    placeholder={placeholder}
                    {...getInputProps({
                      ref: controllerMethods.field.ref,
                      name: controllerMethods.field.name,
                      onKeyUp: (e) => {
                        setEnableEnterHotkey(!isOpen)

                        if (isOpen && justClosed) setJustClosed(false)
                        else if (!isOpen && justClosed && e.key !== 'Enter') {
                          onKeyUp('movement', order, e.key)
                        } else if (!isOpen && !justClosed) {
                          setJustClosed(true)
                        }
                      },
                    })}
                  />
                </div>
                {isOpen ? (
                  <div className={css['options-list']}>
                    <ul {...getMenuProps()}>
                      {
                        /**
                         * If there are no matches at all,
                         * the first option in the auto-suggest dropdown
                         * will be the one user entered
                         */
                        inputValue &&
                        inputValue.length > 1 &&
                        isOpen &&
                        !matches.length ? (
                          <li
                            {...getItemProps({
                              key: value,
                              index: 0,
                              item: value,
                              style: {
                                backgroundColor:
                                  highlightedIndex === 0
                                    ? 'var(--accent-highlight)'
                                    : 'white',
                                fontWeight:
                                  selectedItem === value ? 'normal' : 'normal',
                              },
                            })}
                          >
                            {value}
                          </li>
                        ) : (
                          <></>
                        )
                      }
                      <>
                        {matches.map((item, index) => (
                          <li
                            {...getItemProps({
                              key: item.name,
                              index: index,
                              item,
                              style: {
                                backgroundColor:
                                  highlightedIndex === index
                                    ? 'var(--accent-highlight)'
                                    : 'white',
                              },
                            })}
                          >
                            {inputValue && (
                              <Highlight highlight={inputValue}>
                                {item.name}
                              </Highlight>
                            )}
                          </li>
                        ))}
                      </>
                    </ul>
                    {/**
                     * If some matches are present, but no exact one,
                     * we're showing a hint "Press ESC to add" custom
                     * entry in the input
                     */}
                    {inputValue && matches.length && !exactMatchIsPresent ? (
                      <div className={css['item']}>
                        <IconKey keyName="Enter" />
                        <div>
                          add &quot;
                          <span>{value.trim()}</span>
                          &quot;{' '}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )
          }}
        </Downshift>
      )}
    />
  )
}

export default MoveInput
