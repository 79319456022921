import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import { GymMemberData } from '../../types/types'
import Button from '../buttons/Button/Button'
import useGym from '../../hooks/useGym'
import SegmentedInput from '../inputs/SegmentedInput/SegmentedInput'
import ToggleInput from '../inputs/ToggleInput/ToggleInput'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'

type Props = {
  isVisible: boolean
  isLoading?: boolean
  onCancel: () => void
  onConfirm: () => void
}

const GymName = ({ isVisible, onCancel, onConfirm }: Props) => {
  const { currentSpace } = useContext(AppContext)

  const { register, reset, handleSubmit, setFocus } = useForm()
  const { error, setError, isLoading, updateGymInfo } = useGym({
    onComplete: () => {
      reset()
      onConfirm()
    },
    joinMode: false,
  })

  const save = (data: any) => {
    console.log('data', data)
    updateGymInfo('title', data.gymName.trim())
  }

  useEffect(() => setFocus('gymName'), [isVisible, error])

  return (
    <Modal
      isVisible={isVisible}
      onCancel={() => {
        reset()
        onCancel()
      }}
      size="small"
      title="Edit Gym Name"
      buttons={{
        secondary: {
          onClick: () => {
            reset()
            onCancel()
          },
        },
        primary: {
          onClick: handleSubmit(save),
          label: 'save',
          isSubmitButton: true,
          disabled: error ? true : false,
          isLoading: isLoading,
        },
      }}
    >
      <form onSubmit={handleSubmit(save)}>
        <input
          defaultValue={currentSpace?.title}
          maxLength={24}
          style={{
            borderColor: error ? 'var(--accent-red)' : '',
          }}
          {...register('gymName', { onChange: () => setError(null) })}
        />
        {error && (
          <p style={{ marginTop: '8px', color: 'var(--accent-red)' }}>
            {error}
          </p>
        )}
      </form>
    </Modal>
  )
}

export default GymName
