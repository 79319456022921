import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../App.css'
import { v4 as uuid } from 'uuid'
import toast, { Toaster } from 'react-hot-toast'
import SecondaryNav from '../components/navigation/SecondaryNavigation/SecondaryNav'
import { AppContext } from '../context/AppContext'

import { GymMemberData } from '../types/types'
import { UserContext } from '../context/UserContext'
import { DateTime } from 'luxon'
import GymName from '../components/modals/GymName'
import EditAccessCode from '../components/modals/EditAccessCode'
import { auth, logout } from '../firebase'
import ButtonToolbar from '../components/buttons/ButtonToolbar/ButtonToolbar'
import JoinGym from '../components/modals/JoinGym'
import Confirm from '../components/modals/Confirm'
import useGym from '../hooks/useGym'
import EditName from '../components/modals/EditName'
import EditEmail from '../components/modals/EditEmail'
import EditPassword from '../components/modals/EditPassword'
import Footer from '../components/sections/Footer/Footer'
import Page from './technical/Page'

type Confirmation = {
  what: 'leave-gym' | 'logout'
  gymID?: string
  text: string
}

const Account = () => {
  const { user, userID } = useContext(UserContext)
  const { spaces } = useContext(AppContext)
  const [confirmation, setConfirmation] = useState<Confirmation | null>(null)
  const navigate = useNavigate()

  const [visibleModal, setVisibleModal] = useState<
    'name' | 'email' | 'joinGym' | 'email' | 'password' | null
  >(null)

  const { leaveGym, isLoading } = useGym({
    onComplete: () => setConfirmation(null),
    joinMode: false,
  })

  const handleConfirmation = (what: Confirmation['what']) => {
    switch (what) {
      case 'leave-gym': {
        if (confirmation && confirmation.gymID) leaveGym(confirmation.gymID)
        break
      }
      case 'logout': {
        console.log('logout')
        logout()
      }
    }
  }

  useEffect(() => {
    console.log('confirmation', confirmation)
  }, [confirmation])

  return (
    <>
      <EditName
        onDismiss={() => setVisibleModal(null)}
        isVisible={visibleModal === 'name'}
      />

      <EditPassword
        onDismiss={() => setVisibleModal(null)}
        isVisible={visibleModal === 'password'}
      />

      <EditEmail
        onDismiss={() => setVisibleModal(null)}
        isVisible={visibleModal === 'email'}
      />

      <Confirm
        isVisible={confirmation ? true : false}
        text={confirmation ? confirmation.text : ''}
        onDismiss={() => setConfirmation(null)}
        isLoading={isLoading}
        onConfirm={() => confirmation && handleConfirmation(confirmation.what)}
      />

      <JoinGym
        isVisible={visibleModal === 'joinGym'}
        onDismiss={() => setVisibleModal(null)}
      />
      <Page>
        <div id="page-content" className="col-8">
          <section style={{ marginTop: '24px' }}>
            <div>
              <label style={{ margin: 0 }}>Name</label>
              <span>{user.name}</span>{' '}
              <span
                onClick={() => setVisibleModal('name')}
                className="link s"
                style={{ marginLeft: '8px' }}
              >
                Edit
              </span>
            </div>
            {process.env.NODE_ENV === 'development' && (
              <div>
                <label>User ID</label>
                <span>{user.uid}</span>
              </div>
            )}
            <div>
              <label>E-Mail</label>
              <span>{user.email}</span>{' '}
              <span
                onClick={() => setVisibleModal('email')}
                className="link s"
                style={{ marginLeft: '8px' }}
              >
                Edit
              </span>
            </div>
            <div>
              <label>My Gyms</label>
              {spaces.map((s) => (
                <div key={s.id}>
                  <span>{s.title}</span>{' '}
                  {s.ownerID === userID ? (
                    <span
                      key={s.id}
                      style={{
                        font: 'var(--small-text)',
                        fontWeight: 600,
                        color: 'var(--secondary-text)',
                      }}
                    >
                      Owner
                    </span>
                  ) : (
                    <span
                      onClick={() =>
                        setConfirmation({
                          what: 'leave-gym',
                          text: `Leave ${s.title}?`,
                          gymID: s.id,
                        })
                      }
                      className="link s"
                    >
                      Leave
                    </span>
                  )}
                </div>
              ))}
              <span
                onClick={() => setVisibleModal('joinGym')}
                className="link s"
              >
                Join another gym
              </span>
              <br />
            </div>
            <ButtonToolbar
              style={{ width: '240px', margin: '40px 0 8px' }}
              type="light"
              onClick={() => setVisibleModal('password')}
            >
              Change Password
            </ButtonToolbar>
            <ButtonToolbar
              style={{ width: '240px' }}
              type="light"
              onClick={() => {
                setConfirmation({
                  what: 'logout',
                  text: `Sign out?`,
                })
              }}
            >
              Logout
            </ButtonToolbar>
          </section>
        </div>
      </Page>
    </>
  )
}

export default Account
