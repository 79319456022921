import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from '@firebase/auth'

import { useContext, useState } from 'react'
import {
  FieldValues,
  useForm,
  UseFormRegisterReturn,
  UseFormSetFocus,
} from 'react-hook-form'
import { UserContext } from '../context/UserContext'
import { auth } from '../firebase'
import { createNewUserInDatabase } from '../services/createNewUserInDatabase'
import API from '../services'
import { Analytics } from '../services/analytics'
import { useNavigate } from 'react-router-dom'

export type AuthValues = {
  name: string
  email: string
  password: string
  passwordRepeat: string
  agreedToPolicies: boolean
}

export type AuthMode =
  | 'login'
  | 'register'
  | 'reset'
  | 'verify'
  | 'complete'
  | 'resetSent'
  | 'resetEnterNewPassword'

// export type AuthComponentProps = {
//   values: AuthValues
//   error: string | null
//   isLoading: boolean
//   onSubmit: (data: UseFormHandleSubmit<FieldValues>) => void
//   onChange: (values: AuthValues) => void
//   onModeSwitch: (mode: AuthMode) => void
// }

export type AuthForm = {
  isLoading: boolean
  error: string | null
  name: UseFormRegisterReturn<'name'>
  email: UseFormRegisterReturn<'email'>
  password: UseFormRegisterReturn<'password'>
  passwordRepeat: UseFormRegisterReturn<'passwordRepeat'>
  agreedToPolicies: UseFormRegisterReturn<'agreedToPolicies'>
  setFocus: UseFormSetFocus<FieldValues>
  handleSubmit: () => {}
  onModeSwitch: (mode: AuthMode) => void
}

type Props = {
  defaultMode?: AuthMode | null
  actionCode?: string | null
  introMode?: 'create-gym' | 'join-gym'
}

const useAuth = ({ defaultMode, actionCode, introMode }: Props) => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    formState: { errors },
  } = useForm()

  const fields = {
    name: {
      ...register('name', { maxLength: 40, onChange: () => setError(null) }),
    },
    email: {
      ...register('email', { maxLength: 40, onChange: () => setError(null) }),
    },
    password: {
      ...register('password', {
        maxLength: 40,
        onChange: () => setError(null),
      }),
    },
    passwordRepeat: {
      ...register('passwordRepeat', {
        maxLength: 40,
        onChange: () => setError(null),
      }),
    },
    agreedToPolicies: {
      ...register('agreedToPolicies'),
    },
  }

  const [mode, setMode] = useState<AuthMode>(
    defaultMode ? defaultMode : !auth.currentUser ? 'register' : 'verify'
  ) // we check if email verified before showing auth module
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)

  const registerNewUserInFirebase = async (data: {
    name: string
    email: string
    password: string
  }) => {
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      )
      const user = res.user

      await sendEmailVerification(user)
      await createNewUserInDatabase(data.name, introMode)

      Analytics._logEvent({ name: 'auth_register', params: {} })
      return 'success'
    } catch (err) {
      setError(translateAuthErrors(JSON.stringify(err)))
    }
  }

  const logInWithEmailAndPassword = async (data: {
    email: string
    password: string
  }) => {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password)

      Analytics._logEvent({ name: 'auth_login', params: {} })
      return 'success'
    } catch (err) {
      console.log('err', err)
      setError(translateAuthErrors(JSON.stringify(err)))
    }
  }

  const resetWithNewPassword = async (newPassword: string) => {
    if (!actionCode) return
    try {
      await confirmPasswordReset(auth, actionCode, newPassword)

      Analytics._logEvent({ name: 'auth_reset', params: {} })
      return 'success'
    } catch (err) {
      setError(translateAuthErrors(JSON.stringify(err)))
      setLoading(false)
    }
    // const resp = actionCode && (await applyActionCode(auth, actionCode))
  }

  const handleVerifyEmail = async () => {
    //https://firebase.google.com/docs/auth/custom-email-handler
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    if (!actionCode) return
    try {
      setLoading(true)
      await applyActionCode(auth, actionCode)
      await auth.currentUser?.reload()
      Analytics._logEvent({ name: 'auth_verify_email', params: {} })
      setLoading(false)
    } catch (err) {
      console.log('Error verifying email', err)
      setError(JSON.stringify(err))
      setLoading(false)
    }
  }

  const handleRecoverEmail = async () => {
    if (!actionCode) return
    let restoredEmail: string | null | undefined = null
    setLoading(true)
    // Confirm the action code is valid.
    checkActionCode(auth, actionCode)
      .then((info) => {
        // Get the restored email address.
        restoredEmail = info['data']['email']

        // Revert to the old email.

        console.log('restoredEmail', restoredEmail)
        return applyActionCode(auth, actionCode)
      })
      .then(() => {
        console.log('updating user attribute')

        if (typeof restoredEmail !== 'string')
          throw 'Restored email is not a string: ' + restoredEmail
        else if (!restoredEmail) throw 'No restored email received'

        return API.patchUserAttribute({ email: restoredEmail })
        // Account email reverted to restoredEmail
        // TODO: Display a confirmation message to the user.
        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        // auth
        //   .sendPasswordResetEmail(restoredEmail)
        //   .then(() => {
        //     // Password reset confirmation sent. Ask user to check their email.
        //   })
        //   .catch((error) => {
        //     // Error encountered while sending password reset code.
        //   })
      })
      .then(() => {
        console.log('all done I think')
        window.location.replace('/')
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const sendPasswordReset = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email)
      setMode('resetSent')
      return 'success'
    } catch (err) {
      if (JSON.stringify(err).includes('user-not-found')) {
        setError(null)
        setMode('resetSent')
        return 'success'
      }
      console.error(err)
      setError(translateAuthErrors(JSON.stringify(err)))
    }
  }

  const onSubmit = async (data: any) => {
    const error = checkForErrors(data, mode)
    if (error) {
      setError(error)
      console.log('error', error)
      return
    }

    setError(null)
    setLoading(true)
    setSuccess(false)

    let response = null

    switch (mode) {
      case 'register': {
        response = await registerNewUserInFirebase(data)
        break
      }
      case 'login': {
        response = await logInWithEmailAndPassword(data)
        break
      }
      case 'reset': {
        response = await sendPasswordReset(data.email)

        break
      }
      case 'resetEnterNewPassword': {
        response = await resetWithNewPassword(data.password)
      }
    }

    setLoading(false)
    if (response === 'success') {
      setSuccess(true)
      navigate('/')
    }
  }

  const clearErrors = () => {
    setError(null)
  }

  return {
    authForm: {
      isLoading,
      error,
      name: fields.name,
      email: fields.email,
      password: fields.password,
      passwordRepeat: fields.passwordRepeat,
      agreedToPolicies: fields.agreedToPolicies,
      handleSubmit: handleSubmit(onSubmit),
      setFocus,
    },
    mode,
    setMode,
    setFocus,
    handleVerifyEmail,
    handleRecoverEmail,
    clearErrors,
    clearFields: reset,
    isLoading,
    isSuccess,
    error,
  }
}

export default useAuth

const checkForErrors = (values: AuthValues, mode: AuthMode) => {
  // Registration errors have slighly different wording
  if (mode === 'register') {
    if (!values.email && !values.password) {
      return translateAuthErrors('no-email-and-password-first')
    }
    if (!values.password) {
      return translateAuthErrors('no-password-first')
    }
    if (!values.name) {
      return translateAuthErrors('no-name')
    }

    if (!values.email) {
      return translateAuthErrors('no-email')
    }

    if (!values.agreedToPolicies)
      return 'You must agree with Terms of Use and Privacy Policy to create an account'
  }

  if (mode === 'login') {
    if (!values.email && !values.password) {
      return translateAuthErrors('no-email-and-password')
    }
    if (!values.password) {
      return translateAuthErrors('no-password')
    }

    if (!values.email) {
      return translateAuthErrors('no-email')
    }
  }

  if (mode === 'reset') {
    if (!values.email) return translateAuthErrors('no-email')
  }

  if (mode === 'resetEnterNewPassword') {
    if (!values.password) {
      return translateAuthErrors('enter-new-passwords')
    }
  }
}

const translateAuthErrors = (err: string, from?: string) => {
  if (from === 'reset' && err.includes('user-not-found')) return 'Donno, man...'

  if (from === 'settings' && err.includes('wrong-password'))
    return 'Incorrect password'

  if (err.includes('user-not-found') || err.includes('wrong-password'))
    return 'Incorrect e-mail or password'

  if (err.includes('invalid-email')) return 'Please enter a valid e-mail'
  if (err.includes('email-already-in-use'))
    return 'This e-mail is already in use'

  if (err.includes('no-email-and-password-first'))
    return 'Please enter your e-mail and create a password'

  if (err.includes('no-email-and-password'))
    return 'Please enter your e-mail and the password'

  if (err.includes('no-email')) return 'Please enter your e-mail'

  if (err.includes('no-password-first')) return 'Please create a password'

  if (err.includes('no-password')) return 'Please enter the password'
  if (err.includes('auth/weak-password'))
    return 'Password should be at least 6 characters'

  if (err.includes('no-name')) return 'Please enter your name'
  if (err.includes('network-request-failed'))
    return "There's a problem with the network connection"

  if (err.includes('popup-closed-by-user')) return '' // google sign-in closed
  if (err.includes('invalid-action-code'))
    return 'Password reset link is no longer valid. Please request a new link' // google sign-in closed

  if (err === 'enter-new-passwords') return 'Please enter new password'

  // If all else fails
  return err.replace('Firebase: ', '')
}
