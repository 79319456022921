export enum Muscle {
  abdominals = 'abdominals',
  abductors = 'abductors',
  adductors = 'adductors',
  biceps = 'biceps',
  calves = 'calves',
  chest = 'chest',
  forearms = 'forearms',
  glutes = 'glutes',
  hamstrings = 'hamstrings',
  lats = 'lats',
  'lower back' = 'lower back',
  'middle back' = 'middle back',
  neck = 'neck',
  quadriceps = 'quadriceps',
  shoulders = 'shoulders',
  traps = 'traps',
  triceps = 'triceps',
}

export enum Force {
  pull = 'pull',
  push = 'push',
  static = 'static',
  compound = 'compound',
}

export enum Level {
  beginner = 'beginner',
  intermediate = 'intermediate',
  expert = 'expert',
}

export enum Mechanic {
  compound = 'compound',
  isolation = 'isolation',
}

export enum Equipment {
  body = 'body only',
  machine = 'machine',
  kettlebells = 'kettlebells',
  dumbbell = 'dumbbell',
  plate = 'plate',
  cable = 'cable',
  barbell = 'barbell',
  bands = 'bands',
  d_ball = 'd-ball',
  medicine_ball = 'medicine ball',
  exercise_ball = 'exercise ball',
  e_z_curl_bar = 'e-z curl bar',
  foam_roll = 'foam roll',
  other = 'other',
}

export enum Category {
  strength = 'strength',
  stretching = 'stretching',
  plyometrics = 'plyometrics',
  strongman = 'strongman',
  powerlifting = 'powerlifting',
  cardio = 'cardio',
  olympic_weightlifting = 'olympic weightlifting',
  crossfit = 'crossfit',
  weighted_bodyweight = 'weighted bodyweight',
  assisted_bodyweight = 'assisted bodyweight',
  gymnastics = 'gymnastics',
}

export interface Movement {
  name: string
  id: string
  aliases?: string[]
  primaryMuscles: Muscle[]
  secondaryMuscles: Muscle[]
  force?: Force
  level: Level
  mechanic?: Mechanic
  equipment?: Equipment
  category: Category
  updatedDate?: number
}
