import { QueryKey, useQueryClient } from 'react-query'
import { CalendarEvent } from '../../types/calendarEvents'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { query } from 'firebase/firestore'

/**
 * @returns all currently loaded events without fetching
 */

const useCachedCalendarEvents = () => {
  const queryClient = useQueryClient()

  const { currentSpace } = useContext(AppContext)
  const gymID = currentSpace?.id ?? 'no-id'

  const getCachedEventsForDate = (ISODate: string) => {
    const queryKey = ['events', gymID, ISODate]
    const queryData = queryClient.getQueriesData(queryKey)

    const cachedCalendarEvents = queryData
      .map(([, data]) => data)
      .filter((data) => data)
      .flat() as CalendarEvent.Workout[]

    return cachedCalendarEvents.filter(
      (cachedEvent) => cachedEvent.eventDateISO === ISODate
    )
  }

  const getAllCachedEvents = () => {
    const queryKey = ['events']
    const queryData = queryClient.getQueriesData(queryKey)

    return queryData
      .map(([, data]) => data)
      .filter((data) => data)
      .flat() as CalendarEvent.Workout[]
  }

  const allCachedEvents = getAllCachedEvents()

  const getCachedEventByID = (id: string) => {
    const cachedCalendarEvents = getAllCachedEvents()

    return cachedCalendarEvents.find((cachedEvent) => cachedEvent.id === id)
  }

  const updateCachedEvents = ({
    affectedDates,
    updatedEvents,
  }: {
    affectedDates: string[]
    updatedEvents: CalendarEvent.Workout[]
  }) => {
    // Will be up to two date groups: source and destination

    affectedDates.forEach((date) => {
      const queryKey = ['events', gymID, date]
      const eventsForDate = updatedEvents.filter(
        (event) => event.eventDateISO === date
      )
      queryClient.setQueryData<CalendarEvent.Workout[]>(queryKey, eventsForDate)
    })
  }

  return {
    allCachedEvents,
    updateCachedEvents,
    getCachedEventsForDate,
    getCachedEventByID,
  }
}

export default useCachedCalendarEvents

export const getEventsQueryKeyForDate = (
  dateISO: string,
  gymID: string
): QueryKey => {
  const fromDate = DateTime.fromISO(dateISO).startOf('month').toISODate()
  const toDate = DateTime.fromISO(dateISO).endOf('month').toISODate()

  const dateOverride = undefined
  const enabled = true
  const eventsQueryKey: QueryKey = [
    'events',
    { gymID, fromDate, toDate, dateOverride, enabled },
  ]

  return eventsQueryKey
}
