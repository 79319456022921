import React, { CSSProperties } from 'react'
import { Workout } from '../../../types/workouts'

type IconProps = {
  color?: string
  size?: any
  style?: CSSProperties
  className?: string
}

interface WorkoutIconProps extends IconProps {
  type?: Workout.Item['workoutType']
  outline: boolean
}

export const IconWorkoutType = ({ type, size, outline }: WorkoutIconProps) => {
  switch (type) {
    case 'strength':
      return (
        <IconSquare size={size} color="var(--accent-red)" outline={outline} />
      )

    case 'conditioning':
      return (
        <IconCircle size={size} color="var(--accent-green)" outline={outline} />
      )

    case 'skill':
      return (
        <IconDiamond
          size={size}
          color="var(--accent-yellow)"
          outline={outline}
        />
      )

    case 'girls':
      return (
        <IconDiamond
          size={size}
          color="var(--accent-orange)"
          outline={outline}
        />
      )

    case 'cf-open':
      return (
        <IconDiamond size={size} color="var(--accent-blue)" outline={outline} />
      )

    case 'endurance':
    case 'gymnastics':
      return (
        <IconDiamond
          size={size}
          color="var(--accent-green)"
          outline={outline}
        />
      )

    case 'special':
      return (
        <IconSquare
          size={size}
          color="var(--accent-orange)"
          outline={outline}
        />
      )

    case 'gym-workout':
      return (
        <IconDiamond size={size} color="var(--accent-blue)" outline={outline} />
      )

    case 'heroes':
      return (
        <IconSquare size={size} color="var(--accent-blue)" outline={outline} />
      )

    default:
      return (
        <IconSquare size={size} color="var(--accent-red)" outline={outline} />
      )
  }
}

export const IconSquare = ({ color, size, outline }: WorkoutIconProps) => {
  const setColor = color ? color : '#000000'
  const setSize = size ? size : 24

  return outline ? (
    <svg
      width={setSize}
      height={setSize}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="2"
        width="6"
        height="6"
        stroke={setColor}
        fill="none"
        strokeWidth={2}
      />
    </svg>
  ) : (
    <svg
      width={setSize}
      height={setSize}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="8" height="8" fill={setColor} />
    </svg>
  )
}

export const IconCircle = ({ color, size, outline }: WorkoutIconProps) => {
  const setColor = color ? color : '#000000'
  const setSize = size ? size : 24

  return outline ? (
    <svg
      width={setSize}
      height={setSize}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="3" stroke={setColor} strokeWidth={2} />
    </svg>
  ) : (
    <svg
      width={setSize}
      height={setSize}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="4" fill={setColor} />
    </svg>
  )
}

export const IconDiamond = ({ color, size, outline }: WorkoutIconProps) => {
  const setColor = color ? color : '#000000'
  const setSize = size ? size : 24

  return outline ? (
    <svg
      width={setSize}
      height={setSize}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 1L1 6L6 11L11 6L6 1Z" stroke={setColor} strokeWidth={2} />
    </svg>
  ) : (
    <svg
      width={setSize}
      height={setSize}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 1L1 6L6 11L11 6L6 1Z" fill={setColor} />
    </svg>
  )
}

export const IconArrowDown = ({ color, style }: IconProps) => {
  return (
    <svg
      style={style}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color ? color : 'inherit'}
    >
      <path
        d="M2 4L6 8L10 4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconArrowUTurn = ({ color, style }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_3246_9249)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.39635 14.0758C2.36442 15.2432 3.8131 16 5.66449 16H15.0303C15.5826 16 16.0303 15.5523 16.0303 15C16.0303 14.4477 15.5826 14 15.0303 14H5.66449C4.40621 14 3.52263 13.5068 2.93588 12.7992C2.32984 12.0683 1.99999 11.0549 2 10C2.00001 8.94512 2.32986 7.93167 2.93591 7.20083C3.52268 6.49325 4.40627 6 5.66456 6L5.66456 4C3.81315 4 2.36447 4.75675 1.39639 5.92417C0.447587 7.06833 7.20637e-06 8.55489 0 10C-7.1466e-06 11.4451 0.447558 12.9317 1.39635 14.0758ZM9.40709 6H5.66456L5.66456 4H9.40707L7.04332 1.7197C6.64584 1.33625 6.63447 0.703189 7.01791 0.305712C7.40136 -0.0917653 8.03442 -0.103141 8.4319 0.280305L12.5783 4.2803C12.7736 4.46877 12.884 4.72855 12.884 5C12.884 5.27145 12.7736 5.53123 12.5783 5.71969L8.43194 9.71969C8.03446 10.1031 7.4014 10.0918 7.01795 9.69429C6.6345 9.29682 6.64588 8.66375 7.04335 8.28031L9.40709 6Z"
        />
      </g>
    </svg>
  )
}

export const IconArrowRight = ({ color, style }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70711 1.29289C8.31658 0.902369 7.68342 0.902369 7.29289 1.29289C6.90237 1.68342 6.90237 2.31658 7.29289 2.70711L11.5858 7H2C1.44772 7 1 7.44772 1 8C1 8.55228 1.44772 9 2 9H11.5858L7.29289 13.2929C6.90237 13.6834 6.90237 14.3166 7.29289 14.7071C7.68342 15.0976 8.31658 15.0976 8.70711 14.7071L14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289L8.70711 1.29289Z"
      />
    </svg>
  )
}

export const IconArrowLeft = ({ color, style }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 1.29289C7.68342 0.902369 8.31658 0.902369 8.70711 1.29289C9.09763 1.68342 9.09763 2.31658 8.70711 2.70711L4.41421 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H4.41421L8.70711 13.2929C9.09763 13.6834 9.09763 14.3166 8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289L7.29289 1.29289Z"
      />
    </svg>
  )
}

interface BarbellIconProps extends IconProps {
  type?: 'solid'
}

export const IconBarbell = ({
  color,
  className,
  style,
  type,
}: BarbellIconProps) => {
  switch (type) {
    case 'solid':
      return (
        <svg
          className={className}
          width="52"
          height="24"
          viewBox="0 0 52 24"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
          style={style}
        >
          <path
            d="M11 4C11 3.44772 11.4477 3 12 3H13C13.5523 3 14 3.44772 14 4V11H38V4C38 3.44772 38.4477 3 39 3H40C40.5523 3 41 3.44772 41 4V20C41 20.5523 40.5523 21 40 21H39C38.4477 21 38 20.5523 38 20V13H14V20C14 20.5523 13.5523 21 13 21H12C11.4477 21 11 20.5523 11 20V4Z"
            fill={color}
          />
          <path
            d="M6 7C6 6.44772 6.44772 6 7 6H8C8.55229 6 9 6.44772 9 7V17C9 17.5523 8.55229 18 8 18H7C6.44772 18 6 17.5523 6 17V7Z"
            fill={color}
          />
          <path
            d="M43 7C43 6.44772 43.4477 6 44 6H45C45.5523 6 46 6.44772 46 7V17C46 17.5523 45.5523 18 45 18H44C43.4477 18 43 17.5523 43 17V7Z"
            fill={color}
          />
          <path
            d="M1 10C1 9.44772 1.44772 9 2 9H3C3.55228 9 4 9.44772 4 10V14C4 14.5523 3.55228 15 3 15H2C1.44772 15 1 14.5523 1 14V10Z"
            fill={color}
          />
          <path
            d="M48 10C48 9.44772 48.4477 9 49 9H50C50.5523 9 51 9.44772 51 10V14C51 14.5523 50.5523 15 50 15H49C48.4477 15 48 14.5523 48 14V10Z"
            fill={color}
          />
        </svg>
      )
    default:
      return (
        <svg
          className={className}
          width="52"
          height="24"
          viewBox="0 0 52 24"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
          style={style}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 18V19C8 20.1046 8.89543 21 10 21H12C13.1046 21 14 20.1046 14 19V13H38V19C38 20.1046 38.8954 21 40 21H42C43.1046 21 44 20.1046 44 19V18H46C47.1046 18 48 17.1046 48 16V15H50C51.1046 15 52 14.1046 52 13V11C52 9.89543 51.1046 9 50 9H48V8C48 6.89543 47.1046 6 46 6H44V5C44 3.89543 43.1046 3 42 3H40C38.8954 3 38 3.89543 38 5V11H14V5C14 3.89543 13.1046 3 12 3H10C8.89543 3 8 3.89543 8 5V6H6C4.89543 6 4 6.89543 4 8V9H2C0.895431 9 0 9.89543 0 11V13C0 14.1046 0.89543 15 2 15H4V16C4 17.1046 4.89543 18 6 18H8ZM12 5H10V19H12V5ZM4 13V11H2L2 13H4ZM6 16H8V8H6V16ZM42 5H40V19H42V5ZM46 16H44V8H46V16ZM50 13H48V11H50V13Z"
            fill=""
          />
        </svg>
      )
  }
}

export const IconKey = ({ keyName }: { keyName: 'Enter' }) => {
  return (
    <div
      style={{
        float: 'left',
        padding: '1px 4px',
        borderRadius: '4px',
        fontSize: '12px',
        border: '1px solid var(--divider)',
        borderBottomWidth: '2px',
        position: 'relative',
        top: '-1px',
      }}
    >
      Esc
    </div>
  )
}

export const IconNewWorkout = ({
  type,
  color,
}: {
  type: 'single' | 'superset'
  color: string
}) => {
  switch (type) {
    case 'single':
      return (
        <svg
          width="88"
          height="85"
          viewBox="0 0 88 85"
          style={{ fill: color }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="16"
            y="19"
            width="40"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="16"
            y="56"
            width="54"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <circle
            cx="19.5"
            cy="37.5"
            r="4.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="30"
            y="36"
            width="39"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="16"
            y="63"
            width="34"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="1.5"
            y="1.5"
            width="85"
            height="82"
            rx="6.5"
            stroke={color}
            style={{ fill: 'none' }}
            strokeWidth="3"
          />
        </svg>
      )

    case 'superset':
      return (
        <svg
          width="88"
          height="114"
          viewBox="0 0 88 114"
          style={{ fill: 'none' }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="16"
            y="26"
            width="40"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="16"
            y="19"
            width="54"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <circle
            cx="19.5"
            cy="44.5"
            r="4.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <circle
            cx="19.5"
            cy="60.5"
            r="4.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <circle
            cx="19.5"
            cy="76.5"
            r="4.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="30"
            y="59"
            width="39"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="30"
            y="43"
            width="39"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="16"
            y="92"
            width="34"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="30"
            y="75"
            width="39"
            height="3"
            rx="1.5"
            fill={color}
            style={{ stroke: 'none' }}
          />
          <rect
            x="1.5"
            y="1.5"
            width="85"
            height="111"
            rx="6.5"
            stroke={color}
            style={{ fill: 'none' }}
            strokeWidth="3"
          />
        </svg>
      )
  }
}

export const IconTV = ({ color, size }: { color: string; size: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H20C21.1046 17 22 16.1046 22 15V5C22 3.89543 21.1046 3 20 3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 21H16"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconCollapse = ({ color = 'white' }: { color: string }) => {
  return (
    <svg
      style={{ margin: 0 }}
      width="12"
      height="12"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5607_10241)">
        <path
          d="M0 1C0 0.447715 0.447715 0 1 0H6C6.55228 0 7 0.447715 7 1V3C7 3.55228 6.55228 4 6 4H1C0.447715 4 0 3.55228 0 3V1Z"
          fill={color}
        />
        <path
          d="M0 7C0 6.44772 0.447715 6 1 6H6C6.55228 6 7 6.44772 7 7V9C7 9.55228 6.55228 10 6 10H1C0.447715 10 0 9.55228 0 9V7Z"
          fill={color}
        />
        <path
          d="M0 13C0 12.4477 0.447715 12 1 12H6C6.55228 12 7 12.4477 7 13V15C7 15.5523 6.55228 16 6 16H1C0.447715 16 0 15.5523 0 15V13Z"
          fill={color}
        />
        <path
          d="M9 1C9 0.447715 9.44772 0 10 0H15C15.5523 0 16 0.447715 16 1V3C16 3.55228 15.5523 4 15 4H10C9.44772 4 9 3.55228 9 3V1Z"
          fill={color}
        />
        <path
          d="M9 7C9 6.44772 9.44772 6 10 6H15C15.5523 6 16 6.44772 16 7V9C16 9.55228 15.5523 10 15 10H10C9.44772 10 9 9.55228 9 9V7Z"
          fill={color}
        />
        <path
          d="M9 13C9 12.4477 9.44772 12 10 12H15C15.5523 12 16 12.4477 16 13V15C16 15.5523 15.5523 16 15 16H10C9.44772 16 9 15.5523 9 15V13Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5607_10241">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconCycle = ({ color, size }: { color: string; size: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3H13C15.2091 3 17 4.79086 17 7V7C17 9.20914 15.2091 11 13 11H8C5.79086 11 4 12.7909 4 15V15C4 17.2091 5.79086 19 8 19H21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18 15L22 19L18 23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconExpand = ({ color = 'white' }: { color: string }) => {
  return (
    <svg
      style={{ margin: 0 }}
      width="12"
      height="12"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5607_10249)">
        <path
          d="M0 1C0 0.447715 0.447715 0 1 0H6C6.55228 0 7 0.447715 7 1V6C7 6.55228 6.55228 7 6 7H1C0.447715 7 0 6.55228 0 6V1Z"
          fill={color}
        />
        <path
          d="M0 10C0 9.44772 0.447715 9 1 9H6C6.55228 9 7 9.44772 7 10V15C7 15.5523 6.55228 16 6 16H1C0.447715 16 0 15.5523 0 15V10Z"
          fill={color}
        />
        <path
          d="M9 1C9 0.447715 9.44772 0 10 0H15C15.5523 0 16 0.447715 16 1V6C16 6.55228 15.5523 7 15 7H10C9.44772 7 9 6.55228 9 6V1Z"
          fill={color}
        />
        <path
          d="M9 10C9 9.44772 9.44772 9 10 9H15C15.5523 9 16 9.44772 16 10V15C16 15.5523 15.5523 16 15 16H10C9.44772 16 9 15.5523 9 15V10Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5607_10249">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
