import css from './ExerciseInput.module.css'
import { X as IconMinusCircle, AlignJustify as IconDrag } from 'react-feather'
import { useSortable } from '@dnd-kit/sortable'
import { CSS as DNDCSS } from '@dnd-kit/utilities'
import MoveInput from '../MoveInput/MoveInput'
import { useFormContext } from 'react-hook-form'
import { Tooltip } from 'react-tooltip'

type Props = {
  mode: 'superset' | 'single'
  parentFieldName: string
  isDragOverlay?: boolean
  sortingInProgress?: boolean
  order: number
  onRemove: () => void
  onKeyUp: (
    type: 'reps' | 'effort' | 'movement',
    order: number,
    key: string
  ) => void
  packPosition: 'first' | 'middle' | 'last' | 'lonely'
  id: string
  canSort: boolean
}

const ExerciseInput = ({
  order,
  mode,
  id,
  isDragOverlay,
  sortingInProgress,
  onRemove,
  onKeyUp,
  packPosition,
  canSort,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id })

  const style = {
    transform: DNDCSS.Transform.toString(transform),
    transition,
  }

  const { register } = useFormContext()

  return (
    <div
      className={`
        ${css['everything']} 
        ${css[packPosition]}
        ${isDragOverlay ? css['drag-overlay'] : ''} 
        ${isDragging ? css['hide'] : ''} 
        ${sortingInProgress ? css['sorting'] : ''}
      `}
      ref={setNodeRef}
      style={style}
      {...attributes}
      tabIndex={-1}
    >
      {['first', 'lonely'].includes(packPosition) && (
        <div className={css['labels']}>
          <label style={{ float: 'left' }}>
            {packPosition === 'lonely' ? 'Movement' : 'Movements'}
          </label>
          <div style={{ float: 'right' }}>
            {mode === 'superset' && (
              <label
                style={{
                  float: 'left',
                  width: '140px',
                  textAlign: 'center',
                }}
              >
                Reps
              </label>
            )}
            <label style={{ float: 'left' }}>Effort or Load</label>
          </div>
        </div>
      )}
      <div className={`${css['inputs-and-icon-container']}`}>
        {canSort && (
          <div
            className={css['drag-icon']}
            data-tooltip-id="t-drag-hint"
            data-tooltip-content="Drag to reorder"
            {...listeners}
          >
            <IconDrag size={20} />
          </div>
        )}
        <div className={`${css['inputs-container']}`}>
          <MoveInput
            className={mode === 'superset' ? 'superset' : ''}
            placeholder="Movement"
            order={order}
            onKeyUp={onKeyUp}
            packPosition={packPosition}
          />
          {mode === 'superset' && (
            <input
              placeholder="Reps"
              className={css['reps-input']}
              onKeyUp={(e) => onKeyUp('reps', order, e.key)}
              {...register(`superset.${order}.reps`)}
            />
          )}
          <input
            className={css['effort-input']}
            placeholder="Effort"
            {...register(`superset.${order}.effort`)}
            onKeyUp={(e) => onKeyUp('effort', order, e.key)}
          />
        </div>

        <div onClick={onRemove} className={css['icon-container']}>
          <IconMinusCircle size={16} className={css['icon']} />
        </div>
      </div>

      <div className={css['overlay']} />
    </div>
  )
}

export default ExerciseInput
