import React, { useContext, useEffect, useState } from 'react'

import css from './Pricing.module.css'
import Button from '../../buttons/Button/Button'
import { IconArrowRight } from '../../atoms/Icons/Icons'
import SegmentedInput from '../../inputs/SegmentedInput/SegmentedInput'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebase'
import { UserContext } from '../../../context/UserContext'
import { AppContext } from '../../../context/AppContext'
import { goToCheckout } from '../../../services/goToCheckout'
import API from '../../../services'
import { Info } from 'react-feather'
import Confirm from '../../modals/Confirm'
import ActiveMember from '../../modals/ActiveMember'
import { Analytics } from '../../../services/analytics'
import { useURLParams } from '../../../hooks/useURLParams'

type Props = {
  onComplete: () => void
  isVisible?: boolean
  reSubscribing?: boolean
}

type PriceOption = 'monthly' | 'annually'

type PriceTable = {
  first_monthly: number
  first_yearly: number
  first_extra: number
  second_monthly: number
  second_yearly: number
  second_extra: number
  third_monthly: number
  third_yearly: number
  third_extra: number
} | null

const Pricing = ({ onComplete, isVisible = true, reSubscribing }: Props) => {
  const { setSearchParams } = useURLParams()
  const { currentSpace } = useContext(AppContext)
  const [priceOption, setPriceOption] = useState<PriceOption>('annually')
  const [isLoading, setLoading] = useState([false, false, false])
  const [prices, setPrices] = useState<PriceTable>(null)

  useEffect(() => {
    if (isVisible) {
      setSearchParams({ page: 'choose_price' })
    }

    getPrices()
  }, [])

  const getPrices = async () => {
    fetch(API.url + 'getPrices')
      .then(
        (response) => response.json(),
        (err) => {
          console.error('api error', err)
        }
      )
      .then((res) => {
        setPrices(res)
        console.log('res->', res)
        setLoading([false, false, false])
      })
  }

  const checkout = async (
    option:
      | 'first_monthly'
      | 'first_yearly'
      | 'second_monthly'
      | 'second_yearly'
      | 'third_monthly'
      | 'third_yearly'
  ) => {
    Analytics._logEvent({
      name: 'auth_pricing_plan_selected',
      params: { details: `plan selected: ${option}` },
    })

    if (option.match('first')) setLoading([true, false, false])
    else if (option.match('second')) setLoading([false, true, false])
    else setLoading([false, false, true])

    console.log('option', option)

    if (currentSpace) goToCheckout(option, currentSpace.id)
  }

  const [activeMemberInfoVisible, setActiveMemberInfoVisible] = useState(false)

  return currentSpace ? (
    <>
      <ActiveMember
        isVisible={activeMemberInfoVisible}
        onDismiss={() => setActiveMemberInfoVisible(false)}
      />
      <div className={`${css['pricing']} ${reSubscribing && css['resub']}`}>
        <h1>
          {currentSpace?.title
            ? `Choose a Plan for ${currentSpace?.title}`
            : 'Choose Your Plan'}
        </h1>
        {!reSubscribing && (
          <h3 style={{ textAlign: 'center', margin: '0 0 0 0' }}>
            Starts with 14-day free trial
          </h3>
        )}
        <div className={css['segment-input']}>
          <SegmentedInput
            segments={[
              { label: 'Pay Annually', value: 'annually' },
              { label: 'Pay Monthly', value: 'monthly' },
            ]}
            value={priceOption}
            dark={!reSubscribing}
            onChange={(newValue) => setPriceOption(newValue as PriceOption)}
            style={{ height: '40px' }}
          />
        </div>

        <div className={css['pricing-options-container']}>
          <div className={css['pricing-options']}>
            <div className={`${css['pricing-plan']} ${css['left']}`}>
              <div className={css['padding']}>
                <span>Up to</span>
                <h2>
                  5 active members<span>*</span>
                </h2>
                <p>Best for private coaches or home gyms</p>
              </div>
              <div>
                {prices && (
                  <div className={css['price']}>
                    <div
                      className={`${css['save']} ${
                        priceOption === 'annually' && css['visible']
                      }`}
                    >
                      Save $
                      {Math.floor(
                        12 * prices.first_monthly - prices.first_yearly
                      ).toFixed(2)}{' '}
                    </div>

                    <h1>
                      <>
                        <span>$ </span>
                        {priceOption === 'annually'
                          ? (prices.first_yearly / 12).toFixed(2)
                          : prices.first_monthly.toFixed(2)}
                        <span> /mo</span>
                      </>
                    </h1>

                    <p
                      className={`${css['sub-price']} ${
                        priceOption === 'annually' && css['visible']
                      }`}
                    >
                      {prices &&
                        `$ ${prices.first_yearly.toFixed(2)} paid annually`}
                    </p>
                    <p className={`${css['sub-price']} ${css['visible']}`}>
                      {prices &&
                        `$ ${prices.first_extra.toFixed(2)} per extra member*`}
                    </p>
                  </div>
                )}

                <Button
                  onClick={() =>
                    checkout(
                      priceOption === 'annually'
                        ? 'first_yearly'
                        : 'first_monthly'
                    )
                  }
                  isLoading={isLoading[0]}
                >
                  {!reSubscribing ? 'Try for Free' : 'Continue'}
                </Button>
              </div>
            </div>

            <div className={`${css['pricing-plan']} ${css['middle']}`}>
              <div className={css['padding']}>
                <div className={css['highlight']}>Recommended</div>
                <span>Up to</span>
                <h2>
                  100 active members<span>*</span>
                </h2>

                <p>
                  Optimal plan
                  <br />
                  for most gyms
                </p>
              </div>
              <div>
                {prices && (
                  <div className={css['price']}>
                    <div
                      className={`${css['save']} ${
                        priceOption === 'annually' && css['visible']
                      }`}
                    >
                      Save $
                      {Math.floor(
                        12 * prices.second_monthly - prices.second_yearly
                      ).toFixed(2)}{' '}
                    </div>

                    <h1>
                      <>
                        <span>$ </span>
                        {priceOption === 'annually'
                          ? (prices.second_yearly / 12).toFixed(2)
                          : prices.second_monthly.toFixed(2)}
                        <span> /mo</span>
                      </>
                    </h1>

                    <p
                      className={`${css['sub-price']} ${
                        priceOption === 'annually' && css['visible']
                      }`}
                    >
                      {prices &&
                        `$ ${prices.second_yearly.toFixed(2)} paid annually`}
                    </p>
                    <p className={`${css['sub-price']} ${css['visible']}`}>
                      {prices &&
                        `$ ${prices.second_extra.toFixed(2)} per extra member*`}
                    </p>
                  </div>
                )}

                <Button
                  onClick={() =>
                    checkout(
                      priceOption === 'annually'
                        ? 'second_yearly'
                        : 'second_monthly'
                    )
                  }
                  isLoading={isLoading[1]}
                >
                  {!reSubscribing ? 'Try for Free' : 'Continue'}
                </Button>
              </div>
            </div>

            <div className={`${css['pricing-plan']} ${css['right']}`}>
              <div className={css['padding']}>
                <span>Up to</span>
                <h2>
                  300 active members<span>*</span>
                </h2>
                <p>
                  Best value
                  <br />
                  for larger gyms
                </p>
              </div>
              <div>
                {prices && (
                  <div className={css['price']}>
                    <div
                      className={`${css['save']} ${
                        priceOption === 'annually' && css['visible']
                      }`}
                    >
                      Save $
                      {Math.floor(
                        12 * prices.third_monthly - prices.third_yearly
                      ).toFixed(2)}{' '}
                    </div>

                    <h1>
                      <>
                        <span>$ </span>
                        {priceOption === 'annually'
                          ? (prices.third_yearly / 12).toFixed(2)
                          : prices.third_monthly.toFixed(2)}
                        <span> /mo</span>
                      </>
                    </h1>

                    <p
                      className={`${css['sub-price']} ${
                        priceOption === 'annually' && css['visible']
                      }`}
                    >
                      {prices &&
                        `$ ${prices.third_yearly.toFixed(2)} paid annually`}
                    </p>
                    <p className={`${css['sub-price']} ${css['visible']}`}>
                      {prices &&
                        `$ ${prices.third_extra.toFixed(2)} per extra member*`}
                    </p>
                  </div>
                )}

                <Button
                  onClick={() =>
                    checkout(
                      priceOption === 'annually'
                        ? 'third_yearly'
                        : 'third_monthly'
                    )
                  }
                  isLoading={isLoading[2]}
                >
                  {!reSubscribing ? 'Try for Free' : 'Continue'}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={css['extra']}>
          <div onClick={() => setActiveMemberInfoVisible(true)}>
            <Info color={'var(--accent)'} />
            <h3>*Who is considered an active member?</h3>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>Error: No active gym</>
  )
}
export default Pricing
