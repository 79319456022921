import { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import { firestore } from '../../firebase'
import { collection, query, where } from 'firebase/firestore'
import { useFirestoreQueryData } from '@react-query-firebase/firestore'
import { Cycle } from '../../types/cycles'
import { DateTime } from 'luxon'

const useCycles = () => {
  const { currentSpace } = useContext(AppContext)

  const gymID = currentSpace?.id || 'no-id'

  const cyclesRef = query(
    collection(firestore, `spaces/${gymID}/cycles`),
    where('isDeleted', '==', false)
  )
  const queryKey = ['cycles', gymID]

  const cyclesRaw = useFirestoreQueryData(
    queryKey,
    cyclesRef,
    {
      subscribe: true,
    },
    {
      enabled: gymID !== 'no-id',
    }
  )

  /**
   *
   * @param dateToCheck format 'yyyy-MM-dd'
   * @returns
   */
  const checkDateForCycle = (dateToCheck: string) => {
    if (cyclesRaw.data === undefined) return null
    else {
      const result = (cyclesRaw.data as Cycle[]).map((cycle) => {
        const startDate = cycle.dateRange.startDate.seconds
        const endDate = cycle.dateRange.endDate.seconds

        const dateToCheckUnix = DateTime.fromISO(dateToCheck).toUnixInteger()

        if (dateToCheckUnix >= startDate && dateToCheckUnix <= endDate) {
          return {
            isStart: startDate === dateToCheckUnix,
            isEnd: endDate === dateToCheckUnix,
            color: cycle.calendarColor,
            title: cycle.title,
          }
        } else {
          return null
        }
      })

      return result.find((item) => item !== null)
    }
  }

  const activeCycle = cyclesRaw.data?.find((cycle): cycle is Cycle => {
    const startDate = DateTime.fromSeconds(
      cycle.dateRange.startDate.seconds
    ).toUnixInteger()

    const endDate = DateTime.fromSeconds(
      cycle.dateRange.endDate.seconds
    ).toUnixInteger()
    const today = DateTime.now().toUnixInteger()
    return today >= startDate && today <= endDate
  })

  const cycles =
    cyclesRaw.data && cyclesRaw.data.length ? (cyclesRaw.data as Cycle[]) : null

  return {
    cycles: cycles,
    cyclesQueryKey: queryKey,
    activeCycle: activeCycle || null,
    pastCycles:
      (cyclesRaw.data?.filter((cycle) => {
        const endDate = cycle.dateRange.endDate.seconds
        return endDate <= DateTime.now().toUnixInteger()
      }) as Cycle[]) || null,

    futureCycles:
      (cyclesRaw.data?.filter((cycle) => {
        const startDate = cycle.dateRange.startDate.seconds

        const compareDate = activeCycle
          ? activeCycle?.dateRange.startDate.seconds
          : DateTime.now().toUnixInteger()
        return startDate > compareDate
      }) as Cycle[]) || null,
    checkDateForCycle,
    cyclesAreLoading: cyclesRaw.isLoading,
  }
}
export default useCycles
