import React, { useContext, useState } from 'react'
import css from './MainNavigation.module.css'
import Logo from '../../atoms/Logo/Logo'

import { BrowserRouter, Route, NavLink } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext'
import { AppContext } from '../../../context/AppContext'
import { IconArrowDown } from '../../atoms/Icons/Icons'
import { signOut } from '@firebase/auth'
import GymSelector from '../../atoms/GymSelector/GymSelector'

type Props = {
  limitedAccess?: boolean
}

const MainNavigation = ({ limitedAccess }: Props) => {
  const { user, userID } = useContext(UserContext)
  const { currentSpace } = useContext(AppContext)

  return (
    <div id={css['mainnav-container']}>
      <>
        <Logo type="small" />
        <nav>
          <div className={css['left']}>
            <GymSelector />
            {!limitedAccess && (
              <>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? css['active'] : '')}
                >
                  <span>Calendar</span>
                </NavLink>
                <NavLink
                  to="/playbook"
                  className={({ isActive }) => (isActive ? css['active'] : '')}
                >
                  <span>Playbook</span>
                </NavLink>
                <NavLink
                  to="/members"
                  className={({ isActive }) => (isActive ? css['active'] : '')}
                >
                  <span>Members</span>
                </NavLink>
              </>
            )}
          </div>
          <div className={css['right']}>
            {(currentSpace?.ownerID === userID ||
              currentSpace?.role === 'support') && (
              <NavLink
                to="/gym-settings"
                className={({ isActive }) => (isActive ? css['active'] : '')}
              >
                <span>Gym Settings</span>
              </NavLink>
            )}
            <NavLink
              to="/account"
              className={({ isActive }) => (isActive ? css['active'] : '')}
            >
              <span>{user.name}</span>
            </NavLink>
          </div>
        </nav>
      </>
    </div>
  )
}

export default MainNavigation
