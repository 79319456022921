import pluralize from 'pluralize'

export const rewodPluralize = (string: string) => {
  const str = string.toLowerCase()

  // Full matched exceptions
  const exceptions = [
    'run',
    'run',
    'treadmill',
    'sandbag run',
    'bear crawl',
    'row',
    'stairmaster',
    'calorie row',
    'row',
    'row, stationary',
  ]

  // Partial match exceptions
  if (
    exceptions.includes(str) ||
    str.includes('plank') ||
    str.includes('hold') ||
    (str.includes('hang') && !str.includes('ing') && !str.includes('clean')) ||
    (str.includes('walk') &&
      !str.includes('walking') &&
      !str.includes('wall')) ||
    str.includes('bike') ||
    str.includes('ski') ||
    str.includes('carry') ||
    str.includes('swim') ||
    str.includes('sled pull') ||
    str.includes('sprint')
  )
    return string
  // Special cases
  else
    switch (str) {
      case 'superman':
        return 'Supermans'
      case 'lateral burpee over the bar':
        return 'Lateral Burpees Over the Bar'
      case 'Burpee over the Bar':
        return 'Burpees Over the bar'
      default:
        return pluralize(string)
    }
}
