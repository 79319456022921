import React, { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import {
  collection,
  query,
  where,
  CollectionReference,
} from 'firebase/firestore'
import { firestore } from '../../firebase'
import { useFirestoreQueryData } from '@react-query-firebase/firestore'
import { CalendarEvent } from '../../types/calendarEvents'
import getAdjustedDemoDate from '../../utilities/getAdjustedDemoDate'

type Props = {
  dateISO?: string
  enabled?: boolean
}

const useCalendarEvents = ({ dateISO, enabled = true }: Props) => {
  const { currentSpace } = useContext(AppContext)
  const gymID = currentSpace?.id ?? 'no-id'

  const isDemo = process.env.REACT_APP_DEMO_GYM_ID === gymID
  const date = isDemo ? getAdjustedDemoDate(dateISO) : dateISO

  const ref = dateISO
    ? query<CalendarEvent.Workout>(
        collection(
          firestore,
          'spaces',
          gymID,
          'calendar_events'
        ) as CollectionReference<CalendarEvent.Workout>,
        where('eventDateISO', '==', date),
        where('isDeleted', '==', false)
      )
    : undefined

  const queryKey = ['events', gymID, date]

  const result = useFirestoreQueryData<CalendarEvent.Workout>(
    queryKey,
    ref,
    { subscribe: false },
    {
      enabled: gymID !== 'no-id' && enabled && !!dateISO,
      staleTime: 1000 * 60 * 10, // 10 minutes
    }
  )

  if (dateISO === '2023-06-02') console.log('result.data', result.data)

  return {
    ...result,
    data: result.data?.sort((a, b) => a.index - b.index) || [],
  }
}

export default useCalendarEvents
