import { v4 as uuid } from 'uuid'
import { Workout } from '../../types/workouts'
import { Timestamp } from 'firebase/firestore'
const getNewWorkout = (
  uid: string,
  type?: 'single' | 'superset'
): Workout.Item => {
  const workoutID = 'wk_' + uuid().slice(0, 8)

  const defaultValues: Workout.Item = {
    id: workoutID,
    playbookReferenceID: null,
    benchmarkID: null,
    title: '',
    workoutType: 'strength',
    set: {
      id: 'set_' + uuid().slice(0, 8),
      parentWorkoutID: workoutID,
      scaling: {
        ageGroup: 'masters',
        genderGroup: 'women',
        effort: 'rx',
      },
    },
    superset: [
      {
        itemType: 'exercise',
        movement: { name: '', id: uuid().slice(0, 8) },
        effort: '',
        reps: '',
        id: 'ex_' + uuid().slice(0, 8),
      },
    ],
    repetitionPattern: {
      reps: [],
      dashReps: false,
      entryValue: '',
    },
    roundRestTime: {
      work: { min: 0, sec: 0 },
      rest: { min: 0, sec: 0 },
      entryValue: '',
    },
    rounds: 1,
    isSuperset: false,
    tracking: 'reps-weight',
    index: 0,
    createdBy: uid,
    createdDate: Timestamp.now(),
    updatedBy: uid,
    updatedDate: Timestamp.now(),
    timeCap: undefined,
    // isPublished: false,
    isPredefined: false,
    hideAutomaticDescription: false,
  }

  if (type === 'single') {
    return defaultValues
  } else {
    // superset
    const value: Workout.Item = {
      ...defaultValues,
      workoutType: 'conditioning',
      superset: [
        {
          itemType: 'exercise',
          movement: { name: '', id: uuid().slice(0, 8) },
          effort: '',
          reps: '',
          id: 'ex_' + uuid().slice(0, 8),
        },
        {
          itemType: 'exercise',
          movement: { name: '', id: uuid().slice(0, 8) },
          effort: '',
          reps: '',
          id: 'ex_' + uuid().slice(0, 8),
        },
        {
          itemType: 'exercise',
          movement: { name: '', id: uuid().slice(0, 8) },
          effort: '',
          reps: '',
          id: 'ex_' + uuid().slice(0, 8),
        },
      ],
    }
    return value
  }
}

export default getNewWorkout
