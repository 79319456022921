import { CSSProperties } from 'react'
import css from './LoadingSkeleton.module.css'

interface Props {
  type: 'big name' | 'cards' | 'long thing' | 'short thing' | 'single card'
  style?: CSSProperties
}

const LoadingSkeleton = ({ type, style }: Props) => {
  switch (type) {
    case 'single card':
      return (
        <div style={style}>
          <div
            className={css['card']}
            style={{ height: `${Math.random() * 50 + 300}px`, margin: '0' }}
          />
        </div>
      )
    case 'big name':
      return (
        <div style={style}>
          <div
            className={css['card']}
            style={{ height: '20px', margin: '0' }}
          />
          <div
            className={css['card']}
            style={{ height: '20px', margin: '16px 0', width: '100%' }}
          />
        </div>
      )
    case 'cards':
      return (
        <div className={css['loading-cards']} style={style}>
          <div className={css['card']} style={{ height: '350px' }} />
          <div className={css['card']} />
        </div>
      )
    case 'long thing':
      return (
        <div className={css['loading-cards']} style={style}>
          <div
            className={css['card']}
            style={{ height: '100vh', borderRadius: '20px' }}
          />
        </div>
      )
    default:
      return (
        <div className={css['loading-cards']} style={style}>
          <div className={css['card']} style={{ height: '400px' }} />
        </div>
      )
  }

  // return !isLong && !isSingle ? (
  //   <div className={css['loading-cards']}>
  //     <div className={css['card']} style={{ height: '350px' }} />
  //     <div className={css['card']} />
  //   </div>
  // ) : isSingle ? (
  //   <div className={css['loading-cards']}>
  //     <div className={css['card']} style={{ height: '400px' }} />
  //   </div>
  // ) : (
  //   <div className={css['loading-cards']}>
  //     <div
  //       className={css['card']}
  //       style={{ height: '100vh', borderRadius: '10px' }}
  //     />
  //   </div>
  // )
}

export default LoadingSkeleton
