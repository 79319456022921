import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
  UIEventHandler,
  useContext,
} from 'react'
import css from './WorkoutBuilder.module.css'
import { v4 as uuid } from 'uuid'
import Button, { ButtonType } from '../../buttons/Button/Button'
import SupersetInput from '../../inputs/SupersetInput/SupersetInput'
import TitleInput from '../../inputs/TitleInput/TitleInput'
import { X as IconClose, Plus } from 'react-feather'
import RepsInput from '../../inputs/RepsInput/RepsInput'
import RoundsInput from '../../inputs/RoundsInput/RoundsInput'
import SegmentedInput from '../../inputs/SegmentedInput/SegmentedInput'
import { IconWorkoutType } from '../../atoms/Icons/Icons'
import ButtonIcon from '../../buttons/ButtonIcon/ButtonIcon'
import useWorkoutBuilder from '../../../hooks/useWorkoutBuilder'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import TimeInput from '../../inputs/TimeInput/TimeInput'
import TimeCapInput from '../../inputs/TimeCapInput/TimeCapInput'
import Confirm from '../../modals/Confirm'
import CalendarContext from '../../../context/CalendarContext'
import DoubleTimeInput from '../../inputs/DoubleTimeInput/DoubleTimeInput'
import { Workout } from '../../../types/workouts'
import { Timestamp, collection, doc, getDoc } from 'firebase/firestore'
import { UserContext } from '../../../context/UserContext'
import { ScalingOptionInput } from '../../inputs/ScalingOptionInput/ScalingOptionInput'
import { set } from 'lodash'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import { act } from 'react-dom/test-utils'
import { config } from '../../../config'
import { RewodLegacyEvent } from '../../../types/legacyEvents'
import { CalendarEvent } from '../../../types/calendarEvents'

type Props = {
  index: number
  workoutEventToEdit: CalendarEvent.Workout | null
  workoutToEdit?: Workout.Item | null
  newWorkoutType?: 'single' | 'superset'
  dateSubheader?: ReactNode
  onDismiss: () => void
  activeDate: string | null
  isPresetMode?: boolean
}

const WorkoutBuilder = ({
  index,
  workoutToEdit,
  workoutEventToEdit,
  dateSubheader,
  activeDate,
  newWorkoutType,
  isPresetMode,
  onDismiss,
}: Props) => {
  const { userID } = useContext(UserContext)

  const {
    activeScalingOption,
    formMethods,
    isValid,
    isDirty,
    isSuperset,
    values,
    publishWorkout,
    saveWorkout,
    unpublishWorkout,
    handleDelete,
    setItems,
    handleScalingOptionChange,
    addScalingOption,
    setItemsAreLoading,
  } = useWorkoutBuilder({
    index,
    workoutEventToEdit,
    workoutToEdit: workoutEventToEdit?.details,
    newWorkoutType,
    activeDate,
    onDismiss,
    isPresetMode,
  })

  const [scrollShadowIsVisible, setScrollShadowIsVisible] = useState(false)

  const handleBuilderScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = (e.target as HTMLElement).scrollTop
    if (!scrollShadowIsVisible && scrollTop > 10) setScrollShadowIsVisible(true)
    else if (scrollShadowIsVisible && scrollTop <= 10)
      setScrollShadowIsVisible(false)
  }

  const [confirmation, setConfirmation] = useState<{
    text: string
    what: string
  } | null>(null)

  const {
    pendingActiveDate,
    preventActiveDateChange,
    proceedToPendingDate,
    setPreventActiveDateChange,
  } = useContext(CalendarContext)

  useEffect(() => {
    if (pendingActiveDate && preventActiveDateChange) {
      setConfirmation({
        text: 'Proceed without saving changes?',
        what: 'switch-date',
      })
    }
  }, [pendingActiveDate])

  const handleDeleteConfirmation = () => {
    const workoutID = workoutEventToEdit?.details?.id
    if (workoutID) handleDelete(workoutID)
    setConfirmation(null)
  }

  const handleCloseConfirmation = () => {
    onDismiss()
    setConfirmation(null)
    setPreventActiveDateChange(false)
  }

  useEffect(() => {
    if (newWorkoutType === 'superset')
      formMethods.setFocus('superset.0.movement.name')
  }, [])

  const scalingModeExists =
    !setItems[0].set ||
    !!setItems.find(
      (item) =>
        item.set?.scaling.ageGroup === activeScalingOption.ageGroup &&
        item.set.scaling.effort === activeScalingOption.effort &&
        item.set.scaling.genderGroup === activeScalingOption.genderGroup
    )

  const isAddNewWorkoutEnabled =
    config.access.playbook_add_new_workout?.includes(userID)

  const getButtonActions = (): BuilderButton[] | undefined => {
    if (!isPresetMode) {
      // Creating a new workout
      if (!workoutEventToEdit) {
        return [
          {
            label: 'Publish Workout',
            onClick: publishWorkout,
            type: 'primary',
          },
          { label: 'Save Draft', onClick: saveWorkout, type: 'secondary' },
        ]
      }

      // Editing an existing workout
      if (workoutEventToEdit) {
        if (!workoutEventToEdit.isPublished) {
          return [
            {
              label: 'Publish Workout',
              onClick: publishWorkout,
              type: 'primary',
            },
            { label: 'Save Draft', onClick: publishWorkout, type: 'secondary' },
            {
              label: 'Delete',
              onClick: () =>
                setConfirmation({ text: 'Delete Workout?', what: 'delete' }),
              type: 'tertiary-destructive',
            },
          ]
        } else {
          return [
            { label: 'Save Workout', onClick: publishWorkout, type: 'primary' },
            {
              label: 'Unpublish Workout',
              onClick: unpublishWorkout,
              type: 'secondary',
            },
            {
              label: 'Delete',
              onClick: () =>
                setConfirmation({ text: 'Delete Workout?', what: 'delete' }),
              type: 'tertiary-destructive',
            },
          ]
        }
      }
    }
    // Creating a new preset workout
    else if (!workoutToEdit) {
      return [
        { label: 'Save Workout', onClick: publishWorkout, type: 'primary' },
        {
          label: 'Delete',
          onClick: () =>
            setConfirmation({ text: 'Delete Workout?', what: 'delete' }),
          type: 'tertiary-destructive',
        },
      ]
    }
  }
  return (
    <FormProvider {...formMethods}>
      <Confirm
        isVisible={!!confirmation}
        text={confirmation ? confirmation.text : ''}
        buttonLabels={
          confirmation && confirmation.what === 'delete'
            ? { primary: 'Delete', secondary: 'Go Back' }
            : { primary: 'Proceed', secondary: 'Go Back' }
        }
        onDismiss={() => setConfirmation(null)}
        onConfirm={() => {
          if (confirmation)
            switch (confirmation.what) {
              case 'delete':
                return handleDeleteConfirmation()
              case 'close':
                return handleCloseConfirmation()
              case 'switch-date': {
                setPreventActiveDateChange(false)
                proceedToPendingDate()
                handleCloseConfirmation()
              }
            }
        }}
        isDestructive={confirmation?.what === 'delete'}
      />
      <header className={css['header']}>
        <div className={`${css['subheader']} ${'light'}`}>
          {!isPresetMode
            ? dateSubheader
            : workoutEventToEdit
            ? 'Edit Workout'
            : 'New Workout'}
        </div>
        <TitleInput
          editWorkoutMode={!!workoutEventToEdit}
          defaultTitle={isPresetMode ? 'Title' : ''}
          isBenchmarkEditMode={!!isPresetMode}
        />
        <div className={css['close-icon']}>
          <ButtonIcon
            icon={<IconClose />}
            className={css['close-icon']}
            onClick={() =>
              isDirty
                ? setConfirmation({
                    text: 'Proceed without saving changes?',
                    what: 'close',
                  })
                : onDismiss()
            }
            type={'light'}
            style={{ margin: '-4px 0 0 0' }}
          />
        </div>
        {isAddNewWorkoutEnabled && isPresetMode && (
          <ScalingOptionInput
            onChange={handleScalingOptionChange}
            setItems={setItems}
            value={activeScalingOption}
            style={{ margin: '16px 0 0 0' }}
            isLoading={setItemsAreLoading}
          />
        )}
        <div
          className={`${css['scroll-shadow']} ${
            scrollShadowIsVisible && css['visible']
          }`}
        />
      </header>
      {scalingModeExists ? (
        <div id={css['workout-builder']} onScroll={handleBuilderScroll}>
          <div className={css['top-items']}>
            {isPresetMode ? (
              <select {...formMethods.register('workoutType')}>
                <option>strength</option>
                <option>conditioning</option>
                <option>skill</option>
                <option>girls</option>
                <option>heroes</option>
                <option>special</option>
                <option>endurance</option>
                <option>cf-open</option>
                <option>gymnastics</option>
              </select>
            ) : (
              <Controller
                name="workoutType"
                control={formMethods.control}
                render={({ field }) => (
                  <SegmentedInput
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    style={{ margin: '4px 0 ' }}
                    segments={[
                      {
                        label: 'Strength',
                        value: 'strength',
                        icon: (
                          <IconWorkoutType
                            type={'strength'}
                            size={12}
                            outline={false}
                          />
                        ),
                      },
                      {
                        label: 'Conditioning',
                        value: 'conditioning',
                        icon: (
                          <IconWorkoutType
                            type={'conditioning'}
                            size={12}
                            outline={false}
                          />
                        ),
                      },
                      {
                        label: 'Skill',
                        value: 'skill',
                        icon: (
                          <IconWorkoutType
                            type={'skill'}
                            size={12}
                            outline={false}
                          />
                        ),
                      },
                    ]}
                  />
                )}
              />
            )}

            <h3>Instructions</h3>

            <SupersetInput />

            <div className={css['options']}>
              <h3>Options</h3>
              <div className={`${css['left']} ${css['half']}`}>
                <div>
                  <label htmlFor="tracking">Tracking</label>
                  <select
                    className={css['big']}
                    {...formMethods.register('tracking')}
                  >
                    <option value="no-tracking">No Tracking</option>
                    <option disabled={isSuperset} value="reps-weight">
                      Reps, Weight
                    </option>
                    <option value="time">Time</option>
                    <option value="total-reps">Total Reps</option>
                    <option value="rounds">Total Rounds</option>
                    <option value="rounds-reps">Total Rounds+Reps</option>
                    <option value="weight-used">Used Weight</option>
                    <option value="text">Custom: Text Entry</option>
                  </select>
                </div>
                {values.tracking === 'total-reps' && values.rounds > 1 ? (
                  <DoubleTimeInput name="roundRestTime" />
                ) : (
                  <TimeCapInput name="timeCap" />
                )}
              </div>

              <div className={`${css['right']}  ${css['half']}`}>
                {[
                  'no-tracking',
                  'reps-weight',
                  'text',
                  'time',
                  'weight-used',
                ].includes(values.tracking) ? (
                  <RepsInput
                    label={
                      values.tracking !== 'time'
                        ? 'Sets & Reps'
                        : 'Rounds or Reps'
                    }
                    roundsMode={values.tracking === 'time'}
                    hintText={
                      values.tracking === 'time'
                        ? 'I.e: 4 (rounds) or 21-15-9 (reps)'
                        : ''
                    }
                    isRequired={values.tracking === 'reps-weight'}
                  />
                ) : (
                  <RoundsInput
                    label="Rounds"
                    isAMRAP={['rounds', 'rounds-reps'].includes(
                      values.tracking
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  marginTop: '32px',
                  float: 'left',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <input
                  type="checkbox"
                  id="auto-description"
                  {...formMethods.register('hideAutomaticDescription')}
                />
                <label
                  htmlFor="auto-description"
                  style={{
                    margin: '-2px 0 0 12px',
                    fontSize: '16px',
                    fontWeight: 400,
                    cursor: 'pointer',
                  }}
                >
                  Hide automatic description
                </label>
              </div>

              {/* <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <label>Special Note</label>
                <textarea {...formMethods.register('specialNote')}></textarea>
              </div> */}
            </div>
          </div>

          <div className={css['bottom-items']}>
            {!isValid && (
              <p
                className="caption"
                style={{ textAlign: 'center', marginBottom: '4px' }}
              >
                Please add at least one movement or a note
              </p>
            )}
            <div className={css['bottom-buttons']}>
              <BuilderActions
                buttons={getButtonActions()}
                onAction={() => {}}
              />
            </div>
            {/* <Button
              onClick={publishWorkout}
              disabled={!isValid}
              className={!isValid ? 'disabled' : undefined}
            >
              {(workoutEventToEdit && workoutEventToEdit.isPublished) ||
              isPresetMode
                ? 'Save Workout'
                : 'Publish Workout'}
            </Button>
            {!isPresetMode && (
              <Button
                onClick={
                  workoutEventToEdit && workoutEventToEdit.isPublished
                    ? unpublishWorkout
                    : saveWorkout
                }
                type="secondary"
                className="secondary"
                style={{ marginTop: '8px' }}
                disabled={!isValid}
              >
                {(workoutEventToEdit && !workoutEventToEdit.isPublished) ||
                !workoutToEdit
                  ? 'Save Draft'
                  : 'Unpublish Workout'}
              </Button>
            )}
            {workoutToEdit ? (
              <Button
                onClick={() =>
                  setConfirmation({ text: 'Delete Workout?', what: 'delete' })
                }
                type="tertiary-destructive"
                className="tertiary-destructive"
                style={{ marginTop: '16px' }}
              >
                Delete
              </Button>
            ) : (
              ''
            )} */}
          </div>
        </div>
      ) : (
        <div
          className={css['no-scaling-placeholder']}
          onClick={addScalingOption}
        >
          <div>
            <Plus size={24} />
            Add Scaling Option
          </div>
        </div>
      )}
    </FormProvider>
  )
}

export default WorkoutBuilder

type BuilderAction = 'save' | 'publish' | 'unpublish' | 'delete'
type BuilderButton = {
  label: string
  onClick: () => void
  type: ButtonType
  disabled?: boolean
}

const BuilderActions = ({
  buttons,
  onAction,
}: {
  buttons: BuilderButton[] | undefined
  onAction: (action: BuilderAction) => void
}) => {
  if (buttons)
    return (
      <>
        {buttons.map(({ label, onClick, type, disabled }) => (
          <Button key={label} onClick={onClick} type={type} disabled={disabled}>
            {label}
          </Button>
        ))}
      </>
    )
  else return <></>
}
