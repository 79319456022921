import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { auth } from '../../../firebase'
import './_Auth.css'
import Button from '../../../components/buttons/Button/Button'
import Logo from '../../../components/atoms/Logo/Logo'
import { sendPasswordResetEmail } from 'firebase/auth'
import { translateAuthErrors } from '../../../helpers'
import { AuthForm } from '../../../hooks/useAuth'

interface ResetForm extends AuthForm {
  isSent?: boolean
  setNewPassword?: boolean
}

function ResetModule({
  email,
  password,
  error,
  handleSubmit,
  onModeSwitch,
  isSent,
  isLoading,
  setNewPassword,
}: ResetForm) {
  return (
    <div className="auth_container module">
      {!setNewPassword ? (
        <>
          {' '}
          <h2>Reset Password</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (!isSent) {
                handleSubmit()
              }
            }}
            name="password-reset-email"
          >
            <input
              className="reset__textBox"
              placeholder="E-mail Address"
              {...email}
            />
            {error && (
              <div className={`under-input-message error`}>{error}</div>
            )}
            {isSent && (
              <div className={`under-input-message`}>
                If you created the account with this e-mail, you will receive
                the reset link promptly.{' '}
                <a onClick={() => onModeSwitch('reset')}>Incorrect e-mail?</a>
              </div>
            )}
            {!isSent && (
              <Button
                className="reset__btn"
                isSubmitButton={true}
                style={{ marginTop: '24px' }}
                isLoading={isLoading}
              >
                Send password reset email
              </Button>
            )}
          </form>
        </>
      ) : (
        <>
          {' '}
          <h2>Set New Password</h2>
          <form onSubmit={handleSubmit} name="new-password">
            <input
              autoComplete="new-password"
              {...password}
              placeholder="New Password"
              type="password"
            />
            {error && (
              <div className={`under-input-message error`}>{error}</div>
            )}
            <Button
              className="reset__btn"
              style={{ marginTop: '24px' }}
              isLoading={isLoading}
              isSubmitButton={true}
            >
              Save Password
            </Button>
          </form>
        </>
      )}

      <br />
      {!isSent && (
        <div>
          Remember your password?{' '}
          <a onClick={() => onModeSwitch('login')}>
            {!isSent ? 'Sign In' : 'Return to Sign In'}
          </a>
        </div>
      )}
    </div>
  )
}
export default ResetModule
