import React, {
  createRef,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useResizeObserver } from 'usehooks-ts'
import CalendarContext from '../../context/CalendarContext'
import { AppContext } from '../../context/AppContext'
import _ from 'lodash'

type Props = {}

const useCalendarInfiniteScroller = () => {
  const {
    monthsReducer: { months, latestAction: monthReducerAction },
    activeDate,
    isScrolling,
    setScrolling,
    setMonthsReducer,
    setCalendarRef,
    setSelectedMonth,
  } = useContext(CalendarContext)
  const previousMonths = useRef(months)

  const [startPositionAdjusted, setStartPositionAdjusted] = useState(false)

  const calendarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (calendarRef.current) setCalendarRef(calendarRef.current)
  }, [])

  const startMonthIndex = 3
  const containerRef = useRef<HTMLDivElement>(null)
  const monthsRef = useRef<HTMLDivElement>(null)

  const monthRefs = useRef<RefObject<HTMLDivElement>[]>([])
  monthRefs.current = months.map(
    (_, i) => monthRefs.current[i] ?? createRef<HTMLDivElement>()
  )

  useEffect(() => {
    // Ensure the startMonth scrolls into view on initial load
    shiftToDefaultPosition()
  }, []) // Empty dependency array to ensure it runs only once after the initial render

  const shiftToDefaultPosition = () => {
    const child = monthRefs.current[startMonthIndex].current
    const container = containerRef.current

    if (child && container) {
      const nextOrPreviousMonth = ['next-snap', 'prev-snap'].includes(
        monthReducerAction as string
      )
      const behavior = nextOrPreviousMonth ? 'smooth' : 'auto'
      adjustAnchorPosition()
      setStartPositionAdjusted(true)
      child.scrollIntoView({
        block: 'start', // 'center' | 'end' | 'nearest
        //behavior: 'smooth',
      })
    } else {
      console.warn('could not find child')
    }

    previousFirstElementHeight.current =
      monthRefs.current[0].current?.offsetHeight ?? 0
  }

  const shiftToPreviousMonths = (shiftByNumber: number) => {
    previousMonths.current = months
    setMonthsReducer('prev')
  }

  const shiftToNextMonths = () => {
    previousMonths.current = months
    const firstElementHeight = monthRefs.current[0].current?.offsetHeight ?? 0

    requestAnimationFrame(() => {
      adjustScrollPosition(-firstElementHeight)
    })
    setMonthsReducer('next')
  }

  const previousFirstElementHeight = useRef(0)

  useEffect(() => {
    const monthsContainer = monthsRef.current
    const scrollContainer = containerRef.current
    if (!monthsContainer || !scrollContainer) return

    switch (monthReducerAction) {
      case 'prev': {
        const scrollAdjustment =
          0 - (monthRefs.current[0].current?.offsetHeight ?? 0)
        if (scrollAdjustment) {
          requestAnimationFrame(() => {
            adjustScrollPosition(-scrollAdjustment)
          })
        }
        return
      }
      case 'today':
      case 'prev-snap':
      case 'next-snap': {
        return shiftToDefaultPosition()
      }
      default: {
        return setMonthsReducer(null)
      }
    }
  }, [months])

  // Tracking scroll. We will load new events only once the scrolling has stopped

  const scrollStopped = useCallback(
    _.debounce(() => {
      setScrolling(false)
    }, 500),
    []
  ) // Empty dependency array to ensure the debounce function is created only once

  const [intersectionCaught, setIntersectionCaught] = useState(false)

  const handleIntersection = (
    entry: IntersectionObserverEntry | undefined,
    index: number
  ) => {
    if (entry) {
      const instersectionDirection =
        entry.boundingClientRect.y > 0 ? 'down' : 'up'

      // Checking intersection of the top border of a month
      // We change active months only when the month is more than 60% visible
      if (entry.intersectionRatio > 0.6) {
        setSelectedMonth(months[index])
      }

      // Checking intersections of months borders
      // to start loading next / previos ones
      if (entry.isIntersecting) {
        if (!intersectionCaught) {
          if (index < startMonthIndex - 1 && instersectionDirection === 'up') {
            const shiftByMonths = startMonthIndex - 1 - index
            // Add new month at the top
            shiftToPreviousMonths(shiftByMonths)
          } else if (
            index > startMonthIndex + 1 &&
            instersectionDirection === 'down'
          ) {
            // Add new month at the bottom
            shiftToNextMonths()
          }
        }
      }
    }
  }

  const anchorRef = useRef<HTMLDivElement>(null)
  const anchorPosition = useRef(0)

  // Anchor is used to track height changes of the content that is before
  // the visible area of the scrollable container
  const adjustAnchorPosition = () => {
    const scrollContainer = containerRef.current
    const scrollContainerHeight = scrollContainer?.offsetHeight ?? 0
    const monthsContainer = monthsRef.current
    const anchor = anchorRef.current

    if (scrollContainer && monthsContainer && anchor) {
      const yPosition = scrollContainer.scrollTop
      const children = Array.from(monthsContainer.children) as HTMLElement[]

      // Going through all children of the scrollable area
      // Each child is a month
      for (const child of children) {
        const childHeight = child.offsetHeight
        const absoluteChildTop = child.offsetTop
        const absoluteChildBottom = absoluteChildTop + childHeight

        const childIsInView =
          (absoluteChildTop <= yPosition && absoluteChildBottom > yPosition) ||
          (absoluteChildBottom > yPosition &&
            absoluteChildBottom < yPosition + scrollContainerHeight)

        if (childIsInView) {
          // Top of a scroll container relative to the child
          const relativeOffset =
            100 * ((yPosition - absoluteChildTop) / childHeight)
          anchor.style.top = `${relativeOffset}%`

          // Insterting the anchor into the topmost visible month
          // So that the anchor would move with the above months
          // height changes
          child.appendChild(anchor)
          anchorPosition.current = yPosition

          break
        }
      }
    }
  }

  // Used to fix scroll position when months are expanded, loaded or added/removed from the DOM
  // We won't need this when overflow-anchor will become supported in Safari
  const adjustScrollPosition = (shift: number) => {
    const scrollContainer = containerRef.current
    if (scrollContainer && startPositionAdjusted) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollTop + shift,
        behavior: 'auto',
      })
    }
  }

  const handleResize = (params: { width?: number; height?: number }) => {
    const scrollContainer = containerRef.current
    const anchor = anchorRef.current
    const anchorParent = anchor?.parentElement

    if (scrollContainer && anchor && anchorParent && !intersectionCaught) {
      const newAbsolutePosition = anchorParent.offsetTop + anchor.offsetTop
      const shift = anchorPosition.current - newAbsolutePosition

      if (shift) {
        adjustScrollPosition(-shift)
      }
    }
  }

  const monthsResizeObserver = useResizeObserver<HTMLDivElement>({
    ref: monthsRef,
    onResize: (entry) => handleResize(entry),
  })

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    adjustAnchorPosition()
  }

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    setScrolling(true)
    // debounced
    scrollStopped()
  }

  return {
    refs: {
      calendarRef,
      containerRef,
      monthsRef,
      monthRefs,
      anchorRef,
    },
    handleScroll,
    handleWheel,
    handleIntersection,
  }
}

export default useCalendarInfiniteScroller
