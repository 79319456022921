import { v4 as uuid } from 'uuid'
import { CalendarEvent } from '../../types/calendarEvents'
import { Workout } from '../../types/workouts'

const makeVariationOfPredefinedWorkoutEvent = (
  calendarEvent: CalendarEvent.Workout
): CalendarEvent.Workout => {
  // When workout is "predefined", we actually make a variation of it
  // Workouts with scaling will only have default scaling

  const typeOverride = 'conditioning'
  const titleOverride = calendarEvent.details.title + ' (Variation)'

  const setOverride = calendarEvent.details.set

  const workoutDetails: Workout.Item = {
    ...calendarEvent.details,
    workoutType: typeOverride,
    title: titleOverride,
    specialNote: '',
    benchmarkID: null,
    playbookReferenceID: null,
    isPredefined: false,
    set: setOverride,
    superset: calendarEvent.details.additionalNote
      ? [
          ...calendarEvent.details.superset,
          {
            itemType: 'note',
            id: 'note_' + uuid().substring(0, 8),
            note: calendarEvent.details.additionalNote,
          },
        ]
      : calendarEvent.details.superset,
  }

  return {
    ...calendarEvent,
    details: workoutDetails,
  }
}

export default makeVariationOfPredefinedWorkoutEvent
