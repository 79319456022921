import { CSSProperties, ReactNode } from 'react'
import { BarLoader } from 'react-spinners'
import css from './Button.module.css'

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'secondary-dark'
  | 'destructive'
  | 'tertiary-destructive'

type Props = {
  type?: ButtonType
  isLoading?: boolean
  children?: string
  onClick?: () => void
  className?: string
  style?: CSSProperties
  disabled?: boolean
  iconRight?: ReactNode
  iconLeft?: ReactNode
  isSubmitButton?: boolean
}

const Button = ({
  type,
  children,
  isLoading,
  onClick,
  className,
  style,
  disabled,
  iconRight,
  iconLeft,
  isSubmitButton,
}: Props) => {
  return (
    <button
      type={isSubmitButton ? 'submit' : 'button'}
      onClick={onClick}
      className={`${css['button']} ${className} ${type && css[type]} ${
        disabled && css['disabled']
      }`}
      style={style}
      disabled={disabled}
    >
      {iconLeft && iconLeft}
      {!isLoading ? (
        children
      ) : (
        <BarLoader
          color={
            type === 'destructive' || !type
              ? 'rgba(255,255,255,1)'
              : 'rgba(255,255,255,0.5)'
          }
          cssOverride={{
            backgroundColor: 'rgba(0,0,0,0.3)',
          }}
          width={80}
        />
      )}
      {iconRight && iconRight}
    </button>
  )
}

export default Button
