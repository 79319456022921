import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import css from './PrintCode.module.css'
import QRCode from 'react-qr-code'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '../../services/analytics'

type Props = {}

const Support = (props: Props) => {
  const { code } = useParams()

  useEffect(() => {
    window.location.replace('https://forms.gle/n6DpKk28pbpVRjwx7')
  }, [])

  return <></>
}

export default Support
