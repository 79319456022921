import { DateTime } from 'luxon'

/**
 *
 * @param date actual date
 * @returns demo events have actual dates from 2023-05-29 to 2023-06-28. We need to adjust the date to match the demo events in DB
 */
const getAdjustedDemoDate = (date: string | undefined) => {
  if (!date) return
  const demoDateNumber = DateTime.fromISO(date).get('day')

  const maxNumber = demoEventDates.length - 1

  let adjustedDemoDateNumber = demoDateNumber

  // if reached max, start from the beginning
  if (demoDateNumber > maxNumber)
    adjustedDemoDateNumber = demoDateNumber % maxNumber
  else if (demoDateNumber < 0)
    adjustedDemoDateNumber = maxNumber + demoDateNumber

  return demoEventDates[adjustedDemoDateNumber]
}

export default getAdjustedDemoDate

const demoEventDates = [
  '2023-05-29',
  '2023-05-30',
  '2023-05-31',
  '2023-06-01',
  '2023-06-02',
  '2023-06-03',
  '2023-06-04',
  '2023-06-05',
  '2023-06-06',
  '2023-06-07',
  '2023-06-08',
  '2023-06-09',
  '2023-06-10',
  '2023-06-11',
  '2023-06-12',
  '2023-06-13',
  '2023-06-14',
  '2023-06-15',
  '2023-06-16',
  '2023-06-17',
  '2023-06-18',
  '2023-06-19',
  '2023-06-20',
  '2023-06-21',
  '2023-06-22',
  '2023-06-23',
  '2023-06-24',
  '2023-06-25',
  '2023-06-26',
  '2023-06-27',
  '2023-06-28',
  '2023-05-29',
]
