import React, { useEffect } from 'react'
import { logout } from '../../../firebase'
import { useNavigate } from 'react-router-dom'

type Props = {}

const Logout = (props: Props) => {
  const navigate = useNavigate()
  useEffect(() => {
    logout()
    navigate('/')
  }, [])
  return <div></div>
}

export default Logout
