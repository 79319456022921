import { Workout } from '../../../types/workouts'
import { capitalize } from 'lodash'
import { Dropdown } from '../../inputs/Dropdown/Dropdown'
import { CSSProperties } from 'react'
import { AgeGroup } from '../../../types/types'

type Props = {
  workoutData: Workout.Item
  setItems: Workout.Item[]
  onScalingOptionChange: (value: Workout.Item | undefined) => void
  scalingOption: Workout.Item
  style?: CSSProperties
}

export const ScalingSelector = ({
  setItems,
  workoutData,
  scalingOption,
  onScalingOptionChange,
  style,
}: Props) => {
  const allExistingOptions = setItems.reduce((acc, item) => {
    if (item.set?.scaling) {
      acc.push(item)
    }
    return acc
  }, [] as Workout.Item[])

  const sortedOptions = allExistingOptions.sort((a, b) => {
    // Sort by ageGroup
    const ageOrder = { masters: 0, 'masters-55-plus': 1, teens: 2 }
    const aScaling = a.set?.scaling
    const bScaling = b.set?.scaling

    if (!aScaling || !bScaling) return 0

    if (ageOrder[aScaling.ageGroup] < ageOrder[bScaling.ageGroup]) return -1
    if (ageOrder[aScaling.ageGroup] > ageOrder[bScaling.ageGroup]) return 1

    // Sort by scaling
    const scalingOrder = { rx: 0, scaled: 1, foundations: 2 }
    if (scalingOrder[aScaling.effort] < scalingOrder[bScaling.effort]) return -1
    if (scalingOrder[aScaling.effort] > scalingOrder[bScaling.effort]) return 1

    // Sort by genderGroup
    const genderOrder = { women: 0, men: 1 }
    if (genderOrder[aScaling.genderGroup] < genderOrder[bScaling.genderGroup])
      return -1
    if (genderOrder[aScaling.genderGroup] > genderOrder[bScaling.genderGroup])
      return 1

    return 0
  })

  const mapped = sortedOptions.reduce(
    (acc, option) => {
      if (option.set) {
        const { ageGroup } = option.set.scaling
        if (!acc[ageGroup]) {
          acc[ageGroup] = [option]
        } else {
          acc[ageGroup].push(option)
        }
      }
      return acc
    },
    { masters: [], 'masters-55-plus': [], teens: [] } as {
      [K in AgeGroup]: Workout.Item[]
    }
  )

  const getValue = (item: Workout.ScalingOption | undefined) => {
    // if no other age groups exist, we don't include it in the label
    // same with gender groups, scalinns

    if (!item) return ''

    const genderGroupExists =
      sortedOptions
        .map((option) => option.set?.scaling.genderGroup)
        .filter((item, index, array) => array.indexOf(item) === index).length >
      1

    const scalingExists =
      sortedOptions
        .map((option) => option.set?.scaling.effort)
        .filter((item, index, array) => array.indexOf(item) === index).length >
      1

    const ageGroupExists =
      sortedOptions
        .map((option) => option.set?.scaling.ageGroup)
        .filter((item, index, array) => array.indexOf(item) === index).length >
      1

    return `${ageGroupExists ? translateLabel(item.ageGroup) + ' · ' : ''}${
      scalingExists && genderGroupExists
        ? translateLabel(item.effort) + ' · '
        : scalingExists
        ? translateLabel(item.effort)
        : ''
    }${genderGroupExists ? translateLabel(item.genderGroup) : ''}`
  }

  const items: {
    label: string
    type: 'value' | 'divider'
    id: string
  }[] = [
    ...mapped['masters'].map((item) => ({
      label: getValue(item.set?.scaling),
      type: 'value' as 'value',
      id: item.id,
    })),
    ...(mapped['masters-55-plus'].length
      ? [
          {
            label: 'divider',
            type: 'divider' as 'divider',
            id: `divider_1_${workoutData.id}`,
          },
        ]
      : []),
    ...mapped['masters-55-plus'].map((item) => ({
      label: getValue(item.set?.scaling),
      type: 'value' as 'value',
      id: item.id,
    })),
    ...(mapped['teens'].length
      ? [
          {
            label: 'divider',
            type: 'divider' as 'divider',
            id: `divider_2_${workoutData.id}`,
          },
        ]
      : []),
    ...mapped['teens'].map((item) => ({
      label: getValue(item.set?.scaling),
      type: 'value' as 'value',
      id: item.id,
    })),
  ]

  return (
    <div style={{ display: 'flex', ...style }}>
      <Dropdown
        items={items}
        innerTitle="Scaling Options"
        activeItem={{
          label: getValue(scalingOption.set?.scaling),
          id: scalingOption.id,
          type: 'value',
        }}
        onChange={(id) => {
          console.log('id', id)
          onScalingOptionChange(setItems.find((item) => item.id === id))
        }}
        style={{
          width: '100%',
        }}
      />
    </div>
  )
}

const translateLabel = (label: string) => {
  switch (label) {
    case 'masters':
      return 'Masters'
    case 'masters-55-plus':
      return 'Masters 55+'
    case 'teens':
      return 'Teens'
    default:
      return capitalize(label)
  }
}
