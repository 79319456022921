import axios from 'axios'
import { auth } from '../firebase'
import API from '.'

export const createNewUserInDatabase = async (
  name: string,
  introMode: 'create-gym' | 'join-gym' | null = null
) => {
  const authToken = await auth.currentUser?.getIdToken()

  try {
    await axios.post(API.url + `createNewUserInDatabase`, {
      name,
      introMode,
      authToken,
    })
  } catch (err: any) {
    console.error(err.message)
  }

  return
}
