import ButtonToolbar from '../../../buttons/ButtonToolbar/ButtonToolbar'
import css from '../SecondaryNav.module.css'
import { useContext, useState } from 'react'
import { Plus, Send, Share, Tv } from 'react-feather'
import InviteMembers from '../../../modals/InviteMembers/InviteMembers'

type Props = {}

const MembersNav = ({}: Props) => {
  const [modalIsVisible, setModalIsVisible] = useState(false)
  const showModal = () => {}
  return (
    <>
      <InviteMembers
        isVisible={modalIsVisible}
        onDismiss={() => setModalIsVisible(false)}
      />
      <h2>Members</h2>

      <div className={css['button-group']}>
        <ButtonToolbar
          icon={<Plus color="var(--accent)" size={16} />}
          onClick={() => setModalIsVisible(true)}
        >
          Invite Members
        </ButtonToolbar>
      </div>
    </>
  )
}

export default MembersNav
