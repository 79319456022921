import { CSSProperties } from 'react'
import css from './TextInput.module.css'
import { UseFormRegisterReturn } from 'react-hook-form'

type Props = {
  formMethods: UseFormRegisterReturn<string>
  label?: string
  hintText?: string
  subText?: string
  errorText?: string
  style?: CSSProperties
  placeholder?: string
  centered?: boolean
}

const TextInput = ({
  label,
  hintText,
  subText,
  errorText,
  style,
  formMethods,
  placeholder,
  centered,
}: Props) => {
  return (
    <div style={style}>
      {label && <label>{label}</label>}
      <input
        type="text"
        className={`${css['input']} ${errorText && 'error'} ${
          centered && css['centered']
        }`}
        placeholder={placeholder}
        {...formMethods}
      />
      {errorText && <div className={css['error-text']}>{errorText}</div>}
      {hintText && (!subText || errorText) && (
        <div className={css['hint-text']}>{hintText}</div>
      )}
      {subText && !errorText && (
        <div className={css['sub-text']}>{subText}</div>
      )}
    </div>
  )
}

export default TextInput
