import React from 'react'
import css from './UserList.module.css'
import UserListItem from '../../atoms/UserListItem/UserListItem'
import usePeopleManagement from '../../../hooks/usePeopleManagement'
import { GymMemberData } from '../../../types/types'

type Props = {
  data: GymMemberData[]
  onUserAction: (action: string, data: GymMemberData) => void
}

const UserList = ({ data, onUserAction }: Props) => {
  return (
    <div className={`${css['user-list']}`}>
      <ul>
        {data &&
          data
            .sort((a, b) => {
              if (a.name && b.name) {
                return a.name.localeCompare(b.name)
              } else if (a.name) {
                return -1
              } else if (b.name) {
                return 1
              } else {
                return 0
              }
            })
            .sort((a, b) => {
              if (a.lastActivityRecord && b.lastActivityRecord) {
                return (
                  b.lastActivityRecord.createdDate.seconds -
                  a.lastActivityRecord.createdDate.seconds
                )
              } else if (a.lastActivityRecord) {
                return -1
              } else if (b.lastActivityRecord) {
                return 1
              } else {
                return 0
              }
            })
            .map((item, index) => {
              return (
                item?.uid && (
                  <UserListItem
                    data={item}
                    key={item.uid}
                    onUserAction={onUserAction}
                    index={index + 1}
                  />
                )
              )
            })}
      </ul>
    </div>
  )
}

export default UserList
