import React, { CSSProperties } from 'react'
import { UseFormReturn } from 'react-hook-form'

type Props = {
  name: string
  label?: string
  placeholder?: string
  characterLimit?: number
  formMethods: UseFormReturn<any>
  style?: CSSProperties
  isRequired?: boolean | string
}

const TextAreaInput = ({
  style,
  formMethods,
  placeholder,
  name,
  label,
  characterLimit,
  isRequired,
}: Props) => {
  const charLength = formMethods.watch(name).length
  const isError = formMethods.getFieldState(name).error
  const formState = formMethods.formState

  const errorMessage = (formState.errors[name]?.message as string) ?? null

  return (
    <>
      {!!label && (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <label>{label}</label>
          {!!characterLimit && (
            <span style={{ font: 'var(--small-text)' }}>
              {charLength}/{characterLimit}
            </span>
          )}
        </div>
      )}
      <textarea
        style={style}
        className={formMethods.getFieldState(name).error ? 'error' : ''}
        placeholder={placeholder}
        maxLength={characterLimit}
        {...formMethods.register(name, {
          required: isRequired,
        })}
      />
      {isError ? (
        <span
          style={{
            marginTop: '8px',
            font: 'var(--h5)',
            color: 'var(--accent-red)',
          }}
        >
          {errorMessage}
        </span>
      ) : (
        <span
          style={{
            marginTop: '8px',
            font: 'var(--h5)',
            color: 'var(--accent-red)',
          }}
        >
          &nbsp;
        </span>
      )}
    </>
  )
}

export default TextAreaInput
