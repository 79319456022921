import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { GymMemberData } from '../../types/types'
import Button from '../buttons/Button/Button'
import Avatar from '../atoms/Avatar/Avatar'
import Modal from './Modal'
import css from './Modal.module.css'
import useGym from '../../hooks/useGym'

type Props = {
  isVisible: boolean
  onDismiss: () => void
}

const JoinGym = ({ isVisible, onDismiss }: Props) => {
  //const [isLoading, setLoading] = useState(false)

  const {
    gymIDField,
    error,
    isLoading,
    handleSubmit,
    setFocus,
    gymFound,
    setGymFound,
    joinGym,
  } = useGym({ onComplete: onDismiss, joinMode: true })

  useEffect(() => {
    setFocus('gymID')
  }, [isVisible])

  return (
    <Modal
      isVisible={isVisible}
      onCancel={onDismiss}
      size="small"
      buttons={{
        secondary: {
          onClick: !gymFound ? () => onDismiss() : () => setGymFound(null),
          label: !gymFound ? 'Dismiss' : 'Back',
        },
        primary: {
          label: !gymFound ? 'Search' : 'Join',
          onClick: !gymFound
            ? () => handleSubmit()
            : () => joinGym(gymFound.id),
          isSubmitButton: true,
          isLoading,
          disabled: error ? true : false,
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!gymFound ? (
          <>
            <div>
              <h3>Join a Gym</h3>
            </div>

            <p style={{ marginTop: '24px' }}>Enter a Gym ID</p>
            <form onSubmit={handleSubmit}>
              <input
                {...gymIDField}
                className="accessCode"
                placeholder="XXXXXX"
                style={{ marginBottom: '24px' }}
                maxLength={6}
              />
            </form>
            {error && (
              <div
                style={{
                  margin: '-20px 0 0px',
                  color: 'var(--accent-red)',
                  textAlign: 'center',
                }}
              >
                {error}
              </div>
            )}
          </>
        ) : (
          <>
            <div>
              <h3>Welcome to</h3>
              <h1>{gymFound.title}</h1>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default JoinGym
