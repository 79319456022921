import React, { CSSProperties, useState } from 'react'
import css from './RoundsInput.module.css'
import { Plus as IconPlus, Minus as IconMinus } from 'react-feather'
import NumberInput from '../NumberInput/NumberInput'
import { useFormContext } from 'react-hook-form'

type Props = {
  label?: string
  errorText?: string
  style?: CSSProperties
  isAMRAP: boolean
  disabled?: boolean
}

const RoundsInput = ({ label, style, isAMRAP, disabled }: Props) => {
  return !isAMRAP ? (
    <NumberInput
      label={label}
      style={style}
      min={1}
      max={999}
      disabled={isAMRAP || disabled}
      emptyIsZero={true}
      preventFractions={true}
      name={'rounds'}
    />
  ) : (
    <div style={style}>
      {label && <label>{label}</label>}
      <input
        type="text"
        value="AMRAP"
        disabled={true}
        style={{ textAlign: 'center' }}
      />
    </div>
  )
}

export default RoundsInput
