import { Button } from '@mui/material'
import React, { CSSProperties, useState } from 'react'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import { Workout } from '../../../types/workouts'
import { useFormContext } from 'react-hook-form'
import { Dropdown } from '../Dropdown/Dropdown'
import { capitalize } from 'lodash'
import { AgeGroup } from '../../../types/types'

type Props = {
  value: Workout.ScalingOption
  onChange: (value: Workout.ScalingOption) => void
  setItems: Workout.Item[] | null
  style?: CSSProperties
  isLoading: boolean
}

export const ScalingOptionInput = (props: Props) => {
  const exists = (current: Workout.ScalingOption) => {
    return scalingOptionExists(props.setItems, current) ? '*' : ''
  }

  const allScalingOptions: {
    label: string
    type: 'item' | 'divider'
    id: string
  }[] = [
    {
      label: 'Masters · Rx · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters · Rx · Men ',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters · Scaled · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters · Scaled · Men ',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters · Foundations · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters · Foundations · Men ',
      type: 'item',
      id: '1',
    },
    {
      label: '',
      type: 'divider',
      id: '1',
    },
    {
      label: 'Masters 55+ · Rx · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters 55+ · Rx · Men ',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters 55+ · Scaled · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters 55+ · Scaled · Men ',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters 55+ · Foundations · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Masters 55+ · Foundations · Men ',
      type: 'item',
      id: '1',
    },
    {
      label: 'Teens · Rx · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Teens · Rx · Men ',
      type: 'item',
      id: '1',
    },
    {
      label: 'Teens · Scaled · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Teens · Scaled · Men ',
      type: 'item',
      id: '1',
    },
    {
      label: 'Teens · Foundations · Women',
      type: 'item',
      id: '1',
    },
    {
      label: 'Teens · Foundations · Men',
      type: 'item',
      id: '1',
    },
  ]

  const allEfforts: Workout.Effort[] = ['rx', 'scaled', 'foundations']
  const allAgeGroups: AgeGroup[] = ['masters', 'masters-55-plus', 'teens']
  const allGenderGroups: Workout.GenderGroup[] = ['women', 'men']

  const getValue = (scaling: Workout.ScalingOption) =>
    `${translateLabel(scaling.ageGroup)} · ${translateLabel(
      scaling.effort
    )} · ${translateLabel(scaling.genderGroup)}${exists(scaling)}
    `

  const allOptions = allAgeGroups.reduce(
    (acc, ageGroup) => {
      acc[ageGroup] = []

      allEfforts.forEach((effort) => {
        allGenderGroups.forEach((genderGroup) => {
          // Constructing the label and id could be more specific based on your needs
          const id = `${ageGroup}-${effort}-${genderGroup}}`
          const label = getValue({ ageGroup, effort, genderGroup })

          acc[ageGroup].push({
            id,
            label,
            type: 'value' as 'value',
            effort,
            genderGroup,
            ageGroup,
          })
        })
      })

      return acc
    },
    {} as {
      [ageGroup in AgeGroup]: {
        id: string
        label: string
        type: 'value'
        effort: Workout.Effort
        genderGroup: Workout.GenderGroup
        ageGroup: AgeGroup
      }[]
    }
  )

  const items = [
    ...allOptions['masters'],
    {
      type: 'divider' as 'divider',
      id: 'divider1',
      label: '',
      ageGroup: 'masters' as AgeGroup,
      effort: 'rx' as Workout.Effort,
      genderGroup: 'men' as Workout.GenderGroup,
    },
    ...allOptions['masters-55-plus'],
    {
      type: 'divider' as 'divider',
      id: 'divider2',
      label: '-',
      ageGroup: 'masters' as AgeGroup,
      effort: 'rx' as Workout.Effort,
      genderGroup: 'men' as Workout.GenderGroup,
    },
    ...allOptions['teens'],
  ]

  return (
    <div
      style={{
        display: 'flex',
        gap: '4px',
        alignItems: 'flex-end',
        ...props.style,
      }}
    >
      <Dropdown
        items={items}
        innerTitle="Scaling Options"
        activeItem={{
          label: props.isLoading ? 'Loading...' : getValue(props.value),
          id: `${props.value.ageGroup}-${props.value.effort}-${props.value.genderGroup}}`,
          type: 'value',
          ...props.value,
        }}
        style={{ width: '100%' }}
        onChange={(id) => {
          const { ageGroup, effort, genderGroup } = items.find(
            (item) => item.id === id
          ) || {
            ageGroup: 'masters' as AgeGroup,
            effort: 'rx' as Workout.Effort,
            genderGroup: 'women' as Workout.GenderGroup,
          }

          props.onChange({ ageGroup, effort, genderGroup })
        }}
      />
      {/* <div
        style={{
          flex: '1',
        }}
      >
        <label>Scaling</label>
        <select
          style={{ height: '32px' }}
          value={props.value.scaling}
          onChange={(e) =>
            handleChange({ scaling: e.target.value as Workout.Effort })
          }
        >
          <option value="rx">Rx{exists({ scaling: 'rx' })}</option>
          <option value="scaled">Scaled{exists({ scaling: 'scaled' })}</option>
          <option value="foundations">
            Foundations{exists({ scaling: 'foundations' })}
          </option>
        </select>
      </div>
      <div
        style={{
          flex: '1',
        }}
      >
        <label>Age Group</label>
        <select
          style={{ height: '32px' }}
          value={props.value.ageGroup}
          onChange={(e) =>
            handleChange({ ageGroup: e.target.value as Workout.AgeGroup })
          }
        >
          <option value="masters">
            Masters{exists({ ageGroup: 'masters' })}
          </option>
          <option value="masters-55-plus">
            Masters 55+{exists({ ageGroup: 'masters-55-plus' })}
          </option>
          <option value="teens">Teens{exists({ ageGroup: 'teens' })}</option>
        </select>
      </div>
      <div
        style={{
          flex: '1',
        }}
      >
        <label>Gender Group</label>
        <select
          style={{ height: '32px' }}
          value={props.value.genderGroup}
          onChange={(e) =>
            handleChange({ genderGroup: e.target.value as Workout.GenderGroup })
          }
        >
          <option value="men">Men{exists({ genderGroup: 'men' })}</option>
          <option value="women">Women{exists({ genderGroup: 'women' })}</option>
        </select>
      </div> */}
    </div>
  )
}

const scalingOptionExists = (
  setItems: Workout.Item[] | null,
  scalingOption: Workout.ScalingOption
): boolean => {
  return (
    setItems === null || // only default exists
    isDefaultScalingOption(scalingOption) ||
    !!setItems?.some((item) => {
      return (
        item.set?.scaling.effort === scalingOption.effort &&
        item.set?.scaling.genderGroup === scalingOption.genderGroup &&
        item.set?.scaling.ageGroup === scalingOption.ageGroup
      )
    })
  )
}

const isDefaultScalingOption = (scalingOption: Workout.ScalingOption) => {
  const defaultScaling = {
    scaling: 'rx',
    genderGroup: 'women',
    ageGroup: 'masters',
  }

  return (
    defaultScaling.ageGroup === scalingOption.ageGroup &&
    defaultScaling.genderGroup === scalingOption.genderGroup &&
    defaultScaling.scaling === scalingOption.effort
  )
}

const translateLabel = (label: string) => {
  switch (label) {
    case 'masters':
      return 'Masters'
    case 'masters-55-plus':
      return 'Masters 55+'
    case 'teens':
      return 'Teens'
    default:
      return capitalize(label)
  }
}
