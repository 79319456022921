import { CSSProperties } from 'react'
import css from './ToggleInput.module.css'

type Props = {
  isActive: boolean | undefined
  label?: string
  style?: CSSProperties
  onToggle: () => void
}

const ToggleInput = ({ isActive, label, style, onToggle }: Props) => {
  return (
    <div onClick={onToggle} className={css['container']} style={style}>
      <div className={`${css['toggle']} ${isActive && css['active']}`}>
        <div className={css['pipka']} />
      </div>
      {label && <div>{label}</div>}
    </div>
  )
}

export default ToggleInput
