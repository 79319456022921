import { RewodLegacyEvent } from './types/legacyEvents'
import { GymSubscriptionStatus, Tracking } from './types/types'
import pluralize from 'pluralize'
import { Workout, WorkoutShortcut } from './types/workouts'
import { capitalize } from './helpers'
import { useWorkoutShortcuts } from './hooks/useWorkoutShortcuts'

/** Check if subscription status allows access to the gym */
export const subAllowsAccess = (status: GymSubscriptionStatus) => {
  switch (status) {
    case 'active':
    case 'trialing':
    case 'incomplete':
    case 'past_due': // with this status Stripe still tries to charge the customer
      return true
    default:
      return false
  }
}

export const translateRoundsRestTime = (
  value: RewodLegacyEvent.Item['roundRestTime']
) => {
  if (value && value.work && value.rest)
    if (value.work.min || value.work.sec) {
      const restTime =
        value.rest.min && value.rest.sec
          ? `${value.rest.min}:${value.rest.sec} min`
          : value.rest.min && !value.rest.sec
          ? `${value.rest.min} min`
          : value.rest.sec
          ? `${value.rest.sec} sec`
          : ''

      const workTime =
        value.work.min && value.work.sec
          ? `${value.work.min}:${value.work.sec} min`
          : value.work.min && !value.work.sec
          ? `${value.work.min} min`
          : value.work.sec
          ? `${value.work.sec} sec`
          : ''

      return restTime
        ? `${workTime} work, ${restTime} rest`
        : `${workTime} work, ${workTime} rest`
    } else return null
}

export const translateGymData = (value: string) => {
  switch (value) {
    case 'evening-before':
      return 'At 6PM on the day before'
    case 'day-ahead':
      return '1 day ahead'
    case 'week-ahead':
      return '1 week ahead'
    case 'everything':
      return 'Always visible'
  }
}

export const translateTrackingType = (value: Tracking) => {
  switch (value) {
    case 'number':
      return 'For Count'
    case 'rounds':
      return 'AMRAP'
    case 'reps-weight':
      return 'For Weight'
    case 'time':
      return 'For Time'
    case 'total-reps':
      return 'AMRAP'
    case 'rounds-reps':
      return 'AMRAP'
    case 'text':
      return 'Custom'
    case 'max-weight-import':
      return 'Max Weight Import'
    case 'weight-used':
      return 'For Used Weight'
  }
}

export const translateWorkoutType = (
  value: Workout.Item['workoutType'] | string
) => {
  switch (value) {
    case 'conditioning':
      return 'Conditioning'
    case 'strength':
      return 'Strength'
    case 'skill':
      return 'Skill'
    case 'girls':
      return 'The Girls'
    case 'heroes':
      return 'Heroes'
    case 'special':
      return 'Specials'
    case 'endurance':
      return 'Endurance'
    case 'cf-open':
      return 'The Open'
    case 'gymnastics':
      return 'Gymnastics'
    case 'gym-workout':
      return 'Gym Workout'
    default:
      return capitalize(value)
  }
}
