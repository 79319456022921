import React, { ReactNode, useState } from 'react'
import ButtonIcon from '../buttons/ButtonIcon/ButtonIcon'
import css from './Modal.module.css'
import { X as IconX } from 'react-feather'
import Button, { ButtonType } from '../buttons/Button/Button'
import Pricing from '../sections/pricing/Pricing'

type Props = {
  isVisible: boolean
  onCancel: () => void
  onConfirm?: () => void
}

const SubscribeModal = ({ isVisible, onCancel }: Props) => {
  return (
    <div
      className={`${css['backdrop']} ${isVisible && css['visible']} ${
        css['blurred']
      }`}
      // onClick={(e) => {
      //   // backdrop click closes the modal
      //   if (e.target === e.currentTarget && canDismiss) onCancel()
      // }}
      style={{ backgroundColor: 'rgba(200,200,200,0.8)', overflow: 'scroll' }}
    >
      <ButtonIcon
        className={css['close-button']}
        type="dark"
        onClick={onCancel}
        icon={<IconX />}
        style={{ backgroundColor: 'white' }}
      />
      <Pricing isVisible={true} onComplete={onCancel} reSubscribing={true} />
    </div>
  )
}
export default SubscribeModal
