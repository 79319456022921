import {
  useReducer,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { analytics, auth, firestore } from '../firebase'
import { collection, doc, query, where } from 'firebase/firestore'
import {
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore'
import { DocumentData } from '@google-cloud/firestore'
import { UserData } from '../types/types'
import { useAuthUser } from '@react-query-firebase/auth'
import { AuthError, User } from 'firebase/auth'
import { Result } from '../types/results'
import { setUserId, setUserProperties } from 'firebase/analytics'
import { Analytics } from '../services/analytics'

//import { Analytics } from '../analytics'

interface UserContext {
  user: UserData
  userID: string
  userIsLoading: boolean
  movements: Result.Reference.MappedMovements | null
}

const def: UserContext = {
  user: {
    // public
    gender: 'other',
    name: '',
    photoURL: '',
    uid: '',

    // private
    ageGroup: 'masters',
    defaultSpace: '',
    introComplete: false,
    spaceAccess: null,
    measureUnits: 'lbs',
    introMode: null,
    authProvider: '',
    badgeCount: 0,
    email: '',
    emailVerified: false,
    pushToken: '',
    subscriptions: [''],
    onboardingTasks: {
      firstGymAdded: false,
      genderSelected: false,
      unitsSelected: false,
      unitsTipSeen: false,
    },
  },
  userID: '',
  userIsLoading: true,
  movements: null,
}

export const UserContext = createContext({
  // hasLoaded differentiates placeholder data from real data
  ...def,
})

export const UserContextProvider = ({ children }: any) => {
  const authUser = useAuthUser(['user'], auth)

  const [userIsLoading, setUserIsLoading] = useState(true)

  const userID = authUser.data
    ? authUser.data.uid
      ? authUser.data.uid
      : ''
    : ''

  const userDataRef = userID ? doc(firestore, 'users', userID) : undefined

  const userQuery = useFirestoreDocumentData(
    ['user', userID],
    userDataRef,
    {
      subscribe: true,
    },
    { enabled: !!userID }
  )

  const userData = userQuery.data

  const userMovementsQuery = userID
    ? doc(firestore, 'users', userID, 'results', 'all_results')
    : undefined

  const uMovementsRaw = useFirestoreDocumentData(
    ['results', userID],
    userMovementsQuery,
    { subscribe: true }
  )

  const uMovements = uMovementsRaw.data as Result.Reference.MappedMovements

  useEffect(() => {
    if (userIsLoading) {
      if (
        (!authUser.isLoading && !authUser.data) ||
        (authUser.data && !userQuery.isLoading && !uMovementsRaw.isLoading)
      ) {
        setUserIsLoading(false)
        if (userID) {
          setUserId(analytics, userID)
          setUserProperties(analytics, {
            gender: userData && userData.gender ? userData.gender : 'not set',
            hasProfilePicture: userData && userData.photoURL ? 'yes' : 'no',
            introComplete: userData && userData.introComplete ? 'yes' : 'no',
            measureUnits:
              userData && userData.measureUnits
                ? userData.measureUnits
                : 'not set',
          })
        }
      }
    }
  }, [authUser.isLoading, authUser, userData])

  useEffect(() => {
    if (userID && userData) {
      Analytics._identifyUser(userID, userData)
    }
  }, [userID, userData])

  const value: UserContext =
    !userIsLoading && userData
      ? {
          user: {
            // public
            gender: userData?.gender || def.user.gender,
            name: userData?.name || def.user.name,
            photoURL: userData?.photoURL || def.user.photoURL,
            uid: userData?.uid || def.user.uid,
            ageGroup: userData?.ageGroup || def.user.ageGroup,
            defaultSpace: userData?.defaultSpace || def.user.defaultSpace,
            introComplete: userData?.introComplete || def.user.introComplete,
            spaceAccess: userData?.spaceAccess || def.user.spaceAccess,
            measureUnits: userData?.measureUnits || def.user.measureUnits,
            introMode: userData?.introMode || def.user.introMode,
            authProvider: userData?.authProvider || def.user.authProvider,
            badgeCount: userData?.badgeCount || def.user.badgeCount,
            email: userData?.email || def.user.email,
            emailVerified:
              authUser.data?.emailVerified || def.user.emailVerified,
            pushToken: userData?.pushToken || def.user.pushToken,
            subscriptions: userData?.subscriptions || def.user.subscriptions,
            onboardingTasks:
              userData?.onboardingTasks || def.user.onboardingTasks,
          },
          userID: userID,
          userIsLoading: userIsLoading,
          movements: uMovements || def.movements,
        }
      : userID && !userData // this happens when user just authed
      ? { ...def, userIsLoading: true }
      : { ...def, userIsLoading }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContext
