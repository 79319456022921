import React, { CSSProperties } from 'react'
import css from './Logo.module.css'
import { ScaleLoader } from 'react-spinners'

type Props = {
  type: 'full' | 'small'
  style?: CSSProperties
  className?: string
  mono?: boolean
}

const Logo = ({ type, style, className, mono }: Props) => {
  switch (type) {
    case 'full':
      return (
        <div id={css['logo-full']} style={style} className={className}>
          {!mono ? (
            <img src={'/img/logo-full.svg'} alt="Rewod" />
          ) : (
            <img src={'/img/logo-full-mono.svg'} alt="Rewod" />
          )}
        </div>
      )

    case 'small':
      return (
        <div id={css['logo']} className={className}>
          <img src={'/img/logo.svg'} alt="R" />
        </div>
      )
  }
}

export default Logo
