import { analytics } from '../firebase'
import { EditableUserAttributes } from '.'
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import posthog from 'posthog-js'
import { UserData } from '../types/types'

type screenURL = '/me' | '/barbelllifts'
type RewodLegacyEventName = 'record_action'
type RecordAction = 'added' | 'edited' | 'deleted'

interface AuthEvent {
  name:
    | 'auth_login'
    | 'auth_register'
    | 'auth_logout'
    | 'auth_reset'
    | 'auth_forgot_password'
    | 'auth_verify_email'
    | 'auth_pricing_plan_selected'
    | 'auth_join_as_gym_owner'
    | 'auth_join_as_coach'
  params: {
    details?: string
  }
}

interface WorkoutResultEvent {
  name:
    | 'result_added'
    | 'result_edited'
    | 'result_deleted'
    | 'result_imported_deleted'
    | 'result_new_record'
  params: {
    resultID: string
    isPR?: boolean
  }
}

interface ResultsImportEvent {
  name: 'results_imported'
  params: {
    method: 'manual'
  }
}

interface SocialEvent {
  name:
    | 'comment_added'
    | 'comment_deleted'
    | 'fistbump_added'
    | 'fistbump_deleted'
    | 'comment_reported'
  params: {
    itemID: string
    resultID?: string
    parentID?: string
  }
}

interface GymEvent {
  name:
    | 'gym_switch'
    | 'gym_add'
    | 'gym_first_add'
    | 'gym_found'
    | 'gym_found_already_exists'
    | 'gym_not_found'
    | 'gym_join_cancelled'
    | 'gym_leave'

  params: {
    gymID?: string
    details?: string
  }
}

interface GymManagementEvent {
  name:
    | 'gym_invite_code_first_created'
    | 'gym_invite_code_changed'
    | 'gym_workout_visibility_changed'
    | 'gym_invite_code_print_requested'
    | 'gym_created'
    | 'gym_member_permissions_changed'
    | 'gym_member_blocked'
    | 'gym_member_restored'
    | 'gym_name_changed'
    | 'gym_invite_code_changed'
  params: {
    details?: string
  }
}

interface WorkoutBuilderEvent {
  name:
    | 'workout_created'
    | 'workout_edited'
    | 'workout_deleted'
    | 'workout_sorted'
  params: {
    gymID: string
    eventID: string
    eventItemID?: string
  }
}

interface CalendarEvent {
  name: 'calendar_show_today' | 'calendar_switch_date'
  params: {
    details?: string
  }
}

interface SettingsEvent {
  name: 'profile_edit' | 'profile_select_gender_during_tracking'
  params: {
    key: keyof EditableUserAttributes
  }
}

interface AccountEvent {
  name: 'account_delete' | 'account_delete_cancelled'
  params: {}
}

interface NotificationEvent {
  name:
    | 'notification_deleted'
    | 'notification_marked_all_as_read'
    | 'notification_push_allowed'
    | 'notification_push_denied'
    | 'notification_push_status'
    | 'notification_tap'
    | 'notification_tap_on_push'
  params: { id?: string; details?: string }
}

interface ClickEvent {
  name:
    | 'click__add_record_start'
    | 'click__edit_record_start'
    | 'click__bulk_movement_add_start'
    | 'click__cancel_bulk_movement_add'
    | 'click__bulk_movement_edit_start'
    | 'click__cancel_bulk_movement_edit'
    | 'click__delete_record_start'
    | 'click__cancel_add_result'
    | 'click__cancel_edit_result'
    | 'click__view_scoreboard'
    | 'click__hide_scoreboard'
    | 'click__view_scoreboard_result'
    | 'click__toggle_measure_units'
    | 'click__appstore_link'
  params: {
    details?: string
  }
}

interface ErrorEvent {
  name:
    | 'error_firestore'
    | 'error_pushAPI'
    | 'error_notifications'
    | 'error_auth'
    | 'error_ionic_photos'
    | 'error_notification_setup'
  params: {
    title: string
    body: string
  }
}

interface AgreementViewEvent {
  name: 'view_agreement_privacy_policy' | 'view_agreement_terms_of_use'
  params: {}
}

interface ContactSupportEvent {
  name: 'contact_support' | 'send_feedback'
  params: {
    referenceID?: string
    userID: string
  }
}

interface UserOnUserEvent {
  name: 'user_blocked_user'
  params: {
    blockedUID: string
    commentID: string
    resultID: string
  }
}

interface MiscEvent {
  name: 'misc_barbell_plates_legend_revealed'
  params: {
    details?: string
  }
}

interface QRScan {
  name: 'qr_code_for_app_scanned'
  params: {
    gymCode: string
  }
}

interface AnyEvent {
  name: string
  params: {
    [key: string]: any
  }
}

type AnalyticsEvent =
  | AuthEvent
  | WorkoutResultEvent
  | ResultsImportEvent
  | SocialEvent
  | ClickEvent
  | GymEvent
  | GymManagementEvent
  | CalendarEvent
  | NotificationEvent
  | SettingsEvent
  | MiscEvent
  | ErrorEvent
  | AccountEvent
  | AgreementViewEvent
  | ContactSupportEvent
  | UserOnUserEvent
  | QRScan
  | WorkoutBuilderEvent
  | AnyEvent

/** Analytics functions */
export class Analytics {
  /** Set current screen name
   * @param {string} screen
   */

  static _setScreen = (screen: string) => {
    try {
      logEvent(analytics, 'screen_view', {
        firebase_screen: screen,
        firebase_screen_class: getScreenClass(screen),
      })
    } catch (err: any) {
      console.error('FirebaseAnalytics.setScreen', err)
    }
  }

  static _identifyUser = (
    userID: string,
    userProperies?: Partial<UserData> | { [key: string]: any }
  ) => {
    try {
      posthog.identify(userID, userProperies)
    } catch (err) {
      console.error('posthog.identify', err)
    }
  }

  static _logEvent = (options: AnalyticsEvent) => {
    console.log('logging event', options.name, options.params)
    try {
      posthog.capture(options.name, options.params)
      logEvent(analytics, options.name, options.params)
    } catch (err: any) {
      console.error('FirebaseAnalytics.logEvent', err)
    }
  }

  static _setUserId = (userID: string) => {
    setUserId(analytics, userID)
  }
}

const getScreenClass = (screenURL: string) => {
  const pages: { [key: string]: string } = {
    '/me/barbelllifts': 'Barbell Lifts',
    '/me/movements': 'All Movements',
    '/me/settings': 'Settings',
    '/me': 'Profile',
    '/home': 'Home',
    '/notifications': 'Notifications',
    '/movementdetails': 'Movement Details',
    '/result': 'Result',
  }

  const currentPage = Object.keys(pages).find((page) =>
    screenURL.includes(page)
  )
  return currentPage ? pages[currentPage] : screenURL
}

export {}
