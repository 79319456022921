import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { firestore } from '../firebase'
import {
  collection,
  doc,
  getDoc,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import {
  useFirestoreInfiniteQuery,
  useFirestoreQuery,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore'
import { Workout } from '../types/workouts'

type Props = {
  activeType: Workout.Item['workoutType'] | 'everything'
}

export const useWorkouts = ({ activeType }: Props) => {
  const { currentSpace } = useContext(AppContext)

  const gymID = currentSpace ? currentSpace.id : 'no-id'

  //sort by title

  const workoutsRef =
    activeType !== 'everything'
      ? query(
          collection(firestore, 'workouts'),
          where('workoutType', '==', activeType),
          orderBy('title'),
          limit(20)
        )
      : query(collection(firestore, 'workouts'), orderBy('title'), limit(20))

  const workoutsRaw = useFirestoreInfiniteQuery(
    ['workouts', { activeType }],
    workoutsRef,
    (snapshot) => {
      const lastDocument = snapshot.docs[snapshot.docs.length - 1]
      return lastDocument
        ? query(workoutsRef, startAfter(lastDocument))
        : undefined
    },
    {},
    {
      onError: (error) => {
        console.error("Couldn't retrieve workouts: ", error)
      },
      keepPreviousData: false,
    }
  )

  const workouts: Workout.Item[] =
    workoutsRaw.data && workoutsRaw.data.pages
      ? workoutsRaw.data.pages
          .map((page) => page.docs.map((n) => n.data() as Workout.Item))
          .flat()
          .filter((workout: Workout.Item) => {
            if (workout.set && workout.id !== workout.set.parentWorkoutID)
              return false

            return true
          })
      : []

  return {
    workouts,
    workoutsAreLoading: workoutsRaw.isLoading,
    fetchNextPage: () => {
      if (!workoutsRaw.isFetchingNextPage && workoutsRaw.hasNextPage)
        workoutsRaw.fetchNextPage()
    },
    showNextPageLoader: workoutsRaw.isFetchingNextPage,
    refetch: workoutsRaw.refetch,
  }
}
