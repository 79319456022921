import React, { CSSProperties, useMemo } from 'react'
import { CalendarEvent } from '../../../../types/calendarEvents'
import { useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import css from './WorkoutItem.module.css'
import { rewodPluralize } from '../../../../utilities/rewodPluralize'
import { abbreviateMovementName } from '../../../../helpers'
import { Workout } from '../../../../types/workouts'
import { IconWorkoutType } from '../../../atoms/Icons/Icons'

import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames/bind'
import { Tooltip } from 'react-tooltip'
const cx = classNames.bind(css)

interface WorkoutItem {
  item: CalendarEvent.Workout
  /**
   * @prop hovering - the one that's flying when being dragged
   */
  hovering?: boolean
  duplicating?: boolean
}

const WorkoutItem = React.memo(
  ({ item, hovering, duplicating }: WorkoutItem) => {
    const items = useMemo(
      () => getWorkoutItems(item.details, item.isPublished),
      [item]
    )

    const copyKey = window.navigator.userAgent.includes('Mac') ? '⌥' : 'ctrl'
    const copyText = !duplicating
      ? `Hold ${copyKey} to duplicate`
      : 'Duplicating...'

    const {
      active,
      isDragging,
      setNodeRef,
      attributes,
      listeners,
      transform,
      transition,
    } = useSortable({
      id: item.id,
      strategy: verticalListSortingStrategy,
    })

    const style: CSSProperties = {
      position: 'relative',
      opacity: isDragging ? 0.5 : undefined,
      // transform: CSS.Transform.toString(transform), // buggy during duplication
      transition,
    }

    return (
      <>
        <div
          className={cx('workout', {
            published: item.isPublished,
            dragging: isDragging,
            hovering,
            duplicating,
          })}
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          data-tooltip-id={'tooltip-drag-hint'}
        >
          {item.details.title && (
            <div className={cx('workout-title')}>{item.details.title}</div>
          )}
          {items}
        </div>
        <div className={cx('hoverhint', { duplicating, hovering })}>
          {copyText}
        </div>
      </>
    )
  }
)

const getWorkoutItems = (workout: Workout.Item, isPublished: boolean) => {
  const notesOnlyMode = !workout.superset.some(
    (item) => item.itemType === 'exercise'
  )
  return (
    <>
      {workout.superset.map((item) => {
        switch (item.itemType) {
          case 'exercise': {
            const movementName =
              item.movement && item.movement.name ? item.movement.name : ''

            const shortName = workout.isSuperset
              ? rewodPluralize(abbreviateMovementName(movementName))
              : abbreviateMovementName(movementName)

            return (
              <div
                key={item.id}
                className={cx('day-item', {
                  [workout.workoutType]: true,
                })}
              >
                <div className={cx('icon-and-name-wrapper')}>
                  <div className={cx('icon')}>
                    <IconWorkoutType
                      type={workout.workoutType}
                      size={10}
                      outline={!isPublished}
                    />
                  </div>
                  <div className={cx('movement-name')}>{shortName}</div>
                </div>
              </div>
            )
          }

          case 'note': {
            return (
              notesOnlyMode && (
                <div key={item.id} className={cx('day-item', 'note')}>
                  <div className={cx('icon-and-name-wrapper')}>
                    <div className={css['name']}>
                      {item.note.replace(/\n\s*\n/g, '\n').substring(0, 64)}
                      {item.note.length > 64 ? '...' : ''}
                    </div>
                  </div>
                </div>
              )
            )
          }
        }
      })}
    </>
  )
}

export default WorkoutItem
