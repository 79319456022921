import React, { CSSProperties, useEffect } from 'react'
import css from './NumberInput.module.css'
import TextInput from '../TextInput/TextInput'
import { Plus as IconPlus, Minus as IconMinus } from 'react-feather'
import { useFormContext } from 'react-hook-form'

type Props = {
  label?: string
  hintText?: string
  subText?: string
  errorText?: string
  style?: CSSProperties
  name: string
  min?: number
  max?: number
  disabled?: boolean
  hideButtons?: boolean
  className?: string
  placeholder?: string
  emptyIsZero?: boolean
  preventFractions?: boolean
}

const NumberInput = ({
  label,
  hintText,
  subText,
  errorText,
  style,
  name,
  min,
  max,
  disabled,
  hideButtons,
  className,
  placeholder,
  emptyIsZero,
  preventFractions,
}: Props) => {
  const { register, setValue, watch } = useFormContext()
  const value = watch(name)

  const stepUp = () => {
    if (!disabled && value !== max)
      if (Number.isNaN(value)) setValue(name, 1)
      else setValue(name, value + 1)
  }
  const stepDown = () => {
    if (!disabled && value !== min)
      if (Number.isNaN(value)) setValue(name, 1)
      else setValue(name, value - 1)
  }

  useEffect(() => {
    if (min !== undefined && value < min) setValue(name, min)
    else if (max && value > max) setValue(name, max)
    else if (Number.isNaN(value) && emptyIsZero) setValue(name, min)
    //else if (Number.isNaN(value)) setValue(name, undefined)
  }, [value])

  return (
    <>
      <div
        style={style}
        className={`${css['number-input']} ${
          disabled && css['disabled']
        } ${className} ${hideButtons && css['hide-buttons']}`}
      >
        {label && <label>{label}</label>}
        <div style={{ display: 'flex' }}>
          <button
            onClick={(e) => {
              stepUp()
            }}
            className={value === max || disabled ? css['disabled'] : ''}
          >
            <IconPlus size={20} />
          </button>

          <input
            className={`${errorText && css['error']}`}
            type="number"
            placeholder={placeholder}
            step={1}
            {...register(name, {
              min,
              max,
              valueAsNumber: true,
              disabled,
            })}
            onKeyDown={(e) =>
              preventFractions && e.key === '.' && e.preventDefault()
            }
          />

          <button
            onClick={(e) => {
              stepDown()
            }}
            className={
              value === min || disabled || value === '' ? css['disabled'] : ''
            }
          >
            <IconMinus size={20} />
          </button>
        </div>
        {errorText && <div className={css['error-text']}>{errorText}</div>}
        {hintText && (!subText || errorText) && (
          <div className={css['hint-text']}>{hintText}</div>
        )}
        {subText && !errorText && (
          <div className={css['sub-text']}>{subText}</div>
        )}
      </div>
    </>
  )
}

export default NumberInput
