type Props = {
  highlight: string
  children: string
}

/**
 * Returns a string wrapped in span tag with the highlights wrapped in mark tags
 * @param highlight portion of text to highlight
 * @param children original text
 * @returns
 */
export const Highlight = (props: Props) => {
  const escapedSearchText = props.highlight.replace(
    /[-/\\^$*+?.()|[\]{}]/g,
    '\\$&'
  )
  // making sure that "single leg" will highlight "single-leg"
  const regex = new RegExp(escapedSearchText.replace(/\s/g, '[-\\s]'), 'gi')
  const newText = props.children.replace(regex, '<mark>$&</mark>')
  return <span dangerouslySetInnerHTML={{ __html: newText }} />
}
