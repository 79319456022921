import React from 'react'
import { AlertOctagon } from 'react-feather'
import css from './ErrorStripe.module.css'

type Props = {
  children: React.ReactNode
}

const ErrorStripe: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={css['stripe']}>
      <AlertOctagon
        size={16}
        strokeWidth={3}
        color="var(--accent-yellow)"
        style={{
          position: 'relative',
          top: '2px',
          marginRight: '8px',
        }}
      />
      {children}
    </div>
  )
}

export default ErrorStripe
