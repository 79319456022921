import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'

import { useURLParams } from '../../../hooks/useURLParams'
import { Analytics } from '../../../services/analytics'

export const Success = () => {
  const { searchParams } = useURLParams()

  const data = {
    value: parseInt(searchParams.get('value') || '') / 100,
    currency: searchParams.get('currency') || 'USD',
  }

  console.log('tracking conversion with data', data)

  useEffect(() => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-16474910927/4Mx6CM-u05YZEM_h7K89',
      ...data,
    })

    ReactPixel.track('StartTrial', data)
  }, [])

  return <Navigate replace to="/" />
}
