import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/buttons/Button/Button'
import useGym from '../../hooks/useGym'
import { UserContext } from '../../context/UserContext'
import { useURLParams } from '../../hooks/useURLParams'

type Props = {
  onComplete: () => void
  isVisible: boolean
  joinMode: boolean
  onModeSwitch: (joinGym: boolean) => void
}

function GymNameModule({
  onComplete,
  onModeSwitch,
  isVisible,
  joinMode,
}: Props) {
  const { user } = useContext(UserContext)
  const { setSearchParams, searchParams } = useURLParams()
  const {
    gymNameField,
    gymIDField,
    error,
    isLoading,
    setFocus,
    handleSubmit,
    gymFound,
    setGymFound,
    joinGym,
  } = useGym({
    joinMode: joinMode,
    onComplete: onComplete,
  })

  useEffect(() => {
    if (isVisible)
      setTimeout(() => {
        setFocus('gymName')
      }, 400)
  }, [isVisible])

  useEffect(() => {
    if (!isVisible) return

    if (!joinMode) {
      setSearchParams({ page: 'add_your_gym' })

      return
    }

    setSearchParams({ page: gymFound ? 'add_your_gym' : 'join_gym' })
  }, [isVisible, gymFound, joinMode])

  useEffect(() => {
    if (error) setFocus('gymName')
  }, [error])

  // const handleGymName = () => {
  //   onComplete()
  // }

  return (
    <div className="module auth_container gym-name">
      {!joinMode ? (
        <>
          <h2 style={{ marginTop: '64px' }}>
            <span>Hey, {user.name.split(' ')[0]}</span>
            Let's Create Your Gym
          </h2>
          <form onSubmit={handleSubmit}>
            <input
              {...gymNameField}
              type="text"
              className="reset__textBox"
              placeholder="Gym Name"
              maxLength={40}
            />
            {error && (
              <span
                style={{ margin: '4px 0 12px', color: 'var(--accent-red)' }}
              >
                {error}
              </span>
            )}
            <Button
              className="reset__btn"
              isSubmitButton={true}
              style={{ marginTop: '24px' }}
              isLoading={isLoading}
            >
              Create Gym
            </Button>
            <div>
              <br />
              <br />
              Manager or Coach?{' '}
              <a
                onClick={() => {
                  onModeSwitch(true)
                  setFocus('gymID')
                }}
              >
                Join your gym
              </a>
            </div>
          </form>
        </>
      ) : gymFound ? (
        <div style={{ textAlign: 'center', width: '240px', margin: '0 auto' }}>
          <h2 style={{ textAlign: 'center' }}>
            <span>Welcome to</span>
            {gymFound.title}
          </h2>
          <Button
            style={{ marginTop: '40px' }}
            onClick={() => joinGym(gymFound.id)}
          >
            Join
          </Button>

          <div>
            <br />
            <a onClick={() => setGymFound(null)}>Cancel</a>
          </div>
        </div>
      ) : (
        <>
          <h2>
            <span>Hey, {user.name.split(' ')[0]}</span>
            Enter Your Gym's ID
          </h2>
          <form onSubmit={handleSubmit}>
            <input
              {...gymIDField}
              style={{
                textAlign: 'center',
                textTransform: 'uppercase',
                letterSpacing: '8px',
              }}
              placeholder="XXXXXX"
              maxLength={6}
            />
            <Button
              className="reset__btn"
              style={{ marginTop: '24px' }}
              isLoading={isLoading}
              isSubmitButton={true}
            >
              Search
            </Button>
            <div>
              <br />
              Gym owner?{' '}
              <a
                onClick={() => {
                  onModeSwitch(false)
                  setFocus('gymName')
                }}
              >
                Add your gym
              </a>
            </div>
          </form>
        </>
      )}
    </div>
  )
}
export default GymNameModule
