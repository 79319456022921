import './_Auth.css'
import Button from '../../../components/buttons/Button/Button'

import { AuthForm } from '../../../hooks/useAuth'
import { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'

function RegisterModule({
  name,
  email,
  password,
  agreedToPolicies,
  isLoading,
  error,
  handleSubmit,
  onModeSwitch,
}: AuthForm) {
  // we use user values in case if a user
  // entered a wrong email and tries to reverify it
  const { user } = useContext(UserContext)

  return (
    <div className="module auth_container">
      <div>
        <h2>Create Account</h2>
        <form onSubmit={handleSubmit}>
          <input
            {...name}
            placeholder="Full Name"
            defaultValue={user.name ? user.name : ''}
          />
          <input
            className="login__textBox"
            style={{ marginTop: '24px' }}
            autoComplete="username"
            placeholder="E-Mail Addresss"
            defaultValue={user.email ? user.email : ''}
            {...email}
          />
          <input
            autoComplete="new-password"
            id="new-password"
            className="password__textBox"
            placeholder="Password"
            type="password"
            {...password}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              textAlign: 'left',
              lineHeight: '24px',
              margin: '24px 0 16px',
              color: 'white',
            }}
          >
            <input type="checkbox" {...agreedToPolicies} />
            <span style={{ margin: '-5px 0 0 12px' }}>
              I agree with{' '}
              <a
                href="https://app.rewod.io/policies/terms-of-use.html"
                target="_blank"
              >
                Terms of Use
              </a>{' '}
              and
              <br />
              <a
                href="https://app.rewod.io/policies/privacy-policy.html"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </div>
          {error && <div className={`under-input-message error`}>{error}</div>}
          <Button
            className="register__btn"
            style={{ marginTop: '32px' }}
            isLoading={isLoading}
            isSubmitButton={true}
          >
            Create Account
          </Button>
        </form>
        <div>
          <br />
          Already have an account?{' '}
          <a
            onClick={() => {
              onModeSwitch('login')
            }}
          >
            Sign In
          </a>{' '}
          now
        </div>
      </div>
    </div>
  )
}
export default RegisterModule
