import { useRef, useState, useEffect } from 'react'

/**
 * Made by ChatGPT
 * @returns Tracks the changes in height or width of a div element
 */
const useSize = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setHeight(entry.contentRect.height)
        setWidth(entry.contentRect.width)
      }
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current)
      }
    }
  }, [])

  return { ref, height, width }
}

export default useSize
