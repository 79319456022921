import React from 'react'
import css from './Badge.module.css'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
}

const Badge = (props: Props) => {
  return (
    <div className={css['badge']} style={props.style}>
      {props.children}
    </div>
  )
}

export default Badge
