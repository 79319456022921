import React, { useState, useEffect, useRef } from 'react'
import ExerciseInput from '../ExerciseInput/ExerciseInput'
import RestInput from '../RestInput/RestInput'
import TimeInput from '../TimeInput/TimeInput'
import NoteInput from '../NoteInput/NoteInput'
import {
  Plus as IconPlus,
  ArrowDown as IconArrowDown,
  Clock as IconClock,
  AlignLeft as IconNote,
} from 'react-feather'
import { v4 as uuid } from 'uuid'
import css from './SupersetInput.module.css'

import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'

import {
  DndContext,
  DragOverlay,
  closestCenter,
  MouseSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core'

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form'
import { RewodLegacyEvent } from '../../../types/legacyEvents'
import { useHotkeys } from 'react-hotkeys-hook'
import { Tooltip } from 'react-tooltip'
import { Workout } from '../../../types/workouts'

type Props = {}

const SupersetInput = () => {
  const [activeDraggedItem, setActiveDraggedItem] = useState<number | null>(
    null
  ) /*used in dnd-kit*/
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
  )

  // type FormValues = {
  //   superset: Array<Workout.Superset.Item>
  // }

  const { control, watch, setFocus, getValues, formState } =
    useFormContext<Workout.Item>()

  const { fields, append, remove, move } = useFieldArray({
    control: control,
    name: 'superset',
  })

  const mode =
    watch('superset')?.filter((i) => i.itemType === 'exercise').length > 1
      ? 'superset'
      : 'single'

  const addExercise = () => {
    const newItem: Workout.Superset.Item = {
      itemType: 'exercise',
      movement: { name: '', id: 'move_' + uuid().slice(0, 8) },
      effort: '',
      reps: '',
      id: 'ex_' + uuid().slice(0, 8),
    }

    append(newItem)
    const supersetLength = getValues().superset.length
    setTimeout(() => {
      setFocus(`superset.${supersetLength - 1}.movement.name`)
    }, 10)
  }

  useEffect(() => {
    setTimeout(() => {
      setFocus(`superset.0.movement.name`)
    }, 0)
  }, [])

  const addNote = () => {
    const newItem: Workout.Superset.Item = {
      itemType: 'note',
      note: '',
      id: 'note_' + uuid().slice(0, 8),
    }
    append(newItem)
  }

  const handleDragStart = (event: DragStartEvent) => {
    const draggedItemIndex = event.active.data.current
      ? event.active.data.current.sortable.index
      : null

    setActiveDraggedItem(draggedItemIndex)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    setActiveDraggedItem(null)

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((item) => item.id === active.id)
      const newIndex = fields.findIndex((item) => item.id === over.id)

      move(oldIndex, newIndex)
    }
  }

  const [allowAddExerciseByEnter, setAllowAddExerciseByEnter] = useState(false)

  const handleKeyPress = (
    currentType: 'movement' | 'reps' | 'effort' | 'note',
    index: number,
    key: string
  ) => {
    const prevField = fields[index - 1]
    const nextField = fields[index + 1]

    switch (key) {
      case 'Enter': {
        if (!nextField) {
          addExercise()

          //setFocus(`superset.${index + 1}.movement.name`)
        }
        if (nextField)
          if (nextField.itemType === 'note')
            return setFocus(`superset.${index + 1}.note`)
          else return setFocus(`superset.${index + 1}.movement.name`)
        break
      }
      case 'ArrowDown': {
        if (nextField) {
          if (nextField.itemType === 'note')
            return setFocus(`superset.${index + 1}.note`)
          else if (currentType === 'movement')
            return setFocus(`superset.${index + 1}.movement.name`)
          else return setFocus(`superset.${index + 1}.${currentType}`)
        }
        break
      }

      case 'ArrowUp': {
        if (prevField) {
          if (prevField.itemType === 'note')
            return setFocus(`superset.${index - 1}.note`)
          else if (currentType === 'movement')
            return setFocus(`superset.${index - 1}.movement.name`)
          else return setFocus(`superset.${index - 1}.${currentType}`)
        }
      }
    }
  }

  const getLineItem = ({
    item,
    index,
    isDragOverlay,
    canSort,
    sortingInProgress,
  }: {
    item: Workout.Superset.Item
    index: number
    canSort: boolean
    isDragOverlay?: boolean
    sortingInProgress?: boolean
  }) => {
    if (!item) return
    const packPosition = getPackPosition(item.itemType, index)

    switch (item.itemType) {
      case 'exercise': {
        return (
          <ExerciseInput
            packPosition={packPosition}
            onRemove={() => remove(index)}
            order={index}
            key={item.id}
            id={item.id}
            mode={mode}
            isDragOverlay={isDragOverlay}
            sortingInProgress={sortingInProgress}
            onKeyUp={handleKeyPress}
            canSort={canSort}
            parentFieldName={'superset'}
          />
        )
      }

      case 'note': {
        return (
          <div key={item.id} tabIndex={-1}>
            <NoteInput
              packPosition={packPosition}
              onRemove={() => remove(index)}
              sortingInProgress={sortingInProgress}
              id={item.id}
              index={index}
              isDragOverlay={isDragOverlay}
              canSort={canSort}
              parentFieldName={'superset'}
            />
          </div>
        )
      }
    }
  }

  const getPackPosition = (type: 'note' | 'exercise', index: number) => {
    const prevItemType =
      typeof fields[index - 1] !== 'undefined'
        ? fields[index - 1].itemType
        : null
    const nextItemType =
      typeof fields[index + 1] !== 'undefined'
        ? fields[index + 1].itemType
        : null

    const packPosition = (() => {
      if (prevItemType === type && nextItemType === type) return 'middle'
      else if (prevItemType === type) return 'last'
      else if (nextItemType === type) return 'first'
      else return 'lonely'
    })()

    return packPosition
  }
  const isMacOS = navigator.userAgent.indexOf('Mac') !== -1
  const hotkeyButton = isMacOS ? 'ctrl' : 'alt'

  useHotkeys(`${hotkeyButton}+n`, () => addNote(), {
    enableOnFormTags: true,
  })
  useHotkeys(
    `${hotkeyButton}+Enter`,
    () => {
      addExercise()
    },
    {
      enableOnFormTags: true,
    }
  )

  return (
    <div>
      <div id={css['superset']} className={mode}>
        <Tooltip
          id="t-hotkey-hint"
          place="bottom"
          delayShow={400}
          className="tooltip"
          noArrow={true}
          offset={8}
        />
        <Tooltip
          id="t-drag-hint"
          place="left-start"
          delayShow={400}
          className="tooltip"
          noArrow={true}
          offset={2}
          style={{ margin: '10px 0 0 0' }}
        />
        {fields.length > 0 ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            <SortableContext
              items={fields}
              strategy={verticalListSortingStrategy}
            >
              {fields.map((item, index) =>
                getLineItem({
                  item: item,
                  index: index,
                  isDragOverlay: false,
                  canSort: fields.length > 1,
                  sortingInProgress: activeDraggedItem !== null,
                })
              )}
            </SortableContext>

            <DragOverlay>
              {activeDraggedItem !== null
                ? getLineItem({
                    item: fields[activeDraggedItem],
                    index: activeDraggedItem,
                    isDragOverlay: true,
                    canSort: true,
                    sortingInProgress: true,
                  })
                : null}
            </DragOverlay>
          </DndContext>
        ) : (
          <div className={css['placeholder']}>Add a movement or a note</div>
        )}
      </div>
      <div className={css['actions']}>
        <div
          data-tooltip-id="t-hotkey-hint"
          data-tooltip-html={`Add Movement 
              ${
                isMacOS
                  ? `
                  <span
              class="tooltip-hotkey"
            >⌃</span><span
            class="tooltip-hotkey"
          >↩</span>`
                  : `<span
                  class="tooltip-hotkey"
                >Alt</span><span
                class="tooltip-hotkey"
              >↩</span>`
              }
            `}
        >
          <ButtonToolbar
            icon={<IconPlus size={16} />}
            type={'light'}
            onClick={addExercise}
          >
            Move
          </ButtonToolbar>
        </div>
        <div
          data-tooltip-id="t-hotkey-hint"
          data-tooltip-html={`Add Note 
              ${
                isMacOS
                  ? `
                  <span
              class="tooltip-hotkey"
            >⌃</span><span
            class="tooltip-hotkey"
          >N</span>`
                  : `<span
                  class="tooltip-hotkey"
                >Alt</span><span
                class="tooltip-hotkey"
              >N</span>`
              }
            `}
        >
          <ButtonToolbar
            icon={<IconNote size={16} />}
            type={'light'}
            onClick={addNote}
          >
            Note
          </ButtonToolbar>
        </div>
      </div>
    </div>
  )
}

export default SupersetInput
