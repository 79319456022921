import React from 'react'
import { useLocation } from 'react-router-dom'
import { BarLoader } from 'react-spinners'
import CalendarNav from './CalendarNav/CalendarNav'
import css from './SecondaryNav.module.css'
import PlaybookNav from './PlaybookNav/PlaybookNav'
import MembersNav from './MembersNav/MembersNav'

type Props = {
  data?: {
    activeType: string
  }
  isLoading?: boolean
  onChange?: (params: any) => void
}

const SecondaryNav = ({ data, isLoading, onChange }: Props) => {
  const location = useLocation()

  const getSecondaryNavContent = () => {
    switch (location.pathname) {
      case '/':
        return <CalendarNav />
      case '/playbook':
        return (
          <PlaybookNav
            onChange={onChange ? onChange : () => {}}
            data={data || { activeType: 'girls' }}
          />
        )
      case '/members':
        return <MembersNav />
      case '/gym-settings':
        return <h2>Gym Settings</h2>
      case '/account':
        return <h2>Account</h2>
      case '/test':
        return <h2>Test</h2>
      default:
        return <h2>Secondary Title</h2>
    }
  }

  return (
    <header className={css['secondary-nav']}>
      {getSecondaryNavContent()}
      {isLoading && (
        <BarLoader
          color="var(--accent-hover)"
          height={3}
          style={{
            position: 'absolute',
            width: '100%',
            height: '4px',
            backgroundColor: 'var(--accent-darker)',
            bottom: '0px',
            overflow: 'hidden',
            marginLeft: '-28px',
          }}
        />
      )}
    </header>
  )
}

export default SecondaryNav
