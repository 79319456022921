import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  updateEmail,
  updatePassword,
} from '@firebase/auth'
import e from 'express'
import { useContext, useState } from 'react'
import { UseFormSetFocus } from 'react-hook-form'
import { UserContext } from '../context/UserContext'
import { auth } from '../firebase'
import { UserData } from '../types/types'
import API, { EditableUserAttributes } from '../services'

type Props = {
  setFocus?: UseFormSetFocus<EditableUserAttributes> | undefined
  onSuccess: () => void
}

const useMe = ({ setFocus, onSuccess }: Props) => {
  const { user } = useContext(UserContext)
  const [error, setError] = useState<string | null>()
  const [isLoading, setLoading] = useState(false)

  const updateMyAttribute = async (attr: EditableUserAttributes) => {
    const [key, value] = Object.entries(attr)[0]

    if (!attr['name']) {
      setError('Name cannot be empty')
      return
    }

    setLoading(true)
    await API.patchUserAttribute(attr)
    onSuccess()
    setLoading(false)
  }

  const updateUserEmail = async ({
    email,
    password,
  }: EditableUserAttributes) => {
    if (!auth.currentUser) return setError('Error: No user detected')

    if (!password) {
      setError('Please enter your password')
      if (setFocus) {
        setFocus('password')
      }
      return
    }

    if (!email || email === user.email) {
      setError('Please enter the new e-mail')
      if (setFocus) {
        setFocus('email')
      }
      return
    }

    setLoading(true)

    try {
      await reauthWithPassowrd(password)
      await updateEmail(auth.currentUser, email)
      await sendEmailVerification(auth.currentUser)
      await API.patchUserAttribute({ email: email })
      onSuccess()
    } catch (err: any) {
      setError(translateAuthErrors(err.message, 'settings'))
    } finally {
      setLoading(false)
    }

    // await reauthWithPassowrd(password)
    //   .then(() => {
    //     if (!auth.currentUser || !email) return
    //     updateEmail(auth.currentUser, email).then(() => {
    //       if (!auth.currentUser || !email) return
    //       sendEmailVerification(auth.currentUser)
    //         .then(() => {
    //           try {
    //             updateUserAttribute({ email: email })
    //             setLoading(false)
    //             //   Analytics.logEvent({
    //             //     name: 'profile_edit_email',
    //             //     params: {},
    //             //   })
    //           } catch (e) {
    //             //   Analytics.logEvent({
    //             //     name: 'error_firestore',
    //             //     params: {
    //             //       title: 'Failed to update email setting in DB',
    //             //       body: e,
    //             //     },
    //             //   })
    //             console.log('error', e)
    //           }
    //         })
    //         .catch((err) => {
    //           console.error('updateEmail error 2:' + JSON.stringify(err))
    //           // Analytics.logEvent({
    //           //   name: 'error_firestore',
    //           //   params: {
    //           //     title: 'Failed to update email setting in Firestore Auth',
    //           //     body: err,
    //           //   },
    //           // })
    //           setError(translateAuthErrors(err.message))
    //         })
    //     })
    //   })
    //   .catch((err) => {
    //     setError(translateAuthErrors(err.message, 'settings'))
    //     setLoading(false)
    //     onSuccess()
    //     // Analytics.logEvent({
    //     //   name: 'error_firestore',
    //     //   params: {
    //     //     title: 'Failed to reauthenticate before updating email',
    //     //     body: JSON.stringify(err),
    //     //   },
    //     // })
    //   })

    // console.log('success')
  }
  const updateUserPassword = async ({
    password,
    newpassword,
  }: EditableUserAttributes) => {
    if (!auth.currentUser) return setError('Error: No user detected')

    if (!password) {
      if (setFocus) setFocus('password')
      return setError('Please enter your password')
    }
    if (!newpassword) {
      if (setFocus) setFocus('newpassword')
      return setError('Please enter your new password')
    }

    setLoading(true)

    try {
      await reauthWithPassowrd(password)
      await updatePassword(auth.currentUser, newpassword)
      onSuccess()
    } catch (err: any) {
      setError(translateAuthErrors(err.message, 'settings'))
      if (setFocus) setFocus('newpassword')
    } finally {
      setLoading(false)
    }
  }
  const clearErrors = () => setError(null)

  return {
    error,
    clearErrors,
    isLoading,
    updateMyAttribute,
    updateUserEmail,
    updateUserPassword,
  }
}

export default useMe

const translateAuthErrors = (err: string, from?: string) => {
  if (from === 'reset' && err.includes('user-not-found')) return 'Donno, man...'

  if (from === 'settings' && err.includes('wrong-password'))
    return 'Incorrect password'

  if (err.includes('user-not-found') || err.includes('wrong-password'))
    return 'Incorrect e-mail or password'

  if (err.includes('invalid-email')) return 'Please enter a valid e-mail'
  if (err.includes('email-already-in-use'))
    return 'This e-mail is already in use'

  if (err.includes('no-email-and-password-first'))
    return 'Please enter your e-mail and create a password'

  if (err.includes('no-email-and-password'))
    return 'Please enter your e-mail and the password'

  if (err.includes('no-email')) return 'Please enter your e-mail'

  if (err.includes('no-password-first')) return 'Please create a password'

  if (err.includes('no-password')) return 'Please enter the password'
  if (err.includes('auth/weak-password'))
    return 'Password should be at least 6 characters'

  if (err.includes('no-name')) return 'Please enter your name'
  if (err.includes('network-request-failed'))
    return "There's a problem with the network connection"

  if (err.includes('popup-closed-by-user')) return '' // google sign-in closed
  if (err.includes('invalid-action-code'))
    return 'Password reset link is no longer valid. Please request a new link' // google sign-in closed

  if (err === 'enter-new-passwords') return 'Please enter new password'

  // If all else fails
  return err.replace('Firebase: ', '')
}

const reauthWithPassowrd = (password: string) => {
  if (!auth.currentUser) {
    throw new Error('Error: no user detected')
  }

  if (!auth.currentUser.email) {
    throw new Error('Error: no user email detected')
  }
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    password
  )

  return reauthenticateWithCredential(auth.currentUser, credential)
}
